import { orgUnitServiceApi } from "@/services/infra/client-apis/org-unit-service-api/org-unit-service-api";
import { httpService } from "@/services/infra/https.service/http.service";
import {
  type Department,
  type DepartmentCreationRequest,
  DepartmentFilterSortFields,
  type DepartmentUpdateRequest,
  type ProjectMetadata,
  type Project,
  type ProjectCreationRequest,
  ProjectFilterSortFields,
  type ProjectUpdateRequest,
  Verbosity,
  type OrgUnitTelemetryType,
  ReportFilterAndSortFields,
  type Report,
} from "@/swagger-models/org-unit-service-client";
import type { IFilterBy, IPaginationFilter } from "@/models/filter.model";
import type { IDepartmentTable, IDepartmentTableFilterBy } from "@/models/department.model";
import type { IProjectTable, IProjectTableFilterBy } from "@/models/project.model";
import { filterUtil } from "@/utils/filter.util/filter.util";
import { DEFAULT_DEPARTMENT_NAME } from "@/models/department.model";
import { orgUnitUtil } from "@/utils/org-unit.util";
import { filterService } from "@/services/filter.service/filter.service";
import type { IReportTable } from "@/table-models/report.table-model";

export const orgUnitService = {
  getProjects,
  getProjectsMetadata,
  getProject,
  getDepartments,
  deleteProject,
  countDepartments,
  listDepartments,
  getDepartment,
  deleteDepartment,
  getDepartmentsTelemetry,
  listProjects,
  countProjects,
  createProject,
  updateProject,
  createDepartment,
  updateDepartment,
  isDepartmentNameUnique,
  isProjectNameUnique,
  getDefaultDepartment,
  countReports,
  getReportById,
  getReports,
  listReports,
  isReportApiAvailable,
};

//******** ProjectsApi ************
/**
 * Lists projects based on the provided filter criteria, filters the results by search term, and returns the filtered results.
 *
 * @param {IFilterBy} [filterBy={}] - The filter criteria for listing projects.
 * @returns {Promise<IDepartment[]>} - A promise that resolves to an array of projects.
 */
async function listProjects(filterBy: IProjectTableFilterBy): Promise<IProjectTable[]> {
  const filters: IPaginationFilter = orgUnitUtil.getProjectListFilters(filterBy);

  const projects = await getProjects(
    filters.filterBy || [],
    filters.sortBy as ProjectFilterSortFields | undefined,
    filters.sortOrder,
    filters?.offset,
    filters?.limit,
    filters?.search,
  );
  return projects.map((project: Project) => {
    return {
      ...project,
      rolesNames: [],
    };
  });
}

async function getProjects(
  filterBy?: Array<string>,
  sortBy?: ProjectFilterSortFields,
  sortOrder?: "asc" | "desc",
  offset?: number,
  limit?: number,
  search?: string,
): Promise<Project[]> {
  try {
    const response = await orgUnitServiceApi.projectsApi.getProjects(filterBy, sortBy, sortOrder, offset, limit, search);
    return response.data.projects;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getProjectsMetadata(clusterUuid: string): Promise<Array<ProjectMetadata>> {
  function delay(ms: number): Promise<void> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  try {
    const projects: Array<ProjectMetadata> = [];
    let page = 0;
    const pageSize = 500;
    const delayTime = 300; // delay time in milliseconds

    let next = true;
    while (next) {
      const response = await orgUnitServiceApi.projectsApi.getProjectsMetadata(
        [`clusterId==${clusterUuid}`],
        undefined,
        undefined,
        page,
        pageSize,
      );

      projects.push(...response.data.projects);

      if (!response.data.next) {
        next = false;
        break;
      }

      page += pageSize;
      await delay(delayTime); // Delay before the next request
    }

    return projects;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function countProjects(filters: IPaginationFilter): Promise<number> {
  try {
    const response = await orgUnitServiceApi.projectsApi.countProjects(filters.filterBy, filters.search);
    return response.data.count;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getProject(projectId: string): Promise<Project> {
  try {
    const response = await orgUnitServiceApi.projectsApi.getProject(projectId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function deleteProject(projectId: string): Promise<void> {
  try {
    await orgUnitServiceApi.projectsApi.deleteProject(projectId);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createProject(projectCreationRequest: ProjectCreationRequest): Promise<Project> {
  try {
    const response = await orgUnitServiceApi.projectsApi.createProject(projectCreationRequest);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateProject(projectId: string, projectUpdateRequest: ProjectUpdateRequest): Promise<Project> {
  try {
    const response = await orgUnitServiceApi.projectsApi.updateProject(projectId, projectUpdateRequest);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function isProjectNameUnique(clusterId: string, name: string): Promise<boolean> {
  try {
    const filterBy = [
      filterUtil.getEqualsFilterString(ProjectFilterSortFields.ClusterId, clusterId),
      filterUtil.getEqualsFilterString(ProjectFilterSortFields.Name, name),
    ];
    const count: number = await orgUnitService.countProjects({ filterBy });
    return count <= 0;
  } catch (error: unknown) {
    throw httpService.handleHttpError(error);
  }
}

//******** DepartmentsApi ************

/**
 * Lists departments based on the provided filter criteria, filters the results by search term, and returns the filtered results.
 *
 * @param {IFilterBy} [filterBy={}] - The filter criteria for listing departments.
 * @returns {Promise<IDepartment[]>} - A promise that resolves to an array of departments.
 */
async function listDepartments(filterBy: IDepartmentTableFilterBy): Promise<IDepartmentTable[]> {
  const filters: IPaginationFilter = orgUnitUtil.getDepartmentListFilters(filterBy);
  const departments = await getDepartments(
    filters.filterBy || [],
    filters.sortBy as DepartmentFilterSortFields | undefined,
    Verbosity.Verbose,
    filters.sortOrder,
    filters?.offset,
    filters?.limit,
  );

  return departments.map((department: Department) => {
    return {
      ...department,
      rolesNames: [],
    };
  });
}

async function getDepartments(
  filterBy?: Array<string>,
  sortBy?: DepartmentFilterSortFields,
  verbosity?: Verbosity,
  sortOrder?: "asc" | "desc",
  offset?: number,
  limit?: number,
): Promise<Department[]> {
  try {
    const response = await orgUnitServiceApi.departmentsApi.getDepartments(
      filterBy,
      sortBy,
      verbosity,
      sortOrder,
      offset,
      limit,
    );
    return response.data.departments as Department[];
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function countDepartments(filterBy?: Array<string>): Promise<number> {
  try {
    const response = await orgUnitServiceApi.departmentsApi.countDepartments(filterBy);
    return response.data.count;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getDepartment(departmentId: string): Promise<Department> {
  try {
    const response = await orgUnitServiceApi.departmentsApi.getDepartment(departmentId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function deleteDepartment(departmentId: string): Promise<void> {
  try {
    await orgUnitServiceApi.departmentsApi.deleteDepartment(departmentId);
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function createDepartment(department: DepartmentCreationRequest): Promise<Department> {
  try {
    const response = await orgUnitServiceApi.departmentsApi.createDepartment(department);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function updateDepartment(departmentId: string, department: DepartmentUpdateRequest): Promise<Department> {
  try {
    const response = await orgUnitServiceApi.departmentsApi.updateDepartment(departmentId, department);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getDepartmentsTelemetry(
  telemetryType: OrgUnitTelemetryType,
  clusterId?: string,
  nodepoolName?: string,
  departmentId?: string,
  groupBy?: Array<"CLUSTER_ID">,
): Promise<void> {
  try {
    await orgUnitServiceApi.departmentsApi.getDepartmentsTelemetry(
      telemetryType,
      clusterId,
      nodepoolName,
      departmentId,
      groupBy,
    );
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function isDepartmentNameUnique(clusterId: string, name: string): Promise<boolean> {
  try {
    const filterBy = [
      filterUtil.getEqualsFilterString(DepartmentFilterSortFields.ClusterId, clusterId),
      filterUtil.getEqualsFilterString(DepartmentFilterSortFields.Name, name),
    ];
    const count: number = await orgUnitService.countDepartments(filterBy);
    return count <= 0;
  } catch (error: unknown) {
    throw httpService.handleHttpError(error);
  }
}

async function getDefaultDepartment(clusterId: string): Promise<Department | null> {
  try {
    const filterBy = [
      filterUtil.getEqualsFilterString(DepartmentFilterSortFields.ClusterId, clusterId),
      filterUtil.getEqualsFilterString(DepartmentFilterSortFields.Name, DEFAULT_DEPARTMENT_NAME),
    ];
    const response = await orgUnitService.getDepartments(filterBy);
    if (response.length > 0) {
      return response[0];
    } else {
      return null;
    }
  } catch (error: unknown) {
    throw httpService.handleHttpError(error);
  }
}

//******** ReportsApi ************

/**
 * Checks if the report API is available - usually available only in SaaS.
 */
async function isReportApiAvailable(): Promise<boolean> {
  try {
    const response = await orgUnitServiceApi.reportsApi.areReportsAvailable();
    return response.data.isAvailable;
  } catch (err: unknown) {
    return false;
  }
}

async function listReports(filters: IFilterBy): Promise<IReportTable[]> {
  const parsedFilters = filterService.mapColumnsFilterToFilterParams(
    filters,
    Object.values(ReportFilterAndSortFields),
    true,
  );

  const { filterBy, sortBy, sortOrder, offset, limit, search } = parsedFilters;
  const reports = await getReports(
    filterBy,
    sortBy as ReportFilterAndSortFields | undefined,
    sortOrder,
    offset,
    limit,
    search,
  );
  return reports as IReportTable[];
}

async function countReports(filterBy?: Array<string>, search?: string): Promise<number> {
  try {
    const response = await orgUnitServiceApi.reportsApi.countReports(filterBy, search);
    return response.data.count;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getReports(
  filterBy?: Array<string>,
  sortBy?: ReportFilterAndSortFields,
  sortOrder?: "asc" | "desc",
  offset?: number,
  limit?: number,
  search?: string,
): Promise<Report[]> {
  try {
    const response = await orgUnitServiceApi.reportsApi.listReports(filterBy, sortBy, sortOrder, offset, limit, search);
    return response.data.reports;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}

async function getReportById(reportId: string): Promise<Report> {
  try {
    const response = await orgUnitServiceApi.reportsApi.getReportById(reportId);
    return response.data;
  } catch (err: unknown) {
    throw httpService.handleHttpError(err);
  }
}
