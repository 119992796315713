// 2.20
export const MIN_CLUSTER_VERSION_FOR_COMPLETIONS_AND_PARALLELISM = "2.20";

// 2.19
export const MIN_CLUSTER_VERSION_FOR_NFS_AND_HOST_PATH = "2.19";
export const MIN_CLUSTER_VERSION_FOR_WORKLOAD_DETAILS_TAB = "2.19";
export const MIN_CLUSTER_VERSION_CLUSTER_SCOPE_CM_ASSETS = "2.19";
export const MIN_CLUSTER_VERSION_IP_IN_PODS = "2.19";
export const MIN_CLUSTER_VERSION_FOR_ANY_EFFECT = "2.19";
export const MIN_CLUSTER_VERSION_FOR_SERVING_ENDPOINT_ACCESS = "2.19";
export const MIN_CLUSTER_VERSION_FOR_NIM = "2.19";

// 2.18
export const MIN_NEW_CLI_VERSION = "2.18";
export const MIN_WORKLOAD_CREATION_V2_VERSION = "2.18";
export const MIN_CLUSTER_VERSION_FOR_WORKLOAD_ACCESS_MULTI_USERS = "2.18";
export const MIN_CLUSTER_VERSION_FOR_INFERENCE_GRAPHS = "2.18";
export const MIN_VERSION_BACKOFF_LIMIT = "2.18.0";
export const MIN_CLUSTER_VERSION_FOR_THROUGHPUT_WIDGET = "2.18";
export const MIN_CLUSTER_VERSION_FOR_LATENCY_WIDGET = "2.18";
export const MIN_CLUSTER_VERSION_FOR_SECRET_DATASOURCE = "2.18";
export const MIN_CLUSTER_VERSION_FOR_GENERIC_SECRET = "2.18";
export const MIN_CLUSTER_VERSION_FOR_CONNECTED_STATUS = "2.18";

// 2.17
export const MIN_INFERENCE_AUTO_DELETE = "2.17.0";
export const MIN_CONFIGMAP_CLUSTER_VERSION = "2.17";
export const MIN_SERVING_PORT_CLUSTER_VERSION = "2.17";
export const MIN_VERSION_INFERENCE_WORKLOAD_URL = "2.17.0";
export const MIN_ClUSTER_SCOPE_ASSETS = "2.17";

// 2.16
export const MIN_DEPENDENCIES_VERSION = "2.16.0";
export const MIN_DEPARTMENT_QUEUE_METRIC_VERSION = "2.16";
export const MIN_OVER_PROVISIONING_RATIO_CLUSTER_VERSION = "2.16";
export const MIN_CLUSTER_VERSION_FOR_CONTAINER_OVERRIDE = "2.16";
export const MIN_CLUSTER_VERSION_FOR_INFERENCE_TOP_ROW = "2.16";

// 2.15
export const MIN_DEPARTMENT_SCOPE_CREDENTIALS = "2.15";
export const MIN_CLUSTER_TELEMETRY_TYPE_VERSION = "2.15";
export const MIN_VERSION_GIT_SYNC_ONE_PARAMERTER = "2.15.0";

// 2.14
export const MIN_AMD_GPU_BASED_NODES_VERSION = "2.14";
export const MIN_CLUSTER_SERVICE_NODES_VERSION = "2.14";

// 2.11
export const MIN_TELEMETRY_NODE_POOL_FILTER_VERSION = "2.11";
export const MIN_TENANT_SCOPE_CREDENTIALS = "2.11";

// 2.9
export const MIN_NODE_WORKLOAD_METRICS_VERSION = "2.9.0";
export const MIN_WINDOWS_CLI_VERSION = "2.9.0";
export const MIN_CLUSTER_VERSION_FOR_REPLICAS_WIDGET = "2.9";

// Other
export const TEST_ENV_VERSION = "master";
export const FEATURE_BRANCH_VERSION = "run-";
