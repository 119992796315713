<template>
  <runai-page-actions
    :columns="columns"
    :filters="filterBy"
    @filters-changed="$emit('filters-changed', $event)"
    @selected-actions-close="$emit('selected-actions-close')"
    primary-btn-label="New report"
    @create-entity="createReport"
    :selected-rows-amount="selectedRowsAmount"
    advanced-filters
    hide-export-csv
    hide-cluster-filter
    chip-text="consumption"
  />
</template>
<script lang="ts">
import { defineComponent, type PropType } from "vue";
import { RunaiPageActions } from "@/components/common/runai-page-actions";
import type { IFilterBy } from "@/models/filter.model";
import type { ITableColumn } from "@/models/table.model";
import { ReportRoutesName } from "@/router/report.routes/report.routes.names";
import { useRouter } from "vue-router";
//cmps

//model

export default defineComponent({
  name: "report-page-actions",
  components: { RunaiPageActions },
  emits: ["filters-changed", "selected-actions-close", "create-entity"],
  props: {
    filterBy: {
      type: Object as PropType<IFilterBy>,
      required: true,
    },
    columns: {
      type: Array as PropType<ITableColumn[]>,
      required: true,
    },
    selectedRowsAmount: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const router = useRouter();
    const createReport = () => router.push({ name: ReportRoutesName.ReportNew });

    return {
      createReport,
    };
  },
});
</script>

<style scoped lang="scss"></style>
