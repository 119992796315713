// models
import {
  EAccessOptions,
  type IConnectionAccess,
} from "@/components/environment/connection-access/connection-access-modal/connection-access-modal.model";
import { EHuggingFaceEnvVariableNames, EInferenceType, ENimEnvVariableNames } from "@/models/inference.model";
import type { IUIInferenceCreation, IUIWorkloadCreation, IUIWorkloadSpecificEnv } from "@/models/workload.model";
import type { IUIWorkloadEnvSectionModel } from "@/components/section/environment-section/environment-section.model";
import type { IComputeSectionData } from "@/components/section/compute-resource-section/compute-resource-section.models";
import type { Project, Resources } from "@/swagger-models/org-unit-service-client";
import {
  type InferenceSpecificRunParams,
  SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum,
  type WorkloadCreationRequest,
  type EnvironmentAsset,
  type EnvironmentVariable,
  type InferenceCreationRequestV2,
  type ModelAsset,
  type ModelInferenceCreationRequest,
  type ModelInferenceCreationRequestV2,
  type SpecificRunServingPortAccessServingPortAccess,
  type ComputeAsset,
  type DatasourceListResponseEntry,
} from "@/swagger-models/assets-service-client";
import { ENimAccessModelMethod } from "@/components/section/nim-model-store-section/nim-access-model-selector/nim-access-model-selector.model";

// utils
import { type TWorkloadsToConvert, workloadUtil } from "@/utils/workload.util/workload.util";
import { fallbackDefaultIfNullOrUndefined, omit, pick } from "@/utils/common.util/common.util";
import { workloadCreateFormUtil } from "@/utils/workload-create-form.util/workload-create-form.util";
import type { InferenceCreationRequest, InferenceSpecSpec } from "@/swagger-models/workloads-service-client";
import type { IItemizedListItem } from "@/components/common/runai-itemized-list/runai-itemized-list.model";

export const inferenceWorkloadUtil = {
  getEmptyUIInferenceCreation,
  getEmptyUIModelCatalogInferenceCreation,
  getEmptyUINimInferenceCreation,
  getInferenceComputeResourceData,
  getEnvironmentRunParamsData,
  createModelCatalog,
  getHuggingFaceTokenValue,
  getRunaiModelValue,
  getEnvironmentVariableValue,
  excludeEnvironmentVariablesByName,
  getModelInferenceCreationRequest,
  getModelInferenceCreationRequestV2,
  getInferenceCreationRequestV2,
  convertInferenceToWorkloadUI,
  getInferenceWorkloadCreationRequest,
  getInferenceRequestV2SpecificRunParams,
  getNimInferenceWorkloadCreationRequest,

  // private functions
  _getAuthorizationType,
  _servingPortAccess,
  _getServingPortAccessOption,
  _servingPortInternalToolInfo,
  _getFlatWorkloadData,
  _getFlatInferenceWorkloadData,
  _addNimEnvironmentVariables,
  _fillDatasourceData,
};

function getEmptyUINimInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const uiWorkload: IUIWorkloadCreation = getEmptyUIInferenceCreation(specificEnv);
  const modelCreateUi: IUIInferenceCreation = {
    ...uiWorkload,
    image: "",
    inferenceType: EInferenceType.Nim,
    accessModelMethod: ENimAccessModelMethod.ApiKey,
  };
  return modelCreateUi;
}

function getEmptyUIModelCatalogInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const uiWorkload: IUIWorkloadCreation = getEmptyUIInferenceCreation(specificEnv);
  const modelCreateUi: IUIInferenceCreation = {
    ...uiWorkload,
    modelId: "",
    inferenceType: EInferenceType.RunaiModel,
  };
  return modelCreateUi;
}

function getEmptyUIInferenceCreation(specificEnv?: IUIWorkloadSpecificEnv): IUIInferenceCreation {
  const MIN_REPLICAS_DEFAULT_VALUE = 1;
  const MAX_REPLICAS_DEFAULT_VALUE = 1;

  const uiWorkload: IUIWorkloadCreation = workloadUtil.getEmptyUIWorkloadCreation(specificEnv);
  const uiInferenceWorkload: IUIInferenceCreation = {
    ...uiWorkload,
    specificEnv: {
      ...omit(uiWorkload.specificEnv, ["backoffLimit"]),
      autoScaleData: {
        minReplicas: MIN_REPLICAS_DEFAULT_VALUE,
        maxReplicas: MAX_REPLICAS_DEFAULT_VALUE,
        thresholdMetric: undefined,
        thresholdValue: undefined,
        scaleToZeroRetentionSeconds: undefined,
      },
      servingPortAccess: {
        authorizedUsers: null,
        authorizedGroups: null,
        accessOption: EAccessOptions.PUBLIC,
      },
    },
    inferenceType: EInferenceType.AssetsBased,
  };

  return uiInferenceWorkload;
}

function getInferenceComputeResourceData(
  workload: IUIWorkloadCreation,
  projectNodepools: Array<Resources> | undefined | null = [],
): IComputeSectionData {
  const computeData: IComputeSectionData = workloadCreateFormUtil.getComputeResourceData(workload, projectNodepools);
  computeData.autoScaleData = workload.specificEnv.autoScaleData;
  return computeData;
}

function getEnvironmentRunParamsData(workload: IUIWorkloadCreation): IUIWorkloadEnvSectionModel {
  const envSectionData: IUIWorkloadEnvSectionModel = workloadCreateFormUtil.getEnvironmentRunParamsData(workload);
  envSectionData.servingPortAccess = workload.specificEnv.servingPortAccess;
  return envSectionData;
}

function getHuggingFaceTokenValue(environmentVariables: Array<EnvironmentVariable> = []): string {
  const tokenEnv: EnvironmentVariable | undefined = environmentVariables.find(
    (env: EnvironmentVariable) => env.name === EHuggingFaceEnvVariableNames.HF_TOKEN,
  );

  return tokenEnv?.value || "";
}

function getRunaiModelValue(environmentVariables: Array<EnvironmentVariable> = []): string {
  const modelEnv: EnvironmentVariable | undefined = environmentVariables.find(
    (env: EnvironmentVariable) => env.name === EHuggingFaceEnvVariableNames.RUNAI_MODEL,
  );

  return modelEnv?.value || "";
}

function getEnvironmentVariableValue(keyName: string, environmentVariables: Array<EnvironmentVariable> = []): string {
  const modelEnv: EnvironmentVariable | undefined = environmentVariables.find(
    (env: EnvironmentVariable) => env.name === keyName,
  );

  return modelEnv?.value || "";
}

function excludeEnvironmentVariablesByName(
  keyNames: string | Array<string>,
  environmentVariables: Array<EnvironmentVariable> = [],
): Array<EnvironmentVariable> {
  const keyNamesArray: Array<string> = Array.isArray(keyNames) ? keyNames : [keyNames];
  const keyNamesMap = new Set<string>(keyNamesArray);
  return environmentVariables.filter((env: EnvironmentVariable) => env.name && !keyNamesMap.has(env.name));
}

function getNimInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  compute: ComputeAsset,
  dataSource: DatasourceListResponseEntry | undefined,
  supportingServingPortAccess = false,
): InferenceCreationRequest {
  const workloadCreationRequest: InferenceCreationRequest = _getFlatInferenceWorkloadData(
    workload,
    compute,
    supportingServingPortAccess,
  );

  // nim specific params
  if (!workloadCreationRequest.spec) {
    workloadCreationRequest.spec = {};
  }

  workloadCreationRequest.spec.image = workload.image;
  workloadCreationRequest.spec.environmentVariables = _addNimEnvironmentVariables(
    workloadCreationRequest.spec.environmentVariables || [],
  );

  workloadCreationRequest.spec.security = {
    ...workloadCreationRequest.spec.security,
    runAsGid: 1000,
    runAsUid: 1000,
  };

  workloadCreationRequest.spec.compute = {
    ...workloadCreationRequest.spec.compute,
    largeShmRequest: true,
  };

  workloadCreationRequest.spec.servingPort = {
    ...workloadCreationRequest.spec.servingPort,
    container: 8080,
    protocol: "http",
  };

  if (dataSource) {
    workloadCreationRequest.spec = _fillDatasourceData(workloadCreationRequest.spec, dataSource);
  }

  return workloadCreationRequest;
}

function _fillDatasourceData(
  nimWorkloadSpec: InferenceSpecSpec,
  dataSource: DatasourceListResponseEntry,
): InferenceSpecSpec {
  nimWorkloadSpec.storage = {
    [dataSource.meta.kind]: [dataSource],
  };

  nimWorkloadSpec.environmentVariables = excludeEnvironmentVariablesByName(
    ENimEnvVariableNames.NimCachePath,
    nimWorkloadSpec.environmentVariables || [],
  );
  const path: string | undefined | null =
    dataSource.spec.nfs?.path || dataSource.spec.pvc?.path || dataSource.spec.hostPath?.path;

  if (path) {
    nimWorkloadSpec.environmentVariables?.push({ name: ENimEnvVariableNames.NimCachePath, value: path });
  }

  return nimWorkloadSpec;
}

function _addNimEnvironmentVariables(environmentVariables: Array<EnvironmentVariable>): Array<EnvironmentVariable> {
  environmentVariables = excludeEnvironmentVariablesByName(
    [
      ENimEnvVariableNames.OutlinesCacheDir,
      ENimEnvVariableNames.NimServerPort,
      ENimEnvVariableNames.NimJsonlLogging,
      ENimEnvVariableNames.NimLogLevel,
    ],
    environmentVariables,
  );

  environmentVariables?.push(
    { name: ENimEnvVariableNames.OutlinesCacheDir, value: "/tmp/outlines" },
    { name: ENimEnvVariableNames.NimServerPort, value: "8000" },
    { name: ENimEnvVariableNames.NimJsonlLogging, value: "1" },
    { name: ENimEnvVariableNames.NimLogLevel, value: "INFO" },
  );

  return environmentVariables;
}

function _getFlatInferenceWorkloadData(
  workload: IUIInferenceCreation,
  compute: ComputeAsset,
  supportingServingPortAccess = false,
  environment?: EnvironmentAsset,
): InferenceCreationRequest {
  const specificEnv = workload.specificEnv;
  const workloadCreationRequest: InferenceCreationRequest = _getFlatWorkloadData(workload, compute, environment);

  if (workloadCreationRequest.spec) {
    workloadCreationRequest.spec.autoscaling = {
      ...specificEnv.autoScaleData,
    };
    if (supportingServingPortAccess) {
      workloadCreationRequest.spec.servingPort = _servingPortAccess(specificEnv.servingPortAccess);
    }
  }

  return workloadCreationRequest;
}

function _getFlatWorkloadData(
  workload: IUIInferenceCreation,
  compute: ComputeAsset,
  environment?: EnvironmentAsset,
): InferenceCreationRequest {
  const specificEnv = workload.specificEnv || {};
  const workloadCreationRequest: InferenceCreationRequest = {
    name: workload.name,
    projectId: workload.projectId.toString(),
    clusterId: workload.clusterId,
    spec: {
      command: specificEnv.command || undefined,
      args: specificEnv.args || undefined,
      nodeType: specificEnv.nodeType || undefined,
      nodePools: specificEnv.nodePools,
      podAffinity: specificEnv.podAffinity || undefined,
      environmentVariables: specificEnv.environmentVariables,
      annotations: specificEnv.annotations?.map((env: IItemizedListItem) => pick(env, "name", "value", "exclude")),
      labels: specificEnv.labels?.map((env: IItemizedListItem) => pick(env, "name", "value", "exclude")),
      tolerations: specificEnv.tolerations,
      compute: compute.spec,
    },
  };

  if (environment) {
    workloadCreationRequest.spec = {
      ...workloadCreationRequest.spec,
      image: environment.spec.image || undefined,
      imagePullPolicy: environment.spec.imagePullPolicy || undefined,
      workingDir: environment.spec.workingDir || undefined,
      createHomeDir: environment.spec.createHomeDir || undefined,
    };
  }
  return workloadCreationRequest;
}

function createModelCatalog(
  inference: IUIInferenceCreation,
  selectedProject: Project,
  selectedModelAsset: ModelAsset,
  createdComputeId: string,
  environment: EnvironmentAsset | null,
  supportingServingPortAccess: boolean,
): IUIInferenceCreation {
  const modelCatalog: IUIInferenceCreation = {
    modelId: selectedModelAsset.meta.id,
    clusterId: inference.clusterId,
    projectId: inference.projectId,
    namespace: selectedProject?.status.namespace || inference.namespace,
    name: inference.name,
    assets: {
      compute: createdComputeId || "",
      environment: selectedModelAsset.spec.assets?.environment || null,
    },
    specificEnv: {
      ...inference.specificEnv,
      environmentVariables:
        inference.specificEnv.environmentVariables || environment?.spec.environmentVariables || undefined,
    },
    inferenceType: EInferenceType.RunaiModel,
  };

  if (!modelCatalog.specificEnv.nodePools && selectedProject.effective.defaultNodePools) {
    modelCatalog.specificEnv.nodePools = selectedProject.effective.defaultNodePools;
  }

  // Remove servingPortAccess if not supported
  if (!supportingServingPortAccess) {
    delete modelCatalog.specificEnv.servingPortAccess;
  }

  return modelCatalog;
}

/// ---->
function getInferenceCreationRequestV2(
  inference: IUIWorkloadCreation,
  supportingServingPortAccess = false,
): InferenceCreationRequestV2 {
  if (!inference.assets.environment) throw "Environment details are missing";
  const workloadCreationRequest: InferenceCreationRequestV2 = {
    meta: {
      name: inference.name,
      projectId: inference.projectId.toString(),
      clusterId: inference.clusterId,
    },
    assets: workloadUtil.getWorkloadAssetsFromUIWorkloadAssets(inference.assets),
    specificRunParams: getInferenceRequestV2SpecificRunParams(inference.specificEnv, supportingServingPortAccess),
  };
  return workloadCreationRequest;
}

function getModelInferenceCreationRequest(
  modelSpecCreate: IUIInferenceCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequest {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";
  if (!modelSpecCreate.modelId) throw "Model ID is missing";

  const uiWorkload: ModelInferenceCreationRequest = {
    name: modelSpecCreate.name,
    clusterId: modelSpecCreate.clusterId,
    projectId: modelSpecCreate.projectId,
    namespace: modelSpecCreate.namespace,
    modelId: modelSpecCreate.modelId,
    assets: {
      compute: modelSpecCreate.assets.compute,
    },
    specificEnv: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };

  return uiWorkload;
}

function getModelInferenceCreationRequestV2(
  modelSpecCreate: IUIInferenceCreation,
  supportingServingPortAccess = false,
): ModelInferenceCreationRequestV2 {
  if (!modelSpecCreate.assets.compute) throw "Compute resource is missing";
  if (!modelSpecCreate.modelId) throw "Model ID is missing";

  return {
    meta: {
      name: modelSpecCreate.name,
      clusterId: modelSpecCreate.clusterId,
      projectId: String(modelSpecCreate.projectId),
    },
    assets: {
      model: modelSpecCreate.modelId,
      compute: modelSpecCreate.assets.compute || "",
    },
    specificRunParams: getInferenceRequestV2SpecificRunParams(modelSpecCreate.specificEnv, supportingServingPortAccess),
  };
}

function convertInferenceToWorkloadUI(
  workload: TWorkloadsToConvert,
  inferenceType: EInferenceType,
  supportingServingPortAccess = false,
): IUIInferenceCreation {
  const baseWorkload: IUIWorkloadCreation = workloadUtil.convertWorkloadToWorkloadUI(workload);
  const inferenceUI: IUIInferenceCreation = {
    ...baseWorkload,
    inferenceType,
  };

  const autoScaling = workload.specificRunParams?.autoScaling;
  inferenceUI.specificEnv.autoScaleData = {
    minReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.minReplicas, 1),
    maxReplicas: fallbackDefaultIfNullOrUndefined(autoScaling?.maxReplicas, 1),
    thresholdMetric: autoScaling?.thresholdMetric || undefined,
    thresholdValue: autoScaling?.thresholdValue || undefined,
    scaleToZeroRetentionSeconds: autoScaling?.scaleToZeroRetentionSeconds || undefined,
  };

  if (supportingServingPortAccess) {
    inferenceUI.specificEnv.servingPortAccess = _servingPortInternalToolInfo(
      workload.specificRunParams?.servingPortAccess,
    );
  }

  // delete inferenceUI.specificEnv.allowOverQuota;
  return {
    ...inferenceUI,
    inferenceType: inferenceType || EInferenceType.AssetsBased,
  };
}

function _servingPortInternalToolInfo(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): IConnectionAccess {
  return {
    accessOption: _getServingPortAccessOption(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getServingPortAccessOption(
  servingPortAccess: SpecificRunServingPortAccessServingPortAccess | undefined | null,
): EAccessOptions {
  let authorizationType: EAccessOptions = EAccessOptions.PUBLIC;
  if (!servingPortAccess) return authorizationType;
  switch (servingPortAccess.authorizationType) {
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public:
      authorizationType = EAccessOptions.PUBLIC;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers:
      authorizationType = EAccessOptions.EVERYONE;
      break;
    case SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups:
      if (servingPortAccess.authorizedGroups) {
        authorizationType = EAccessOptions.GROUPS;
      } else {
        authorizationType = EAccessOptions.SPECIFIC_USERS;
      }
  }

  return authorizationType;
}

function getInferenceRequestV2SpecificRunParams(
  specificEnv: IUIWorkloadSpecificEnv,
  supportingServingPortAccess = false,
): InferenceSpecificRunParams {
  const retSpecificEnv: InferenceSpecificRunParams = workloadUtil.processCommonSpecificEnv(specificEnv, [
    "allowOverQuota",
    "backoffLimit",
    "autoScaleData",
    "servingPortAccess",
  ]);

  retSpecificEnv.autoScaling = specificEnv.autoScaleData;

  if (supportingServingPortAccess) {
    retSpecificEnv.servingPortAccess = _servingPortAccess(specificEnv.servingPortAccess);
  }

  return retSpecificEnv;
}

function getInferenceWorkloadCreationRequest(
  workload: IUIInferenceCreation,
  supportingServingPortAccess = false,
): WorkloadCreationRequest {
  const workloadCreationRequest: WorkloadCreationRequest = workloadUtil.getWorkloadCreationRequest(workload);

  workloadCreationRequest.specificEnv = {
    ...workloadCreationRequest.specificEnv,
    autoScaling: workload.specificEnv.autoScaleData,
  };

  if (supportingServingPortAccess && workload.specificEnv.servingPortAccess) {
    workloadCreationRequest.specificEnv.servingPortAccess = _servingPortAccess(workload.specificEnv.servingPortAccess);
  }

  // Inference does not support backoffLimit
  delete workloadCreationRequest.specificEnv.backoffLimit;

  return workloadCreationRequest;
}

function _servingPortAccess(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccess {
  return {
    authorizationType: _getAuthorizationType(servingPortAccess),
    authorizedGroups: servingPortAccess?.authorizedGroups,
    authorizedUsers: servingPortAccess?.authorizedUsers,
  };
}

function _getAuthorizationType(
  servingPortAccess: IConnectionAccess | null | undefined,
): SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum {
  let authorizationType: SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum =
    SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public;
  if (!servingPortAccess?.accessOption) return authorizationType;

  switch (servingPortAccess.accessOption) {
    case EAccessOptions.PUBLIC:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.Public;
      break;
    case EAccessOptions.EVERYONE:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthenticatedUsers;
      break;
    case EAccessOptions.GROUPS:
    case EAccessOptions.SPECIFIC_USERS:
      authorizationType = SpecificRunServingPortAccessServingPortAccessAuthorizationTypeEnum.AuthorizedUsersOrGroups;
  }

  return authorizationType;
}
