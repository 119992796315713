import { unleashConfigService } from "./unleash-config.service";
import { EFeatureToggle } from "@/services/infra/unleash.service/unleash.model";

export const unleashService = {
  shouldShowContainerPathOverride,
  isWorkloadDetailsTabEnabled,
  shouldShowNewLayout,
  showOrgUnitParity,
  showOrgUnitOverTimeData,
  showPolicyForInference,
  isMigProfilesDisabled,
  isUpdateAssetEnabled,
  showNodePoolsV2,
  showNimInference,
  showServingEndpointAccessInference,
  showPersonalApps,
  showReportsGrid,
  isFeatureEnabled,
};

function isFeatureEnabled(featureToggle: EFeatureToggle): boolean {
  if (featureToggle === EFeatureToggle.ShowReportsGrid) return false;
  return unleashConfigService.isFeatureEnabled(featureToggle);
}

function shouldShowContainerPathOverride(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowContainerPathOverride);
}

function isWorkloadDetailsTabEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowWorkloadDetailsTabEnabled);
}

function shouldShowNewLayout(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowNewLayout);
}

function showOrgUnitParity(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowOrgUnitParity);
}

function showOrgUnitOverTimeData(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowOrgUnitOverTimeData);
}

function showPolicyForInference(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowPolicyForInference);
}

function isMigProfilesDisabled(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowMigProfilesDisabled);
}

function isUpdateAssetEnabled(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowUpdateAssetEnabled);
}

function showNodePoolsV2(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowNodePoolsV2);
}

function showNimInference(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowNimInference);
}

function showServingEndpointAccessInference(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowServingEndpointAccessInference);
}

function showPersonalApps(): boolean {
  // To support also self-hosted version
  return false; //unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowPersonalApps);
}

function showReportsGrid(): boolean {
  return unleashConfigService.isFeatureEnabled(EFeatureToggle.ShowReportsGrid);
}
