<template>
  <runai-table-wrapper :filters-object="filterBy" aid="report-index">
    <template v-slot:actions>
      <report-page-actions
        :selected-rows-amount="selectedRowsAmount"
        :filter-by="filterBy"
        :columns="columns"
        @filters-changed="setFilterBy"
        @selected-actions-close="resetSelectedRows"
      />
    </template>
    <template v-slot:table>
      <runai-table
        :rows="tableData"
        :columns="columns"
        :filter-by="filterBy"
        v-model:selected="selectedRows"
        :loading="loadingTableData"
        @update-filters="setFilterBy"
        @clear-filters="clearFilters"
        :top-row="lastCreatedEntity"
        :get-row-key="getRowKey"
        sticky-columns
        :bordered="false"
        :rows-per-page-options="[1, 5, 7, 10, 15, 20, 25, 50, 100]"
      >
        <template #no-data>
          <runai-table-no-data
            v-if="!loadingTableData && !lastCreatedEntity"
            :filter-by="filterBy"
            entity-name="report"
            icon-name="report"
            :show-error="loadingError"
            @clear-filters="clearFilters"
          >
          </runai-table-no-data>
        </template>
      </runai-table>
    </template>
  </runai-table-wrapper>
</template>
<script lang="ts">
import { computed, defineComponent } from "vue";
// store
import { useAppStore } from "@/stores/app.store";
// cmps
import { RunaiTableWrapper } from "@/components/common/runai-table-wrapper/";
import { RunaiTableNoData } from "@/components/common/runai-table/runai-table-no-data";
import { RunaiTable } from "@/components/common/runai-table/";
import { ReportPageActions } from "@/components/report/report-page-actions/";
// model
import { type IReportTable, reportIndexColumns } from "@/table-models/report.table-model";
import type { IFilterBy } from "@/models/filter.model";
// service
import { orgUnitService } from "@/services/control-plane/org-unit.service/org-unit.service";
// other
import { useTableDataAdvancedFilters } from "@/composables/use-table-data-advanced-filters.composable/use-table-data-advanced-filters.composable";
import { EAdvancedIndexPages } from "@/composables/helpers/use-table-data.mapper";

export default defineComponent({
  name: "report-index",
  components: { ReportPageActions, RunaiTable, RunaiTableNoData, RunaiTableWrapper },
  setup() {
    const appStore = useAppStore();

    /*** api ***/
    const listReports = async (filterBy: IFilterBy) => {
      return await orgUnitService.listReports(filterBy);
    };
    const getReportById = async (id: string) => await orgUnitService.getReportById(id);

    const columns = computed(() => reportIndexColumns);

    const getRowKey = (row: IReportTable) => row.id;

    const {
      setFilterBy,
      clearFilters,
      tableData,
      filterBy,
      loadingTableData,
      loadingError,
      lastCreatedEntity,
      selectedRows,
      resetSelectedRows,
      selectedRowsAmount,
      initTableFilter,
    } = useTableDataAdvancedFilters<IReportTable>(
      listReports,
      columns,
      EAdvancedIndexPages.REPORT,
      getReportById,
      undefined,
      true,
    );
    initTableFilter();
    appStore.setPageLoading(false);
    return {
      appStore,
      filterBy,
      setFilterBy,
      columns,
      selectedRows,
      resetSelectedRows,
      selectedRowsAmount,
      tableData,
      loadingTableData,
      clearFilters,
      lastCreatedEntity,
      loadingError,
      getRowKey,
    };
  },
});
</script>

<style scoped lang="scss"></style>
