/* tslint:disable */
/* eslint-disable */
/**
 * Org unit
 * An API for managing RunAi organizational units.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@run.ai
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * Sum of all resources assigned to the projects under this department per Node Pool
 * @export
 * @interface AggregatedResources
 */
export interface AggregatedResources {
    /**
     * 
     * @type {AggregatedResourcesNodePool}
     * @memberof AggregatedResources
     */
    'nodePool': AggregatedResourcesNodePool;
    /**
     * 
     * @type {DeservedResource}
     * @memberof AggregatedResources
     */
    'gpu'?: DeservedResource;
    /**
     * 
     * @type {DeservedResource}
     * @memberof AggregatedResources
     */
    'cpu'?: DeservedResource;
    /**
     * 
     * @type {AggregatedResourcesMemory}
     * @memberof AggregatedResources
     */
    'memory'?: AggregatedResourcesMemory;
}
/**
 * 
 * @export
 * @interface AggregatedResourcesMemory
 */
export interface AggregatedResourcesMemory {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof AggregatedResourcesMemory
     */
    'deserved'?: number | null;
    /**
     * 
     * @type {Units}
     * @memberof AggregatedResourcesMemory
     */
    'units': Units;
}
/**
 * 
 * @export
 * @interface AggregatedResourcesMemoryAllOf
 */
export interface AggregatedResourcesMemoryAllOf {
    /**
     * 
     * @type {Units}
     * @memberof AggregatedResourcesMemoryAllOf
     */
    'units': Units;
}
/**
 * the node pool which the resources refer to
 * @export
 * @interface AggregatedResourcesNodePool
 */
export interface AggregatedResourcesNodePool {
    /**
     * node pool id
     * @type {string}
     * @memberof AggregatedResourcesNodePool
     */
    'id': string;
    /**
     * node pool name
     * @type {string}
     * @memberof AggregatedResourcesNodePool
     */
    'name': string;
}
/**
 * The ancestors of an org unit.
 * @export
 * @interface Ancestors
 */
export interface Ancestors {
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'tenant_id': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Ancestors
     */
    'cluster_uuid'?: string | null;
    /**
     * The id of the department.
     * @type {string}
     * @memberof Ancestors
     */
    'department_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Ancestors
     */
    'project_id'?: string | null;
}
/**
 * 
 * @export
 * @interface AreReportsAvailable200Response
 */
export interface AreReportsAvailable200Response {
    /**
     * 
     * @type {boolean}
     * @memberof AreReportsAvailable200Response
     */
    'isAvailable': boolean;
}
/**
 * 
 * @export
 * @interface AssignedResourcesV1
 */
export interface AssignedResourcesV1 {
    /**
     * 
     * @type {number}
     * @memberof AssignedResourcesV1
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1Gpu}
     * @memberof AssignedResourcesV1
     */
    'gpu'?: AssignedResourcesV1Gpu;
    /**
     * 
     * @type {AssignedResourcesV1Cpu}
     * @memberof AssignedResourcesV1
     */
    'cpu'?: AssignedResourcesV1Cpu;
    /**
     * 
     * @type {AssignedResourcesV1Memory}
     * @memberof AssignedResourcesV1
     */
    'memory'?: AssignedResourcesV1Memory;
}
/**
 * CPU Millicores assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesV1Cpu
 */
export interface AssignedResourcesV1Cpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1Cpu
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1Cpu
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1Cpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * GPU number assigned
 * @export
 * @interface AssignedResourcesV1Gpu
 */
export interface AssignedResourcesV1Gpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1Gpu
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1Gpu
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1Gpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * CPU Memory Mib assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesV1Memory
 */
export interface AssignedResourcesV1Memory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1Memory
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1Memory
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1Memory
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface AssignedResourcesV1Response
 */
export interface AssignedResourcesV1Response {
    /**
     * 
     * @type {number}
     * @memberof AssignedResourcesV1Response
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1ResponseGpu}
     * @memberof AssignedResourcesV1Response
     */
    'gpu': AssignedResourcesV1ResponseGpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseCpu}
     * @memberof AssignedResourcesV1Response
     */
    'cpu': AssignedResourcesV1ResponseCpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseMemory}
     * @memberof AssignedResourcesV1Response
     */
    'memory': AssignedResourcesV1ResponseMemory;
}
/**
 * CPU Millicores assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesV1ResponseCpu
 */
export interface AssignedResourcesV1ResponseCpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseCpu
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseCpu
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseCpu
     */
    'overQuotaWeight': number | null;
}
/**
 * GPU number assigned
 * @export
 * @interface AssignedResourcesV1ResponseGpu
 */
export interface AssignedResourcesV1ResponseGpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseGpu
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseGpu
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseGpu
     */
    'overQuotaWeight': number | null;
}
/**
 * CPU Memory Mib assigned. Supported only if \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface AssignedResourcesV1ResponseMemory
 */
export interface AssignedResourcesV1ResponseMemory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseMemory
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseMemory
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof AssignedResourcesV1ResponseMemory
     */
    'overQuotaWeight': number | null;
}
/**
 * 
 * @export
 * @interface ComplianceIssues
 */
export interface ComplianceIssues {
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof ComplianceIssues
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface ComplianceIssuesComplianceIssuesInner
 */
export interface ComplianceIssuesComplianceIssuesInner {
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'field': string;
    /**
     * 
     * @type {string}
     * @memberof ComplianceIssuesComplianceIssuesInner
     */
    'details': string;
}
/**
 * 
 * @export
 * @interface CountResponse
 */
export interface CountResponse {
    /**
     * 
     * @type {number}
     * @memberof CountResponse
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface DataDepartmentFields
 */
export interface DataDepartmentFields {
    /**
     * department\'s description
     * @type {string}
     * @memberof DataDepartmentFields
     */
    'description'?: string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DataDepartmentFields
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DataDepartmentFields
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DataDepartmentFields
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DataDepartmentFields
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * 
     * @type {string}
     * @memberof DataDepartmentFields
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DataDepartmentFields
     */
    'clusterId': string;
    /**
     * 
     * @type {OvertimeData}
     * @memberof DataDepartmentFields
     */
    'overtimeData'?: OvertimeData;
}
/**
 * 
 * @export
 * @interface DataDepartmentFieldsAllOf
 */
export interface DataDepartmentFieldsAllOf {
    /**
     * 
     * @type {string}
     * @memberof DataDepartmentFieldsAllOf
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DataDepartmentFieldsAllOf
     */
    'clusterId': string;
    /**
     * 
     * @type {OvertimeData}
     * @memberof DataDepartmentFieldsAllOf
     */
    'overtimeData'?: OvertimeData;
}
/**
 * 
 * @export
 * @interface DataFields
 */
export interface DataFields {
    /**
     * project\'s description
     * @type {string}
     * @memberof DataFields
     */
    'description'?: string;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DataFields
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DataFields
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DataFields
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DataFields
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {string}
     * @memberof DataFields
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DataFields
     */
    'clusterId': string;
}
/**
 * 
 * @export
 * @interface DataFieldsAllOf
 */
export interface DataFieldsAllOf {
    /**
     * 
     * @type {string}
     * @memberof DataFieldsAllOf
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DataFieldsAllOf
     */
    'clusterId': string;
}
/**
 * Updatable fields
 * @export
 * @interface DataUpdatableDepartmentFields
 */
export interface DataUpdatableDepartmentFields {
    /**
     * department\'s description
     * @type {string}
     * @memberof DataUpdatableDepartmentFields
     */
    'description'?: string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DataUpdatableDepartmentFields
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DataUpdatableDepartmentFields
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DataUpdatableDepartmentFields
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DataUpdatableDepartmentFields
     */
    'nodeTypes'?: NodeTypesPerWorkload;
}
/**
 * Updatable fields
 * @export
 * @interface DataUpdatableFields
 */
export interface DataUpdatableFields {
    /**
     * project\'s description
     * @type {string}
     * @memberof DataUpdatableFields
     */
    'description'?: string;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DataUpdatableFields
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DataUpdatableFields
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DataUpdatableFields
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DataUpdatableFields
     */
    'resources': Array<Resources>;
}
/**
 * 
 * @export
 * @interface Deletion
 */
export interface Deletion {
    /**
     * 
     * @type {DeletionTypes}
     * @memberof Deletion
     */
    'type': DeletionTypes;
    /**
     * 
     * @type {string}
     * @memberof Deletion
     */
    'timestamp': string;
    /**
     * 
     * @type {boolean}
     * @memberof Deletion
     */
    'force': boolean;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DeletionTypes = {
    IfEmpty: 'IfEmpty',
    Cascade: 'Cascade',
    Orphan: 'Orphan'
} as const;

export type DeletionTypes = typeof DeletionTypes[keyof typeof DeletionTypes];


/**
 * 
 * @export
 * @interface Department
 */
export interface Department {
    /**
     * department\'s description
     * @type {string}
     * @memberof Department
     */
    'description': string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof Department
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof Department
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof Department
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof Department
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Department
     */
    'clusterId': string;
    /**
     * 
     * @type {OvertimeData}
     * @memberof Department
     */
    'overtimeData'?: OvertimeData;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Department
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {Array<DepartmentProjectChild>}
     * @memberof Department
     */
    'children'?: Array<DepartmentProjectChild> | null;
    /**
     * 
     * @type {Array<AggregatedResources>}
     * @memberof Department
     */
    'projectsAggregatedResources'?: Array<AggregatedResources> | null;
    /**
     * 
     * @type {DepartmentAllOfStatus}
     * @memberof Department
     */
    'status'?: DepartmentAllOfStatus;
    /**
     * 
     * @type {TotalResources}
     * @memberof Department
     */
    'totalResources': TotalResources;
}
/**
 * 
 * @export
 * @interface DepartmentAllOf
 */
export interface DepartmentAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentAllOf
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {Array<DepartmentProjectChild>}
     * @memberof DepartmentAllOf
     */
    'children'?: Array<DepartmentProjectChild> | null;
    /**
     * 
     * @type {Array<AggregatedResources>}
     * @memberof DepartmentAllOf
     */
    'projectsAggregatedResources'?: Array<AggregatedResources> | null;
    /**
     * 
     * @type {DepartmentAllOfStatus}
     * @memberof DepartmentAllOf
     */
    'status'?: DepartmentAllOfStatus;
    /**
     * 
     * @type {TotalResources}
     * @memberof DepartmentAllOf
     */
    'totalResources': TotalResources;
}
/**
 * 
 * @export
 * @interface DepartmentAllOfStatus
 */
export interface DepartmentAllOfStatus {
    /**
     * 
     * @type {Array<QuotaStatusNodePool>}
     * @memberof DepartmentAllOfStatus
     */
    'nodePoolQuotaStatuses'?: Array<QuotaStatusNodePool>;
    /**
     * 
     * @type {QuotaStatus}
     * @memberof DepartmentAllOfStatus
     */
    'quotaStatus'?: QuotaStatus;
}
/**
 * 
 * @export
 * @interface DepartmentCreationRequest
 */
export interface DepartmentCreationRequest {
    /**
     * department\'s description
     * @type {string}
     * @memberof DepartmentCreationRequest
     */
    'description'?: string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DepartmentCreationRequest
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DepartmentCreationRequest
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DepartmentCreationRequest
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DepartmentCreationRequest
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * 
     * @type {string}
     * @memberof DepartmentCreationRequest
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentCreationRequest
     */
    'clusterId': string;
    /**
     * 
     * @type {OvertimeData}
     * @memberof DepartmentCreationRequest
     */
    'overtimeData'?: OvertimeData;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DepartmentFilterSortFields = {
    Name: 'name',
    ClusterId: 'clusterId',
    TotalGpuQuota: 'totalGpuQuota',
    GpuAllocated: 'gpuAllocated',
    CreatedAt: 'createdAt',
    AvgGpuAllocation24h: 'avgGpuAllocation24h',
    AvgGpuUtilization24h: 'avgGpuUtilization24h',
    AvgGpuAllocation7d: 'avgGpuAllocation7d',
    AvgGpuUtilization7d: 'avgGpuUtilization7d',
    AvgGpuAllocation30d: 'avgGpuAllocation30d',
    AvgGpuUtilization30d: 'avgGpuUtilization30d'
} as const;

export type DepartmentFilterSortFields = typeof DepartmentFilterSortFields[keyof typeof DepartmentFilterSortFields];


/**
 * 
 * @export
 * @interface DepartmentForGetV1
 */
export interface DepartmentForGetV1 {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof DepartmentForGetV1
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1Response>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentForGetV1
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentForGetV1
     * @deprecated
     */
    'deservedGpus': number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentForGetV1
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentForGetV1
     * @deprecated
     */
    'maxAllowedGpus': number;
    /**
     * 
     * @type {DepartmentV1CommonFieldsResponseResources}
     * @memberof DepartmentForGetV1
     * @deprecated
     */
    'resources'?: DepartmentV1CommonFieldsResponseResources;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof DepartmentForGetV1
     */
    'tenantId'?: number;
    /**
     * The cluster UUID this department belongs to.
     * @type {string}
     * @memberof DepartmentForGetV1
     */
    'clusterUuid'?: string;
    /**
     * The creation date of the department.
     * @type {string}
     * @memberof DepartmentForGetV1
     */
    'createdAt'?: string;
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof DepartmentForGetV1
     */
    'id'?: number;
    /**
     * Projects under this department.
     * @type {Array<ProjectV1>}
     * @memberof DepartmentForGetV1
     */
    'projects': Array<ProjectV1>;
    /**
     * Deprecated. Instead, use \'nodePoolsResources\' field. Total deserved GPUs of the projects under this department - as string.
     * @type {string}
     * @memberof DepartmentForGetV1
     * @deprecated
     */
    'projectsDeservedGpus'?: string;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof DepartmentForGetV1
     */
    'departmentAdmins'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DepartmentForGetV1
     */
    'quotaStatuses'?: Array<object> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof DepartmentForGetV1
     */
    'projectAggregatedNodePoolsResources'?: Array<object> | null;
}
/**
 * 
 * @export
 * @interface DepartmentForGetV1AllOf
 */
export interface DepartmentForGetV1AllOf {
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof DepartmentForGetV1AllOf
     */
    'tenantId'?: number;
    /**
     * The cluster UUID this department belongs to.
     * @type {string}
     * @memberof DepartmentForGetV1AllOf
     */
    'clusterUuid'?: string;
    /**
     * The creation date of the department.
     * @type {string}
     * @memberof DepartmentForGetV1AllOf
     */
    'createdAt'?: string;
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof DepartmentForGetV1AllOf
     */
    'id'?: number;
    /**
     * Projects under this department.
     * @type {Array<ProjectV1>}
     * @memberof DepartmentForGetV1AllOf
     */
    'projects': Array<ProjectV1>;
    /**
     * Deprecated. Instead, use \'nodePoolsResources\' field. Total deserved GPUs of the projects under this department - as string.
     * @type {string}
     * @memberof DepartmentForGetV1AllOf
     * @deprecated
     */
    'projectsDeservedGpus'?: string;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof DepartmentForGetV1AllOf
     */
    'departmentAdmins'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof DepartmentForGetV1AllOf
     */
    'quotaStatuses'?: Array<object> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof DepartmentForGetV1AllOf
     */
    'projectAggregatedNodePoolsResources'?: Array<object> | null;
}
/**
 * 
 * @export
 * @interface DepartmentForList
 */
export interface DepartmentForList {
    /**
     * department\'s description
     * @type {string}
     * @memberof DepartmentForList
     */
    'description'?: string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DepartmentForList
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DepartmentForList
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DepartmentForList
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DepartmentForList
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentForList
     */
    'clusterId': string;
    /**
     * 
     * @type {OvertimeData}
     * @memberof DepartmentForList
     */
    'overtimeData'?: OvertimeData;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForList
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {Array<DepartmentProjectChild>}
     * @memberof DepartmentForList
     */
    'children'?: Array<DepartmentProjectChild> | null;
    /**
     * 
     * @type {DepartmentForListAllOfStatus}
     * @memberof DepartmentForList
     */
    'status'?: DepartmentForListAllOfStatus | null;
    /**
     * 
     * @type {TotalResources}
     * @memberof DepartmentForList
     */
    'totalResources': TotalResources;
}
/**
 * 
 * @export
 * @interface DepartmentForListAllOf
 */
export interface DepartmentForListAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentForListAllOf
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForListAllOf
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForListAllOf
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForListAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentForListAllOf
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {Array<DepartmentProjectChild>}
     * @memberof DepartmentForListAllOf
     */
    'children'?: Array<DepartmentProjectChild> | null;
    /**
     * 
     * @type {DepartmentForListAllOfStatus}
     * @memberof DepartmentForListAllOf
     */
    'status'?: DepartmentForListAllOfStatus | null;
    /**
     * 
     * @type {TotalResources}
     * @memberof DepartmentForListAllOf
     */
    'totalResources': TotalResources;
}
/**
 * 
 * @export
 * @interface DepartmentForListAllOfStatus
 */
export interface DepartmentForListAllOfStatus {
    /**
     * 
     * @type {QuotaStatus}
     * @memberof DepartmentForListAllOfStatus
     */
    'quotaStatus'?: QuotaStatus;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1
 */
export interface DepartmentMetricsV1 {
    /**
     * 
     * @type {DepartmentMetricsV1Metadata}
     * @memberof DepartmentMetricsV1
     */
    'metadata': DepartmentMetricsV1Metadata;
    /**
     * 
     * @type {DepartmentMetricsV1Current}
     * @memberof DepartmentMetricsV1
     */
    'current': DepartmentMetricsV1Current;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof DepartmentMetricsV1
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * Current reported metrics data. resources holds data that is summed up to the department level and projectResources explains the division of it to projects
 * @export
 * @interface DepartmentMetricsV1Current
 */
export interface DepartmentMetricsV1Current {
    /**
     * 
     * @type {Array<DepartmentMetricsV1CurrentResourcesInner>}
     * @memberof DepartmentMetricsV1Current
     */
    'resources': Array<DepartmentMetricsV1CurrentResourcesInner>;
    /**
     * 
     * @type {Array<DepartmentMetricsV1CurrentProjectResourcesInner>}
     * @memberof DepartmentMetricsV1Current
     */
    'projectResources': Array<DepartmentMetricsV1CurrentProjectResourcesInner>;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1CurrentProjectResourcesInner
 */
export interface DepartmentMetricsV1CurrentProjectResourcesInner {
    /**
     * 
     * @type {number}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'gpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'cpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'memory': MetricsResourceDataV1;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInner
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1CurrentProjectResourcesInnerAllOf
 */
export interface DepartmentMetricsV1CurrentProjectResourcesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInnerAllOf
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentProjectResourcesInnerAllOf
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1CurrentResourcesInner
 */
export interface DepartmentMetricsV1CurrentResourcesInner {
    /**
     * 
     * @type {number}
     * @memberof DepartmentMetricsV1CurrentResourcesInner
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentResourcesInner
     */
    'gpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentResourcesInner
     */
    'cpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof DepartmentMetricsV1CurrentResourcesInner
     */
    'memory': MetricsResourceDataV1;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentResourcesInner
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1CurrentResourcesInnerAllOf
 */
export interface DepartmentMetricsV1CurrentResourcesInnerAllOf {
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1CurrentResourcesInnerAllOf
     */
    'nodepoolName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1Metadata
 */
export interface DepartmentMetricsV1Metadata {
    /**
     * The id of the department
     * @type {number}
     * @memberof DepartmentMetricsV1Metadata
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1Metadata
     */
    'departmentName': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentMetricsV1Metadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentMetricsV1Metadata
     */
    'clusterName': string;
}
/**
 * 
 * @export
 * @interface DepartmentMetricsV1Response
 */
export interface DepartmentMetricsV1Response {
    /**
     * 
     * @type {DepartmentMetricsV1Metadata}
     * @memberof DepartmentMetricsV1Response
     */
    'metadata': DepartmentMetricsV1Metadata;
    /**
     * 
     * @type {DepartmentMetricsV1Current}
     * @memberof DepartmentMetricsV1Response
     */
    'current': DepartmentMetricsV1Current;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof DepartmentMetricsV1Response
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * Department or project child
 * @export
 * @interface DepartmentProjectChild
 */
export interface DepartmentProjectChild {
    /**
     * 
     * @type {string}
     * @memberof DepartmentProjectChild
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentProjectChild
     */
    'name': string;
    /**
     * 
     * @type {Array<DepartmentProjectChild>}
     * @memberof DepartmentProjectChild
     */
    'children'?: Array<DepartmentProjectChild>;
}
/**
 * Department or project parent
 * @export
 * @interface DepartmentProjectParent
 */
export interface DepartmentProjectParent {
    /**
     * 
     * @type {string}
     * @memberof DepartmentProjectParent
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentProjectParent
     */
    'name': string;
    /**
     * 
     * @type {DepartmentProjectParent}
     * @memberof DepartmentProjectParent
     */
    'parent'?: DepartmentProjectParent | null;
}
/**
 * 
 * @export
 * @interface DepartmentStatus
 */
export interface DepartmentStatus {
    /**
     * 
     * @type {string}
     * @memberof DepartmentStatus
     */
    'lastUpdatedTime'?: string | null;
}
/**
 * status update fields
 * @export
 * @interface DepartmentStatusUpdateRequest
 */
export interface DepartmentStatusUpdateRequest {
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentStatusUpdateRequest
     */
    'clusterId'?: string;
    /**
     * 
     * @type {DepartmentStatus}
     * @memberof DepartmentStatusUpdateRequest
     */
    'status': DepartmentStatus;
}
/**
 * 
 * @export
 * @interface DepartmentSync
 */
export interface DepartmentSync {
    /**
     * 
     * @type {string}
     * @memberof DepartmentSync
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSync
     */
    'name'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof DepartmentSync
     */
    'clusterId'?: string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSync
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSync
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof DepartmentSync
     */
    'deletedAt'?: string | null;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<ResourcesSync>}
     * @memberof DepartmentSync
     */
    'resources'?: Array<ResourcesSync>;
}
/**
 * 
 * @export
 * @interface DepartmentUpdateRequest
 */
export interface DepartmentUpdateRequest {
    /**
     * department\'s description
     * @type {string}
     * @memberof DepartmentUpdateRequest
     */
    'description'?: string;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof DepartmentUpdateRequest
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof DepartmentUpdateRequest
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof DepartmentUpdateRequest
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof DepartmentUpdateRequest
     */
    'nodeTypes'?: NodeTypesPerWorkload;
}
/**
 * 
 * @export
 * @interface DepartmentV1AccessControl
 */
export interface DepartmentV1AccessControl {
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof DepartmentV1AccessControl
     */
    'departmentId'?: number;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof DepartmentV1AccessControl
     */
    'departmentAdmins'?: Array<string>;
}
/**
 * 
 * @export
 * @interface DepartmentV1CommonFields
 */
export interface DepartmentV1CommonFields {
    [key: string]: any;

    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResourcesV1>}
     * @memberof DepartmentV1CommonFields
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentV1CommonFields
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CommonFields
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentV1CommonFields
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CommonFields
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentV1CommonFieldsResources}
     * @memberof DepartmentV1CommonFields
     * @deprecated
     */
    'resources'?: DepartmentV1CommonFieldsResources;
}
/**
 * Deprecated. Instead, use \'nodePoolsResources\'. Total resources assigned to the Department. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in GET are the sum of all Node Pool Resources.
 * @export
 * @interface DepartmentV1CommonFieldsResources
 */
export interface DepartmentV1CommonFieldsResources {
    /**
     * 
     * @type {number}
     * @memberof DepartmentV1CommonFieldsResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1Gpu}
     * @memberof DepartmentV1CommonFieldsResources
     */
    'gpu'?: AssignedResourcesV1Gpu;
    /**
     * 
     * @type {AssignedResourcesV1Cpu}
     * @memberof DepartmentV1CommonFieldsResources
     */
    'cpu'?: AssignedResourcesV1Cpu;
    /**
     * 
     * @type {AssignedResourcesV1Memory}
     * @memberof DepartmentV1CommonFieldsResources
     */
    'memory'?: AssignedResourcesV1Memory;
}
/**
 * 
 * @export
 * @interface DepartmentV1CommonFieldsResponse
 */
export interface DepartmentV1CommonFieldsResponse {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof DepartmentV1CommonFieldsResponse
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1Response>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentV1CommonFieldsResponse
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CommonFieldsResponse
     * @deprecated
     */
    'deservedGpus': number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentV1CommonFieldsResponse
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CommonFieldsResponse
     * @deprecated
     */
    'maxAllowedGpus': number;
    /**
     * 
     * @type {DepartmentV1CommonFieldsResponseResources}
     * @memberof DepartmentV1CommonFieldsResponse
     * @deprecated
     */
    'resources'?: DepartmentV1CommonFieldsResponseResources;
}
/**
 * Deprecated. Instead, use \'nodePoolsResources\'. Total resources assigned to the Department. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in GET are the sum of all Node Pool Resources.
 * @export
 * @interface DepartmentV1CommonFieldsResponseResources
 */
export interface DepartmentV1CommonFieldsResponseResources {
    /**
     * 
     * @type {number}
     * @memberof DepartmentV1CommonFieldsResponseResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1ResponseGpu}
     * @memberof DepartmentV1CommonFieldsResponseResources
     */
    'gpu': AssignedResourcesV1ResponseGpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseCpu}
     * @memberof DepartmentV1CommonFieldsResponseResources
     */
    'cpu': AssignedResourcesV1ResponseCpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseMemory}
     * @memberof DepartmentV1CommonFieldsResponseResources
     */
    'memory': AssignedResourcesV1ResponseMemory;
}
/**
 * 
 * @export
 * @interface DepartmentV1CreationRequest
 */
export interface DepartmentV1CreationRequest {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResourcesV1>}
     * @memberof DepartmentV1CreationRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof DepartmentV1CreationRequest
     */
    'name': string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CreationRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof DepartmentV1CreationRequest
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof DepartmentV1CreationRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * 
     * @type {DepartmentV1CommonFieldsResources}
     * @memberof DepartmentV1CreationRequest
     * @deprecated
     */
    'resources'?: DepartmentV1CommonFieldsResources;
}
/**
 * 
 * @export
 * @interface DepartmentsMetricsV1Response
 */
export interface DepartmentsMetricsV1Response {
    /**
     * 
     * @type {Array<DepartmentsMetricsV1ResponseDataInner>}
     * @memberof DepartmentsMetricsV1Response
     */
    'data': Array<DepartmentsMetricsV1ResponseDataInner>;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof DepartmentsMetricsV1Response
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * 
 * @export
 * @interface DepartmentsMetricsV1ResponseDataInner
 */
export interface DepartmentsMetricsV1ResponseDataInner {
    /**
     * 
     * @type {DepartmentMetricsV1Metadata}
     * @memberof DepartmentsMetricsV1ResponseDataInner
     */
    'metadata': DepartmentMetricsV1Metadata;
    /**
     * 
     * @type {DepartmentMetricsV1Current}
     * @memberof DepartmentsMetricsV1ResponseDataInner
     */
    'current': DepartmentMetricsV1Current;
    /**
     * 
     * @type {any}
     * @memberof DepartmentsMetricsV1ResponseDataInner
     */
    'timeRange'?: any;
}
/**
 * 
 * @export
 * @interface DepartmentsMetricsV1ResponseDataInnerAllOf
 */
export interface DepartmentsMetricsV1ResponseDataInnerAllOf {
    /**
     * 
     * @type {any}
     * @memberof DepartmentsMetricsV1ResponseDataInnerAllOf
     */
    'timeRange'?: any;
}
/**
 * 
 * @export
 * @interface DeservedResource
 */
export interface DeservedResource {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof DeservedResource
     */
    'deserved'?: number | null;
}
/**
 * 
 * @export
 * @interface GetDepartments200Response
 */
export interface GetDepartments200Response {
    /**
     * 
     * @type {Array<DepartmentForList>}
     * @memberof GetDepartments200Response
     */
    'departments': Array<DepartmentForList>;
    /**
     * 
     * @type {number}
     * @memberof GetDepartments200Response
     */
    'next'?: number;
}
/**
 * 
 * @export
 * @interface GetDepartmentsV1200ResponseInner
 */
export interface GetDepartmentsV1200ResponseInner {
    /**
     * Resources assigned to the Department per node pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1Response>;
    /**
     * The name of the department.
     * @type {string}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'name'?: string;
    /**
     * Deprecated. Instead, use `deserved` for the relevant resource type under `NodePoolResources`. Deserved GPUs for the department.
     * @type {number}
     * @memberof GetDepartmentsV1200ResponseInner
     * @deprecated
     */
    'deservedGpus': number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Is over quota allowed for the department.
     * @type {boolean}
     * @memberof GetDepartmentsV1200ResponseInner
     * @deprecated
     */
    'allowOverQuota'?: boolean;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. Max allowed GPUs for the department.
     * @type {number}
     * @memberof GetDepartmentsV1200ResponseInner
     * @deprecated
     */
    'maxAllowedGpus': number;
    /**
     * 
     * @type {DepartmentV1CommonFieldsResponseResources}
     * @memberof GetDepartmentsV1200ResponseInner
     * @deprecated
     */
    'resources'?: DepartmentV1CommonFieldsResponseResources;
    /**
     * The tenant id this cluster belongs to.
     * @type {number}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'tenantId'?: number;
    /**
     * The cluster UUID this department belongs to.
     * @type {string}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'clusterUuid'?: string;
    /**
     * The creation date of the department.
     * @type {string}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'createdAt'?: string;
    /**
     * The unique id identifying the department.
     * @type {number}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'id'?: number;
    /**
     * Projects under this department.
     * @type {Array<ProjectV1>}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'projects': Array<ProjectV1>;
    /**
     * Deprecated. Instead, use \'nodePoolsResources\' field. Total deserved GPUs of the projects under this department - as string.
     * @type {string}
     * @memberof GetDepartmentsV1200ResponseInner
     * @deprecated
     */
    'projectsDeservedGpus'?: string;
    /**
     * Id\'s of users with department admin role that are assigned to managed the department
     * @type {Array<string>}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'departmentAdmins'?: Array<string>;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'quotaStatuses'?: Array<object> | null;
    /**
     * 
     * @type {Array<object>}
     * @memberof GetDepartmentsV1200ResponseInner
     */
    'projectAggregatedNodePoolsResources'?: Array<object> | null;
}
/**
 * 
 * @export
 * @interface GetNodeTypes200Response
 */
export interface GetNodeTypes200Response {
    /**
     * 
     * @type {Array<GetNodeTypes200ResponseNodeTypesInner>}
     * @memberof GetNodeTypes200Response
     */
    'nodeTypes'?: Array<GetNodeTypes200ResponseNodeTypesInner>;
}
/**
 * 
 * @export
 * @interface GetNodeTypes200ResponseNodeTypesInner
 */
export interface GetNodeTypes200ResponseNodeTypesInner {
    /**
     * 
     * @type {string}
     * @memberof GetNodeTypes200ResponseNodeTypesInner
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetNodeTypes200ResponseNodeTypesInner
     */
    'name'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof GetNodeTypes200ResponseNodeTypesInner
     */
    'clusterId'?: string;
}
/**
 * 
 * @export
 * @interface GetOrgTree200Response
 */
export interface GetOrgTree200Response {
    /**
     * 
     * @type {Array<OrgUnit>}
     * @memberof GetOrgTree200Response
     */
    'orgUnits': Array<OrgUnit>;
}
/**
 * 
 * @export
 * @interface GetProjects200Response
 */
export interface GetProjects200Response {
    /**
     * 
     * @type {Array<Project>}
     * @memberof GetProjects200Response
     */
    'projects': Array<Project>;
    /**
     * 
     * @type {number}
     * @memberof GetProjects200Response
     */
    'next'?: number;
}
/**
 * 
 * @export
 * @interface GetProjectsMetadata200Response
 */
export interface GetProjectsMetadata200Response {
    /**
     * 
     * @type {Array<GetProjectsMetadata200ResponseProjectsInner>}
     * @memberof GetProjectsMetadata200Response
     */
    'projects': Array<GetProjectsMetadata200ResponseProjectsInner>;
    /**
     * 
     * @type {number}
     * @memberof GetProjectsMetadata200Response
     */
    'next'?: number;
}
/**
 * 
 * @export
 * @interface GetProjectsMetadata200ResponseProjectsInner
 */
export interface GetProjectsMetadata200ResponseProjectsInner {
    /**
     * 
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'phase': string | null;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'createdAt': string;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'allocated': QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof GetProjectsMetadata200ResponseProjectsInner
     */
    'deserved': QuotaStatusResource;
}
/**
 * 
 * @export
 * @interface GetSyncDepartments200Response
 */
export interface GetSyncDepartments200Response {
    /**
     * 
     * @type {Array<DepartmentSync>}
     * @memberof GetSyncDepartments200Response
     */
    'departments': Array<DepartmentSync>;
    /**
     * 
     * @type {number}
     * @memberof GetSyncDepartments200Response
     */
    'next'?: number;
}
/**
 * 
 * @export
 * @interface GetSyncProjects200Response
 */
export interface GetSyncProjects200Response {
    /**
     * 
     * @type {Array<ProjectSync>}
     * @memberof GetSyncProjects200Response
     */
    'projects': Array<ProjectSync>;
    /**
     * 
     * @type {number}
     * @memberof GetSyncProjects200Response
     */
    'next'?: number;
}
/**
 * 
 * @export
 * @interface HttpResponse
 */
export interface HttpResponse {
    /**
     * 
     * @type {number}
     * @memberof HttpResponse
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof HttpResponse
     */
    'message': string;
}
/**
 * 
 * @export
 * @interface ListReports200Response
 */
export interface ListReports200Response {
    /**
     * 
     * @type {Array<Report>}
     * @memberof ListReports200Response
     */
    'reports': Array<Report>;
}
/**
 * 
 * @export
 * @interface MeasurementResponse
 */
export interface MeasurementResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof MeasurementResponse
     */
    'type': string;
    /**
     * labels of the metric measurement
     * @type {{ [key: string]: string; }}
     * @memberof MeasurementResponse
     */
    'labels'?: { [key: string]: string; } | null;
    /**
     * 
     * @type {Array<MeasurementResponseValuesInner>}
     * @memberof MeasurementResponse
     */
    'values': Array<MeasurementResponseValuesInner> | null;
}
/**
 * 
 * @export
 * @interface MeasurementResponseValuesInner
 */
export interface MeasurementResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof MeasurementResponseValuesInner
     */
    'timestamp': string | null;
}
/**
 * quota equals to the given resources and allocated equal to the real count of used resources
 * @export
 * @interface MetricsResourceDataV1
 */
export interface MetricsResourceDataV1 {
    /**
     * 
     * @type {number}
     * @memberof MetricsResourceDataV1
     */
    'quota': number;
    /**
     * 
     * @type {number}
     * @memberof MetricsResourceDataV1
     */
    'allocated': number;
    /**
     * 
     * @type {number}
     * @memberof MetricsResourceDataV1
     */
    'utilization'?: number;
}
/**
 * 
 * @export
 * @interface MetricsResourcesDataV1
 */
export interface MetricsResourcesDataV1 {
    /**
     * 
     * @type {number}
     * @memberof MetricsResourcesDataV1
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof MetricsResourcesDataV1
     */
    'gpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof MetricsResourcesDataV1
     */
    'cpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof MetricsResourcesDataV1
     */
    'memory': MetricsResourceDataV1;
}
/**
 * 
 * @export
 * @interface MetricsResponse
 */
export interface MetricsResponse {
    /**
     * 
     * @type {Array<MeasurementResponse>}
     * @memberof MetricsResponse
     */
    'measurements': Array<MeasurementResponse>;
}
/**
 * 
 * @export
 * @interface ModelError
 */
export interface ModelError {
    /**
     * 
     * @type {number}
     * @memberof ModelError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof ModelError
     */
    'details'?: string;
}
/**
 * 
 * @export
 * @interface NodeAffinityV1
 */
export interface NodeAffinityV1 {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof NodeAffinityV1
     */
    'affinityType'?: NodeAffinityV1AffinityTypeEnum;
    /**
     * 
     * @type {Array<NodeAffinityV1SelectedTypesInner>}
     * @memberof NodeAffinityV1
     */
    'selectedTypes'?: Array<NodeAffinityV1SelectedTypesInner>;
}

export const NodeAffinityV1AffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type NodeAffinityV1AffinityTypeEnum = typeof NodeAffinityV1AffinityTypeEnum[keyof typeof NodeAffinityV1AffinityTypeEnum];

/**
 * 
 * @export
 * @interface NodeAffinityV1SelectedTypesInner
 */
export interface NodeAffinityV1SelectedTypesInner {
    /**
     * 
     * @type {number}
     * @memberof NodeAffinityV1SelectedTypesInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeAffinityV1SelectedTypesInner
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NodePool
 */
export interface NodePool {
    /**
     * 
     * @type {number}
     * @memberof NodePool
     */
    'uid'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodePool
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodePool
     */
    'name'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof NodePool
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof NodePool
     */
    'operation': NodePoolOperationEnum;
}

export const NodePoolOperationEnum = {
    Created: 'CREATED',
    Deleted: 'DELETED'
} as const;

export type NodePoolOperationEnum = typeof NodePoolOperationEnum[keyof typeof NodePoolOperationEnum];

/**
 * 
 * @export
 * @interface NodePoolAssignedResourcesV1
 */
export interface NodePoolAssignedResourcesV1 {
    /**
     * The id of the assigned resources. Required in PUT when updating the assigned resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1
     */
    'id': number;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1ResponseNodePool}
     * @memberof NodePoolAssignedResourcesV1
     */
    'nodePool': NodePoolAssignedResourcesV1ResponseNodePool;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1Gpu}
     * @memberof NodePoolAssignedResourcesV1
     */
    'gpu'?: NodePoolAssignedResourcesV1Gpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1Cpu}
     * @memberof NodePoolAssignedResourcesV1
     */
    'cpu'?: NodePoolAssignedResourcesV1Cpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1Memory}
     * @memberof NodePoolAssignedResourcesV1
     */
    'memory'?: NodePoolAssignedResourcesV1Memory;
}
/**
 * Number of CPU Millicores assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesV1Cpu
 */
export interface NodePoolAssignedResourcesV1Cpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Cpu
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Cpu
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Cpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * Number of GPUs assigned in the node pool.
 * @export
 * @interface NodePoolAssignedResourcesV1Gpu
 */
export interface NodePoolAssignedResourcesV1Gpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Gpu
     */
    'deserved': number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Gpu
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Gpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * Amount of CPU Memory Mib assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesV1Memory
 */
export interface NodePoolAssignedResourcesV1Memory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Memory
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Memory
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Memory
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface NodePoolAssignedResourcesV1Response
 */
export interface NodePoolAssignedResourcesV1Response {
    /**
     * The id of the assigned resources. Required in PUT when updating the assigned resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1Response
     */
    'id': number;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1ResponseNodePool}
     * @memberof NodePoolAssignedResourcesV1Response
     */
    'nodePool': NodePoolAssignedResourcesV1ResponseNodePool;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1ResponseGpu}
     * @memberof NodePoolAssignedResourcesV1Response
     */
    'gpu': NodePoolAssignedResourcesV1ResponseGpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1ResponseCpu}
     * @memberof NodePoolAssignedResourcesV1Response
     */
    'cpu': NodePoolAssignedResourcesV1ResponseCpu;
    /**
     * 
     * @type {NodePoolAssignedResourcesV1ResponseMemory}
     * @memberof NodePoolAssignedResourcesV1Response
     */
    'memory': NodePoolAssignedResourcesV1ResponseMemory;
}
/**
 * Number of CPU Millicores assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesV1ResponseCpu
 */
export interface NodePoolAssignedResourcesV1ResponseCpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseCpu
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseCpu
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseCpu
     */
    'overQuotaWeight': number | null;
}
/**
 * Number of GPUs assigned in the node pool.
 * @export
 * @interface NodePoolAssignedResourcesV1ResponseGpu
 */
export interface NodePoolAssignedResourcesV1ResponseGpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseGpu
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseGpu
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseGpu
     */
    'overQuotaWeight': number | null;
}
/**
 * Amount of CPU Memory Mib assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface NodePoolAssignedResourcesV1ResponseMemory
 */
export interface NodePoolAssignedResourcesV1ResponseMemory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseMemory
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseMemory
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseMemory
     */
    'overQuotaWeight': number | null;
}
/**
 * The node pool which the assigned resources refer to.
 * @export
 * @interface NodePoolAssignedResourcesV1ResponseNodePool
 */
export interface NodePoolAssignedResourcesV1ResponseNodePool {
    /**
     * Node pool id.
     * @type {number}
     * @memberof NodePoolAssignedResourcesV1ResponseNodePool
     */
    'id': number;
    /**
     * Node pool name.
     * @type {string}
     * @memberof NodePoolAssignedResourcesV1ResponseNodePool
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NodeType
 */
export interface NodeType {
    /**
     * 
     * @type {string}
     * @memberof NodeType
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeType
     */
    'name'?: string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof NodeType
     */
    'clusterId'?: string;
}
/**
 * 
 * @export
 * @interface NodeTypeCreateRequest
 */
export interface NodeTypeCreateRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeTypeCreateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeTypeCreateRequest
     */
    'cluster-id'?: string;
}
/**
 * 
 * @export
 * @interface NodeTypeUpdateRequest
 */
export interface NodeTypeUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof NodeTypeUpdateRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof NodeTypeUpdateRequest
     */
    'cluster-id'?: string;
}
/**
 * 
 * @export
 * @interface NodeTypeV1
 */
export interface NodeTypeV1 {
    /**
     * The NodeType ID.
     * @type {number}
     * @memberof NodeTypeV1
     */
    'id'?: number;
    /**
     * The tenant ID.
     * @type {number}
     * @memberof NodeTypeV1
     */
    'tenantId'?: number;
    /**
     * The cluster UUID.
     * @type {string}
     * @memberof NodeTypeV1
     */
    'clusterUuid'?: string;
    /**
     * The NodeType name.
     * @type {string}
     * @memberof NodeTypeV1
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface NodeTypesPerWorkload
 */
export interface NodeTypesPerWorkload {
    /**
     * ids of the node types for training workloads
     * @type {Array<string>}
     * @memberof NodeTypesPerWorkload
     */
    'training'?: Array<string>;
    /**
     * ids of the node types for workspace workloads
     * @type {Array<string>}
     * @memberof NodeTypesPerWorkload
     */
    'workspace'?: Array<string>;
    /**
     * mapping of ids to names
     * @type {{ [key: string]: string; }}
     * @memberof NodeTypesPerWorkload
     */
    'names'?: { [key: string]: string; } | null;
}
/**
 * 
 * @export
 * @interface NonNullResource
 */
export interface NonNullResource {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof NonNullResource
     */
    'deserved': number;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof NonNullResource
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof NonNullResource
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface NonNullResourceV1
 */
export interface NonNullResourceV1 {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof NonNullResourceV1
     */
    'deserved': number;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof NonNullResourceV1
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof NonNullResourceV1
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface OrgUnit
 */
export interface OrgUnit {
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'id': string;
    /**
     * 
     * @type {ScopeType}
     * @memberof OrgUnit
     */
    'type': ScopeType;
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OrgUnit
     */
    'parentId'?: string;
    /**
     * 
     * @type {ScopeType}
     * @memberof OrgUnit
     */
    'parentType'?: ScopeType;
}
/**
 * Specify which metric data to request.
 * @export
 * @enum {string}
 */

export const OrgUnitMetricType = {
    GpuQuota: 'GPU_QUOTA',
    CpuQuotaMillicores: 'CPU_QUOTA_MILLICORES',
    CpuMemoryQuotaMb: 'CPU_MEMORY_QUOTA_MB',
    GpuAllocation: 'GPU_ALLOCATION',
    CpuAllocationMillicores: 'CPU_ALLOCATION_MILLICORES',
    CpuMemoryAllocationMb: 'CPU_MEMORY_ALLOCATION_MB'
} as const;

export type OrgUnitMetricType = typeof OrgUnitMetricType[keyof typeof OrgUnitMetricType];


/**
 * Select a telemetry type.
 * @export
 * @enum {string}
 */

export const OrgUnitTelemetryType = {
    GpuQuota: 'GPU_QUOTA',
    CpuQuota: 'CPU_QUOTA',
    MemoryQuota: 'MEMORY_QUOTA',
    GpuAllocation: 'GPU_ALLOCATION',
    CpuAllocation: 'CPU_ALLOCATION',
    MemoryAllocation: 'MEMORY_ALLOCATION',
    GpuAllocationNonPreemptible: 'GPU_ALLOCATION_NON_PREEMPTIBLE',
    CpuAllocationNonPreemptible: 'CPU_ALLOCATION_NON_PREEMPTIBLE',
    MemoryAllocationNonPreemptible: 'MEMORY_ALLOCATION_NON_PREEMPTIBLE'
} as const;

export type OrgUnitTelemetryType = typeof OrgUnitTelemetryType[keyof typeof OrgUnitTelemetryType];


/**
 * 
 * @export
 * @interface OvertimeData
 */
export interface OvertimeData {
    /**
     * 
     * @type {OvertimeDataRange24hData}
     * @memberof OvertimeData
     */
    'range24hData'?: OvertimeDataRange24hData | null;
    /**
     * 
     * @type {OvertimeDataRange24hData}
     * @memberof OvertimeData
     */
    'range7dData'?: OvertimeDataRange24hData | null;
    /**
     * 
     * @type {OvertimeDataRange24hData}
     * @memberof OvertimeData
     */
    'range30dData'?: OvertimeDataRange24hData | null;
}
/**
 * 
 * @export
 * @interface OvertimeDataRange24hData
 */
export interface OvertimeDataRange24hData {
    /**
     * The average gpu devices allocation of the project/department.
     * @type {number}
     * @memberof OvertimeDataRange24hData
     */
    'averageGpuAllocation'?: number;
    /**
     * The average gpu utilization percentage of the project/department.
     * @type {number}
     * @memberof OvertimeDataRange24hData
     */
    'averageGpuUtilization'?: number;
    /**
     * 
     * @type {string}
     * @memberof OvertimeDataRange24hData
     */
    'updatedAt'?: string;
}
/**
 * Average data for the specified time range, or for the lifetime of the project/department if it exists for less than the time range.
 * @export
 * @interface OvertimeRangeData
 */
export interface OvertimeRangeData {
    /**
     * The average gpu devices allocation of the project/department.
     * @type {number}
     * @memberof OvertimeRangeData
     */
    'averageGpuAllocation'?: number;
    /**
     * The average gpu utilization percentage of the project/department.
     * @type {number}
     * @memberof OvertimeRangeData
     */
    'averageGpuUtilization'?: number;
    /**
     * 
     * @type {string}
     * @memberof OvertimeRangeData
     */
    'updatedAt'?: string;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * project\'s description
     * @type {string}
     * @memberof Project
     */
    'description': string;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof Project
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof Project
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof Project
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof Project
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof Project
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'id': string;
    /**
     * department parent uuid
     * @type {string}
     * @memberof Project
     */
    'parentId': string;
    /**
     * the project\'s namespace only if requested
     * @type {string}
     * @memberof Project
     */
    'requestedNamespace'?: string | null;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof Project
     */
    'status': ProjectStatus;
    /**
     * 
     * @type {TotalResources}
     * @memberof Project
     */
    'totalResources': TotalResources;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {DepartmentProjectParent}
     * @memberof Project
     */
    'parent'?: DepartmentProjectParent | null;
    /**
     * 
     * @type {ProjectEffective}
     * @memberof Project
     */
    'effective': ProjectEffective;
    /**
     * 
     * @type {OvertimeData}
     * @memberof Project
     */
    'overtimeData'?: OvertimeData;
    /**
     * 
     * @type {Deletion}
     * @memberof Project
     */
    'deletion'?: Deletion | null;
}
/**
 * 
 * @export
 * @interface ProjectAggregatedResourcesV1
 */
export interface ProjectAggregatedResourcesV1 {
    /**
     * The id of the assigned resources. Required in PUT when updating the assigned resources.
     * @type {number}
     * @memberof ProjectAggregatedResourcesV1
     */
    'id': number;
    /**
     * 
     * @type {ProjectAggregatedResourcesV1NodePool}
     * @memberof ProjectAggregatedResourcesV1
     */
    'nodePool': ProjectAggregatedResourcesV1NodePool;
    /**
     * 
     * @type {ProjectAggregatedResourcesV1Gpu}
     * @memberof ProjectAggregatedResourcesV1
     */
    'gpu'?: ProjectAggregatedResourcesV1Gpu;
    /**
     * 
     * @type {ProjectAggregatedResourcesV1Cpu}
     * @memberof ProjectAggregatedResourcesV1
     */
    'cpu'?: ProjectAggregatedResourcesV1Cpu;
    /**
     * 
     * @type {ProjectAggregatedResourcesV1Memory}
     * @memberof ProjectAggregatedResourcesV1
     */
    'memory'?: ProjectAggregatedResourcesV1Memory;
}
/**
 * Number of CPU Millicores assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface ProjectAggregatedResourcesV1Cpu
 */
export interface ProjectAggregatedResourcesV1Cpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectAggregatedResourcesV1Cpu
     */
    'deserved'?: number;
}
/**
 * Number of GPUs assigned in the node pool.
 * @export
 * @interface ProjectAggregatedResourcesV1Gpu
 */
export interface ProjectAggregatedResourcesV1Gpu {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectAggregatedResourcesV1Gpu
     */
    'deserved'?: number;
}
/**
 * Amount of CPU Memory Mib assigned in the node pool. Supported only if the \'CPU Resources Quota\' feature flag is enabled.
 * @export
 * @interface ProjectAggregatedResourcesV1Memory
 */
export interface ProjectAggregatedResourcesV1Memory {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectAggregatedResourcesV1Memory
     */
    'deserved'?: number;
}
/**
 * The node pool which the assigned resources refer to.
 * @export
 * @interface ProjectAggregatedResourcesV1NodePool
 */
export interface ProjectAggregatedResourcesV1NodePool {
    /**
     * Node pool id.
     * @type {number}
     * @memberof ProjectAggregatedResourcesV1NodePool
     */
    'id'?: number;
    /**
     * Node pool name.
     * @type {string}
     * @memberof ProjectAggregatedResourcesV1NodePool
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ProjectAllOf
 */
export interface ProjectAllOf {
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'id': string;
    /**
     * department parent uuid
     * @type {string}
     * @memberof ProjectAllOf
     */
    'parentId': string;
    /**
     * the project\'s namespace only if requested
     * @type {string}
     * @memberof ProjectAllOf
     */
    'requestedNamespace'?: string | null;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectAllOf
     */
    'status': ProjectStatus;
    /**
     * 
     * @type {TotalResources}
     * @memberof ProjectAllOf
     */
    'totalResources': TotalResources;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAllOf
     */
    'updatedBy'?: string;
    /**
     * 
     * @type {DepartmentProjectParent}
     * @memberof ProjectAllOf
     */
    'parent'?: DepartmentProjectParent | null;
    /**
     * 
     * @type {ProjectEffective}
     * @memberof ProjectAllOf
     */
    'effective': ProjectEffective;
    /**
     * 
     * @type {OvertimeData}
     * @memberof ProjectAllOf
     */
    'overtimeData'?: OvertimeData;
    /**
     * 
     * @type {Deletion}
     * @memberof ProjectAllOf
     */
    'deletion'?: Deletion | null;
}
/**
 * 
 * @export
 * @interface ProjectCreationRequest
 */
export interface ProjectCreationRequest {
    /**
     * project\'s description
     * @type {string}
     * @memberof ProjectCreationRequest
     */
    'description'?: string;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof ProjectCreationRequest
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof ProjectCreationRequest
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof ProjectCreationRequest
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof ProjectCreationRequest
     */
    'resources': Array<Resources>;
    /**
     * 
     * @type {string}
     * @memberof ProjectCreationRequest
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectCreationRequest
     */
    'clusterId': string;
    /**
     * project\'s requested namespace
     * @type {string}
     * @memberof ProjectCreationRequest
     */
    'requestedNamespace'?: string;
    /**
     * department parent uuid
     * @type {string}
     * @memberof ProjectCreationRequest
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectCreationRequestAllOf
 */
export interface ProjectCreationRequestAllOf {
    /**
     * project\'s requested namespace
     * @type {string}
     * @memberof ProjectCreationRequestAllOf
     */
    'requestedNamespace'?: string;
    /**
     * department parent uuid
     * @type {string}
     * @memberof ProjectCreationRequestAllOf
     */
    'parentId'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectEffective
 */
export interface ProjectEffective {
    /**
     * 
     * @type {SchedulingRules}
     * @memberof ProjectEffective
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof ProjectEffective
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof ProjectEffective
     */
    'nodeTypes'?: NodeTypesPerWorkload;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectFilterSortFields = {
    Name: 'name',
    ClusterId: 'clusterId',
    DepartmentId: 'departmentId',
    ParentId: 'parentId',
    ParentName: 'parentName',
    Phase: 'phase',
    TotalGpuQuota: 'totalGpuQuota',
    GpuAllocated: 'gpuAllocated',
    CreatedAt: 'createdAt',
    AvgGpuAllocation24h: 'avgGpuAllocation24h',
    AvgGpuUtilization24h: 'avgGpuUtilization24h',
    AvgGpuAllocation7d: 'avgGpuAllocation7d',
    AvgGpuUtilization7d: 'avgGpuUtilization7d',
    AvgGpuAllocation30d: 'avgGpuAllocation30d',
    AvgGpuUtilization30d: 'avgGpuUtilization30d'
} as const;

export type ProjectFilterSortFields = typeof ProjectFilterSortFields[keyof typeof ProjectFilterSortFields];


/**
 * 
 * @export
 * @interface ProjectMetadata
 */
export interface ProjectMetadata {
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'phase': string | null;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectMetadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetadata
     */
    'createdAt': string;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof ProjectMetadata
     */
    'allocated': QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof ProjectMetadata
     */
    'deserved': QuotaStatusResource;
}
/**
 * 
 * @export
 * @interface ProjectMetricsV1
 */
export interface ProjectMetricsV1 {
    /**
     * 
     * @type {ProjectMetricsV1Metadata}
     * @memberof ProjectMetricsV1
     */
    'metadata': ProjectMetricsV1Metadata;
    /**
     * 
     * @type {ProjectMetricsV1Current}
     * @memberof ProjectMetricsV1
     */
    'current': ProjectMetricsV1Current;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof ProjectMetricsV1
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * Current reported metrics data. resources holds data that is summed up to the project level and projectResources explains the division of it to nodepools
 * @export
 * @interface ProjectMetricsV1Current
 */
export interface ProjectMetricsV1Current {
    /**
     * 
     * @type {Array<DepartmentMetricsV1CurrentResourcesInner>}
     * @memberof ProjectMetricsV1Current
     */
    'resources': Array<DepartmentMetricsV1CurrentResourcesInner>;
}
/**
 * 
 * @export
 * @interface ProjectMetricsV1Metadata
 */
export interface ProjectMetricsV1Metadata {
    /**
     * 
     * @type {number}
     * @memberof ProjectMetricsV1Metadata
     */
    'projectId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetricsV1Metadata
     */
    'projectName': string;
    /**
     * The id of the department
     * @type {number}
     * @memberof ProjectMetricsV1Metadata
     */
    'departmentId': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetricsV1Metadata
     */
    'departmentName': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectMetricsV1Metadata
     */
    'clusterId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectMetricsV1Metadata
     */
    'clusterName': string;
}
/**
 * 
 * @export
 * @interface ProjectMetricsV1Response
 */
export interface ProjectMetricsV1Response {
    /**
     * 
     * @type {ProjectMetricsV1Metadata}
     * @memberof ProjectMetricsV1Response
     */
    'metadata': ProjectMetricsV1Metadata;
    /**
     * 
     * @type {ProjectMetricsV1Current}
     * @memberof ProjectMetricsV1Response
     */
    'current': ProjectMetricsV1Current;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof ProjectMetricsV1Response
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ProjectPhase = {
    Creating: 'Creating',
    Updating: 'Updating',
    Deleting: 'Deleting',
    Deleted: 'Deleted',
    Initializing: 'Initializing',
    Ready: 'Ready',
    NotReady: 'NotReady',
    Unknown: 'Unknown'
} as const;

export type ProjectPhase = typeof ProjectPhase[keyof typeof ProjectPhase];


/**
 * 
 * @export
 * @interface ProjectQuotasV1Inner
 */
export interface ProjectQuotasV1Inner {
    /**
     * Project id.
     * @type {number}
     * @memberof ProjectQuotasV1Inner
     */
    'id'?: number;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof ProjectQuotasV1Inner
     */
    'tenantId'?: number;
    /**
     * ID of the cluster where the project is located.
     * @type {string}
     * @memberof ProjectQuotasV1Inner
     */
    'clusterUuid'?: string;
    /**
     * Project name.
     * @type {string}
     * @memberof ProjectQuotasV1Inner
     */
    'name'?: string;
    /**
     * The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectQuotasV1Inner
     */
    'deservedGpus'?: number;
    /**
     * The project\'s allocated GPU.
     * @type {number}
     * @memberof ProjectQuotasV1Inner
     */
    'allocatedGpus'?: number;
}
/**
 * 
 * @export
 * @interface ProjectStatus
 */
export interface ProjectStatus {
    /**
     * the project\'s namespace
     * @type {string}
     * @memberof ProjectStatus
     */
    'namespace'?: string | null;
    /**
     * 
     * @type {ProjectPhase}
     * @memberof ProjectStatus
     */
    'phase'?: ProjectPhase;
    /**
     * the project\'s phase message
     * @type {string}
     * @memberof ProjectStatus
     */
    'phaseMessage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectStatus
     */
    'lastUpdatedTime'?: string | null;
    /**
     * 
     * @type {Array<QuotaStatusNodePool>}
     * @memberof ProjectStatus
     */
    'nodePoolQuotaStatuses'?: Array<QuotaStatusNodePool>;
    /**
     * 
     * @type {QuotaStatus}
     * @memberof ProjectStatus
     */
    'quotaStatus'?: QuotaStatus;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ProjectStatus
     */
    'additionalStatusData'?: { [key: string]: any; } | null;
}
/**
 * status update fields
 * @export
 * @interface ProjectStatusUpdateRequest
 */
export interface ProjectStatusUpdateRequest {
    /**
     * 
     * @type {string}
     * @memberof ProjectStatusUpdateRequest
     */
    'id': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectStatusUpdateRequest
     */
    'clusterId': string;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectStatusUpdateRequest
     */
    'status': ProjectStatus;
}
/**
 * 
 * @export
 * @interface ProjectSync
 */
export interface ProjectSync {
    /**
     * 
     * @type {string}
     * @memberof ProjectSync
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSync
     */
    'name': string;
    /**
     * The id of the cluster.
     * @type {string}
     * @memberof ProjectSync
     */
    'clusterId'?: string;
    /**
     * the project\'s namespace only if requested
     * @type {string}
     * @memberof ProjectSync
     */
    'requestedNamespace'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSync
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSync
     */
    'updatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSync
     */
    'deletedAt'?: string | null;
    /**
     * 
     * @type {DepartmentProjectParent}
     * @memberof ProjectSync
     */
    'parent': DepartmentProjectParent | null;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof ProjectSync
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof ProjectSync
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof ProjectSync
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<ResourcesSync>}
     * @memberof ProjectSync
     */
    'resources'?: Array<ResourcesSync>;
    /**
     * 
     * @type {Deletion}
     * @memberof ProjectSync
     */
    'deletion'?: Deletion | null;
    /**
     * 
     * @type {ProjectSyncPermissions}
     * @memberof ProjectSync
     * @deprecated
     */
    'permissions'?: ProjectSyncPermissions;
}
/**
 * Deprecated. Instead, use the `accessRules` API to add permissions to a specific subject in the project scope.
 * @export
 * @interface ProjectSyncPermissions
 */
export interface ProjectSyncPermissions {
    /**
     * Names of users that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectSyncPermissions
     */
    'users'?: Array<string>;
    /**
     * Names of groups that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectSyncPermissions
     */
    'groups'?: Array<string>;
    /**
     * Names of applications that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectSyncPermissions
     */
    'applications'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectUpdateRequest
 */
export interface ProjectUpdateRequest {
    /**
     * project\'s description
     * @type {string}
     * @memberof ProjectUpdateRequest
     */
    'description'?: string;
    /**
     * 
     * @type {SchedulingRules}
     * @memberof ProjectUpdateRequest
     */
    'schedulingRules'?: SchedulingRules | null;
    /**
     * default order of node pools for workloads. will be enforced if no list is defined in workload policy
     * @type {Array<string>}
     * @memberof ProjectUpdateRequest
     */
    'defaultNodePools'?: Array<string> | null;
    /**
     * 
     * @type {NodeTypesPerWorkload}
     * @memberof ProjectUpdateRequest
     */
    'nodeTypes'?: NodeTypesPerWorkload;
    /**
     * Resources assigned to this Organization per Node Pool
     * @type {Array<Resources>}
     * @memberof ProjectUpdateRequest
     */
    'resources': Array<Resources>;
}
/**
 * 
 * @export
 * @interface ProjectV1
 */
export interface ProjectV1 {
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof ProjectV1
     */
    'nodePoolsResources': Array<NodePoolAssignedResourcesV1Response>;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectV1
     * @deprecated
     */
    'deservedGpus': number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectV1
     * @deprecated
     */
    'maxAllowedGpus': number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectV1
     * @deprecated
     */
    'gpuOverQuotaWeight': number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectV1
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectV1
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectV1
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectV1NodeAffinityResponse}
     * @memberof ProjectV1
     */
    'nodeAffinity'?: ProjectV1NodeAffinityResponse | null;
    /**
     * 
     * @type {ProjectV1ResponseCommonFieldsPermissions}
     * @memberof ProjectV1
     * @deprecated
     */
    'permissions'?: ProjectV1ResponseCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectV1ResponseCommonFieldsResources}
     * @memberof ProjectV1
     * @deprecated
     */
    'resources': ProjectV1ResponseCommonFieldsResources;
    /**
     * Project name.
     * @type {string}
     * @memberof ProjectV1
     */
    'name'?: string;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof ProjectV1
     */
    'namespace': string;
    /**
     * Project id.
     * @type {number}
     * @memberof ProjectV1
     */
    'id'?: number;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof ProjectV1
     */
    'departmentId'?: number;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof ProjectV1
     */
    'tenantId'?: number;
    /**
     * ID of the cluster where the project is located.
     * @type {string}
     * @memberof ProjectV1
     */
    'clusterUuid'?: string;
    /**
     * Name of the department where the project is located.
     * @type {string}
     * @memberof ProjectV1
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1
     */
    'interactiveNodeAffinity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1
     */
    'trainNodeAffinity'?: string;
    /**
     * Creation date of the project.
     * @type {string}
     * @memberof ProjectV1
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ProjectV1AllOfStatus}
     * @memberof ProjectV1
     */
    'status'?: ProjectV1AllOfStatus;
    /**
     * project\'s phase
     * @type {string}
     * @memberof ProjectV1
     */
    'phase'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1
     */
    'quotaStatuses'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectV1AllOf
 */
export interface ProjectV1AllOf {
    /**
     * Project name.
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'name'?: string;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof ProjectV1AllOf
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1Response>;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'namespace'?: string;
    /**
     * Project id.
     * @type {number}
     * @memberof ProjectV1AllOf
     */
    'id'?: number;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof ProjectV1AllOf
     */
    'departmentId'?: number;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof ProjectV1AllOf
     */
    'tenantId'?: number;
    /**
     * ID of the cluster where the project is located.
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'clusterUuid'?: string;
    /**
     * Name of the department where the project is located.
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'departmentName'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'interactiveNodeAffinity'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'trainNodeAffinity'?: string;
    /**
     * Creation date of the project.
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'createdAt'?: string;
    /**
     * 
     * @type {ProjectV1AllOfStatus}
     * @memberof ProjectV1AllOf
     */
    'status'?: ProjectV1AllOfStatus;
    /**
     * project\'s phase
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'phase'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1AllOf
     */
    'quotaStatuses'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectV1AllOfStatus
 */
export interface ProjectV1AllOfStatus {
    /**
     * The namespace of the project
     * @type {string}
     * @memberof ProjectV1AllOfStatus
     */
    'namespace'?: string;
    /**
     * Detailed phase message
     * @type {string}
     * @memberof ProjectV1AllOfStatus
     */
    'message'?: string;
    /**
     * project\'s phase
     * @type {string}
     * @memberof ProjectV1AllOfStatus
     */
    'phase'?: string;
    /**
     * 
     * @type {Array<{ [key: string]: string; }>}
     * @memberof ProjectV1AllOfStatus
     */
    'conditions'?: Array<{ [key: string]: string; }>;
    /**
     * The current quota status for each node pool. Supported only for cluster versions 2.15+ or higher.
     * @type {Array<QuotaStatusV1Inner>}
     * @memberof ProjectV1AllOfStatus
     */
    'quotaStatuses': Array<QuotaStatusV1Inner>;
}
/**
 * 
 * @export
 * @interface ProjectV1CommonFields
 */
export interface ProjectV1CommonFields {
    [key: string]: any;

    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesV1>}
     * @memberof ProjectV1CommonFields
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1>;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectV1CommonFields
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectV1CommonFields
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectV1CommonFields
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectV1CommonFields
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectV1CommonFields
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CommonFields
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CommonFields
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectV1CommonFields
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CommonFields
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectV1NodeAffinity}
     * @memberof ProjectV1CommonFields
     */
    'nodeAffinity'?: ProjectV1NodeAffinity | null;
    /**
     * 
     * @type {ProjectV1CommonFieldsPermissions}
     * @memberof ProjectV1CommonFields
     * @deprecated
     */
    'permissions'?: ProjectV1CommonFieldsPermissions;
    /**
     * 
     * @type {ProjectV1CommonFieldsResources}
     * @memberof ProjectV1CommonFields
     * @deprecated
     */
    'resources'?: ProjectV1CommonFieldsResources;
}
/**
 * Deprecated. Instead, use the `accessRules` API to add permissions to a specific subject in the project scope.
 * @export
 * @interface ProjectV1CommonFieldsPermissions
 */
export interface ProjectV1CommonFieldsPermissions {
    /**
     * Names of users that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1CommonFieldsPermissions
     */
    'users'?: Array<string>;
    /**
     * Names of groups that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1CommonFieldsPermissions
     */
    'groups'?: Array<string>;
    /**
     * Names of applications that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1CommonFieldsPermissions
     */
    'applications'?: Array<string>;
}
/**
 * Deprecated. Instead, use `nodePoolsResources`. Total resources assigned to the Project. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in `GET` are the sum of all Node Pool Resources.
 * @export
 * @interface ProjectV1CommonFieldsResources
 */
export interface ProjectV1CommonFieldsResources {
    /**
     * 
     * @type {number}
     * @memberof ProjectV1CommonFieldsResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1Gpu}
     * @memberof ProjectV1CommonFieldsResources
     */
    'gpu'?: AssignedResourcesV1Gpu;
    /**
     * 
     * @type {AssignedResourcesV1Cpu}
     * @memberof ProjectV1CommonFieldsResources
     */
    'cpu'?: AssignedResourcesV1Cpu;
    /**
     * 
     * @type {AssignedResourcesV1Memory}
     * @memberof ProjectV1CommonFieldsResources
     */
    'memory'?: AssignedResourcesV1Memory;
}
/**
 * 
 * @export
 * @interface ProjectV1CreationRequest
 */
export interface ProjectV1CreationRequest {
    [key: string]: any;

    /**
     * Project Name
     * @type {string}
     * @memberof ProjectV1CreationRequest
     */
    'name': string;
    /**
     * ID of the department that owns the project.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'departmentId': number;
    /**
     * The name of an existing namespace to use for the project in the cluster. Supported only for cluster versions 2.12 or higher.
     * @type {string}
     * @memberof ProjectV1CreationRequest
     */
    'namespace'?: string;
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesV1>}
     * @memberof ProjectV1CreationRequest
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1>;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     * @deprecated
     */
    'deservedGpus'?: number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectV1CreationRequest
     * @deprecated
     */
    'maxAllowedGpus'?: number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     * @deprecated
     */
    'gpuOverQuotaWeight'?: number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectV1CreationRequest
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1CreationRequest
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectV1NodeAffinity}
     * @memberof ProjectV1CreationRequest
     */
    'nodeAffinity'?: ProjectV1NodeAffinity | null;
    /**
     * 
     * @type {ProjectV1CommonFieldsPermissions}
     * @memberof ProjectV1CreationRequest
     * @deprecated
     */
    'permissions'?: ProjectV1CommonFieldsPermissions;
    /**
     * 
     * @type {ProjectV1CommonFieldsResources}
     * @memberof ProjectV1CreationRequest
     * @deprecated
     */
    'resources'?: ProjectV1CommonFieldsResources;
}
/**
 * Node affinity configuration for jobs in the project.
 * @export
 * @interface ProjectV1NodeAffinity
 */
export interface ProjectV1NodeAffinity {
    /**
     * 
     * @type {ProjectV1NodeAffinityTrain}
     * @memberof ProjectV1NodeAffinity
     */
    'train'?: ProjectV1NodeAffinityTrain | null;
    /**
     * 
     * @type {ProjectV1NodeAffinityInteractive}
     * @memberof ProjectV1NodeAffinity
     */
    'interactive'?: ProjectV1NodeAffinityInteractive | null;
}
/**
 * Node affinity configuration for interactive jobs.
 * @export
 * @interface ProjectV1NodeAffinityInteractive
 */
export interface ProjectV1NodeAffinityInteractive {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof ProjectV1NodeAffinityInteractive
     */
    'affinityType'?: ProjectV1NodeAffinityInteractiveAffinityTypeEnum;
    /**
     * 
     * @type {Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>}
     * @memberof ProjectV1NodeAffinityInteractive
     */
    'selectedTypes'?: Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>;
}

export const ProjectV1NodeAffinityInteractiveAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type ProjectV1NodeAffinityInteractiveAffinityTypeEnum = typeof ProjectV1NodeAffinityInteractiveAffinityTypeEnum[keyof typeof ProjectV1NodeAffinityInteractiveAffinityTypeEnum];

/**
 * Node affinity configuration for jobs in the project.
 * @export
 * @interface ProjectV1NodeAffinityResponse
 */
export interface ProjectV1NodeAffinityResponse {
    /**
     * 
     * @type {ProjectV1NodeAffinityResponseTrain}
     * @memberof ProjectV1NodeAffinityResponse
     */
    'train'?: ProjectV1NodeAffinityResponseTrain;
    /**
     * 
     * @type {ProjectV1NodeAffinityResponseInteractive}
     * @memberof ProjectV1NodeAffinityResponse
     */
    'interactive'?: ProjectV1NodeAffinityResponseInteractive;
}
/**
 * Node affinity configuration for interactive jobs.
 * @export
 * @interface ProjectV1NodeAffinityResponseInteractive
 */
export interface ProjectV1NodeAffinityResponseInteractive {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof ProjectV1NodeAffinityResponseInteractive
     */
    'affinityType'?: ProjectV1NodeAffinityResponseInteractiveAffinityTypeEnum;
    /**
     * 
     * @type {Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>}
     * @memberof ProjectV1NodeAffinityResponseInteractive
     */
    'selectedTypes': Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>;
}

export const ProjectV1NodeAffinityResponseInteractiveAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type ProjectV1NodeAffinityResponseInteractiveAffinityTypeEnum = typeof ProjectV1NodeAffinityResponseInteractiveAffinityTypeEnum[keyof typeof ProjectV1NodeAffinityResponseInteractiveAffinityTypeEnum];

/**
 * Node affinity configuration for training jobs.
 * @export
 * @interface ProjectV1NodeAffinityResponseTrain
 */
export interface ProjectV1NodeAffinityResponseTrain {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof ProjectV1NodeAffinityResponseTrain
     */
    'affinityType'?: ProjectV1NodeAffinityResponseTrainAffinityTypeEnum;
    /**
     * 
     * @type {Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>}
     * @memberof ProjectV1NodeAffinityResponseTrain
     */
    'selectedTypes': Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>;
}

export const ProjectV1NodeAffinityResponseTrainAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type ProjectV1NodeAffinityResponseTrainAffinityTypeEnum = typeof ProjectV1NodeAffinityResponseTrainAffinityTypeEnum[keyof typeof ProjectV1NodeAffinityResponseTrainAffinityTypeEnum];

/**
 * 
 * @export
 * @interface ProjectV1NodeAffinityResponseTrainSelectedTypesInner
 */
export interface ProjectV1NodeAffinityResponseTrainSelectedTypesInner {
    /**
     * 
     * @type {number}
     * @memberof ProjectV1NodeAffinityResponseTrainSelectedTypesInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectV1NodeAffinityResponseTrainSelectedTypesInner
     */
    'name'?: string;
}
/**
 * Node affinity configuration for training jobs.
 * @export
 * @interface ProjectV1NodeAffinityTrain
 */
export interface ProjectV1NodeAffinityTrain {
    /**
     * The type of affinity of the jobs on the nodes.
     * @type {string}
     * @memberof ProjectV1NodeAffinityTrain
     */
    'affinityType'?: ProjectV1NodeAffinityTrainAffinityTypeEnum;
    /**
     * 
     * @type {Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>}
     * @memberof ProjectV1NodeAffinityTrain
     */
    'selectedTypes'?: Array<ProjectV1NodeAffinityResponseTrainSelectedTypesInner>;
}

export const ProjectV1NodeAffinityTrainAffinityTypeEnum = {
    NoLimit: 'no_limit',
    OnlySelected: 'only_selected'
} as const;

export type ProjectV1NodeAffinityTrainAffinityTypeEnum = typeof ProjectV1NodeAffinityTrainAffinityTypeEnum[keyof typeof ProjectV1NodeAffinityTrainAffinityTypeEnum];

/**
 * 
 * @export
 * @interface ProjectV1ResponseCommonFields
 */
export interface ProjectV1ResponseCommonFields {
    /**
     * Resources assigned to this Project per Node Pool.
     * @type {Array<NodePoolAssignedResourcesV1Response>}
     * @memberof ProjectV1ResponseCommonFields
     */
    'nodePoolsResources'?: Array<NodePoolAssignedResourcesV1Response>;
    /**
     * Deprecated. Use \'deserved\' for the relevant resource type under `NodePoolResources`. The project\'s deserved GPU allocation in case the cluster has those resources.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     * @deprecated
     */
    'deservedGpus': number;
    /**
     * Deprecated. Instead, use `maxAllowed` for the relevant resource type under `NodePoolResources`. An upper limit for the amount of GPUs the project can get (Even if over quota is allowed and resources are available).
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     * @deprecated
     */
    'maxAllowedGpus': number;
    /**
     * Deprecated. Instead, use `overQuotaWeight` for the relevant resource type under `NodePoolResources`. The priority the project gets for over quota resources.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     * @deprecated
     */
    'gpuOverQuotaWeight': number;
    /**
     * Default node pools list for workload submission for this project if a workload doesn\'t specify a node pools list.
     * @type {Array<string>}
     * @memberof ProjectV1ResponseCommonFields
     */
    'defaultNodePools'?: Array<string>;
    /**
     * A limit (in seconds) for the duration of interactive jobs from this project.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     */
    'interactiveJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     */
    'interactiveJobMaxIdleDurationSecs'?: number;
    /**
     * Maximum duration (in seconds) that an interactive preemptible job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     */
    'interactivePreemptibleJobMaxIdleDurationSecs'?: number;
    /**
     * A limit (in seconds) for the duration of training jobs from this project. Available only from cluster version 2.12
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     */
    'trainingJobTimeLimitSecs'?: number;
    /**
     * Maximum duration (in seconds) that a training job can be idle before being terminated.
     * @type {number}
     * @memberof ProjectV1ResponseCommonFields
     */
    'trainingJobMaxIdleDurationSecs'?: number;
    /**
     * 
     * @type {ProjectV1NodeAffinityResponse}
     * @memberof ProjectV1ResponseCommonFields
     */
    'nodeAffinity'?: ProjectV1NodeAffinityResponse | null;
    /**
     * 
     * @type {ProjectV1ResponseCommonFieldsPermissions}
     * @memberof ProjectV1ResponseCommonFields
     * @deprecated
     */
    'permissions'?: ProjectV1ResponseCommonFieldsPermissions;
    /**
     * 
     * @type {ProjectV1ResponseCommonFieldsResources}
     * @memberof ProjectV1ResponseCommonFields
     * @deprecated
     */
    'resources'?: ProjectV1ResponseCommonFieldsResources;
}
/**
 * Deprecated. Instead, use the `accessRules` API to add permissions to a specific subject in the project scope.
 * @export
 * @interface ProjectV1ResponseCommonFieldsPermissions
 */
export interface ProjectV1ResponseCommonFieldsPermissions {
    /**
     * Names of users that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1ResponseCommonFieldsPermissions
     */
    'users': Array<string> | null;
    /**
     * Names of groups that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1ResponseCommonFieldsPermissions
     */
    'groups': Array<string> | null;
    /**
     * Names of applications that have permissions to the project.
     * @type {Array<string>}
     * @memberof ProjectV1ResponseCommonFieldsPermissions
     */
    'applications'?: Array<string>;
}
/**
 * Deprecated. Instead, use `nodePoolsResources`. Total resources assigned to the Project. Can only be used in PUT/POST when there is a single Node Pool in the system. The resources returned in `GET` are the sum of all Node Pool Resources.
 * @export
 * @interface ProjectV1ResponseCommonFieldsResources
 */
export interface ProjectV1ResponseCommonFieldsResources {
    /**
     * 
     * @type {number}
     * @memberof ProjectV1ResponseCommonFieldsResources
     */
    'id'?: number;
    /**
     * 
     * @type {AssignedResourcesV1ResponseGpu}
     * @memberof ProjectV1ResponseCommonFieldsResources
     */
    'gpu': AssignedResourcesV1ResponseGpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseCpu}
     * @memberof ProjectV1ResponseCommonFieldsResources
     */
    'cpu': AssignedResourcesV1ResponseCpu;
    /**
     * 
     * @type {AssignedResourcesV1ResponseMemory}
     * @memberof ProjectV1ResponseCommonFieldsResources
     */
    'memory': AssignedResourcesV1ResponseMemory;
}
/**
 * 
 * @export
 * @interface ProjectV1StatusRequest
 */
export interface ProjectV1StatusRequest {
    /**
     * A json representing the project\'s status
     * @type {string}
     * @memberof ProjectV1StatusRequest
     */
    'status'?: string;
    /**
     * Project Name
     * @type {string}
     * @memberof ProjectV1StatusRequest
     */
    'name'?: string;
    /**
     * name of the department owning the project
     * @type {string}
     * @memberof ProjectV1StatusRequest
     */
    'departmentName'?: string;
    /**
     * The current quota status for each node pool. Supported only for cluster versions 2.15+ or higher.
     * @type {Array<QuotaStatusV1Inner>}
     * @memberof ProjectV1StatusRequest
     */
    'quotaStatuses'?: Array<QuotaStatusV1Inner>;
}
/**
 * 
 * @export
 * @interface ProjectsMetricsV1Response
 */
export interface ProjectsMetricsV1Response {
    /**
     * 
     * @type {Array<ProjectsMetricsV1ResponseDataInner>}
     * @memberof ProjectsMetricsV1Response
     */
    'data': Array<ProjectsMetricsV1ResponseDataInner>;
    /**
     * 
     * @type {TimeRangeV1}
     * @memberof ProjectsMetricsV1Response
     */
    'timeRange'?: TimeRangeV1;
}
/**
 * 
 * @export
 * @interface ProjectsMetricsV1ResponseDataInner
 */
export interface ProjectsMetricsV1ResponseDataInner {
    /**
     * 
     * @type {ProjectMetricsV1Metadata}
     * @memberof ProjectsMetricsV1ResponseDataInner
     */
    'metadata': ProjectMetricsV1Metadata;
    /**
     * 
     * @type {ProjectMetricsV1Current}
     * @memberof ProjectsMetricsV1ResponseDataInner
     */
    'current': ProjectMetricsV1Current;
    /**
     * 
     * @type {any}
     * @memberof ProjectsMetricsV1ResponseDataInner
     */
    'timeRange'?: any;
}
/**
 * Sum of all quota statuses of all the node pools
 * @export
 * @interface QuotaStatus
 */
export interface QuotaStatus {
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatus
     */
    'allocated'?: QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatus
     */
    'allocatedNonPreemptible'?: QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatus
     */
    'requested'?: QuotaStatusResource;
}
/**
 * 
 * @export
 * @interface QuotaStatusNodePool
 */
export interface QuotaStatusNodePool {
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatusNodePool
     */
    'allocated'?: QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatusNodePool
     */
    'allocatedNonPreemptible'?: QuotaStatusResource;
    /**
     * 
     * @type {QuotaStatusResource}
     * @memberof QuotaStatusNodePool
     */
    'requested'?: QuotaStatusResource;
    /**
     * The node pool name which the current quota status refers to
     * @type {string}
     * @memberof QuotaStatusNodePool
     */
    'nodePoolName': string;
    /**
     * The node pool id which the current quota status refers to
     * @type {string}
     * @memberof QuotaStatusNodePool
     */
    'nodePoolId': string;
}
/**
 * 
 * @export
 * @interface QuotaStatusNodePoolAllOf
 */
export interface QuotaStatusNodePoolAllOf {
    /**
     * The node pool name which the current quota status refers to
     * @type {string}
     * @memberof QuotaStatusNodePoolAllOf
     */
    'nodePoolName': string;
    /**
     * The node pool id which the current quota status refers to
     * @type {string}
     * @memberof QuotaStatusNodePoolAllOf
     */
    'nodePoolId': string;
}
/**
 * 
 * @export
 * @interface QuotaStatusResource
 */
export interface QuotaStatusResource {
    /**
     * The number of GPUs (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResource
     */
    'gpu'?: number;
    /**
     * CPU in Millicores (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResource
     */
    'cpu'?: number;
    /**
     * Memory in MB (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResource
     */
    'memory'?: number;
}
/**
 * 
 * @export
 * @interface QuotaStatusResourceListV1
 */
export interface QuotaStatusResourceListV1 {
    /**
     * The number of GPUs (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceListV1
     */
    'gpu'?: number;
    /**
     * CPU in Millicores (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceListV1
     */
    'cpu'?: number;
    /**
     * Memory in Mib (allocated/requested/...).
     * @type {number}
     * @memberof QuotaStatusResourceListV1
     */
    'memory'?: number;
}
/**
 * 
 * @export
 * @interface QuotaStatusV1Inner
 */
export interface QuotaStatusV1Inner {
    /**
     * The node pool name which the current quota status refers to.
     * @type {string}
     * @memberof QuotaStatusV1Inner
     */
    'nodePoolName'?: string;
    /**
     * 
     * @type {QuotaStatusResourceListV1}
     * @memberof QuotaStatusV1Inner
     */
    'allocated'?: QuotaStatusResourceListV1;
    /**
     * 
     * @type {QuotaStatusResourceListV1}
     * @memberof QuotaStatusV1Inner
     */
    'allocatedNonPreemptible'?: QuotaStatusResourceListV1;
    /**
     * 
     * @type {QuotaStatusResourceListV1}
     * @memberof QuotaStatusV1Inner
     */
    'requested'?: QuotaStatusResourceListV1;
}
/**
 * 
 * @export
 * @interface Range
 */
export interface Range {
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof Range
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof Range
     */
    'end': string;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'name': string;
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof Report
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof Report
     */
    'end': string;
    /**
     * 
     * @type {ReportGroupByOptions}
     * @memberof Report
     */
    'groupBy'?: ReportGroupByOptions | null;
    /**
     * Filter results by a parameter. Use the format field-name == value.
     * @type {Array<string>}
     * @memberof Report
     */
    'filterBy'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'createdBy': string;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof Report
     */
    'tenantId': number;
    /**
     * 
     * @type {ReportStatus}
     * @memberof Report
     */
    'status': ReportStatus;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'statusUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    'statusMessage'?: string;
}
/**
 * 
 * @export
 * @interface ReportCalculatedFields
 */
export interface ReportCalculatedFields {
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'createdAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'createdBy': string;
    /**
     * ID of the tenant where the project is located.
     * @type {number}
     * @memberof ReportCalculatedFields
     */
    'tenantId': number;
    /**
     * 
     * @type {ReportStatus}
     * @memberof ReportCalculatedFields
     */
    'status': ReportStatus;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'statusUpdatedAt': string;
    /**
     * 
     * @type {string}
     * @memberof ReportCalculatedFields
     */
    'statusMessage'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportFilterAndSortFields = {
    Name: 'name',
    CreatedAt: 'createdAt',
    CreatedBy: 'createdBy',
    Status: 'status'
} as const;

export type ReportFilterAndSortFields = typeof ReportFilterAndSortFields[keyof typeof ReportFilterAndSortFields];


/**
 * 
 * @export
 * @enum {string}
 */

export const ReportGroupByOptions = {
    Nodepool: 'Nodepool',
    Project: 'Project',
    Department: 'Department',
    Cluster: 'Cluster'
} as const;

export type ReportGroupByOptions = typeof ReportGroupByOptions[keyof typeof ReportGroupByOptions];


/**
 * 
 * @export
 * @interface ReportRequestFields
 */
export interface ReportRequestFields {
    /**
     * 
     * @type {string}
     * @memberof ReportRequestFields
     */
    'name': string;
    /**
     * timestamp from when to fetch data in UTC
     * @type {string}
     * @memberof ReportRequestFields
     */
    'start': string;
    /**
     * timestamp until when to fetch data in UTC
     * @type {string}
     * @memberof ReportRequestFields
     */
    'end': string;
    /**
     * 
     * @type {ReportGroupByOptions}
     * @memberof ReportRequestFields
     */
    'groupBy'?: ReportGroupByOptions | null;
    /**
     * Filter results by a parameter. Use the format field-name == value.
     * @type {Array<string>}
     * @memberof ReportRequestFields
     */
    'filterBy'?: Array<string>;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ReportStatus = {
    Pending: 'Pending',
    Processing: 'Processing',
    Ready: 'Ready',
    Failed: 'Failed'
} as const;

export type ReportStatus = typeof ReportStatus[keyof typeof ReportStatus];


/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof Resource
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof Resource
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof Resource
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface ResourceV1
 */
export interface ResourceV1 {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof ResourceV1
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof ResourceV1
     */
    'maxAllowed'?: number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof ResourceV1
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface ResourceV1Response
 */
export interface ResourceV1Response {
    /**
     * The amount of resources guaranteed to be allocated in case the cluster has those resources.
     * @type {number}
     * @memberof ResourceV1Response
     */
    'deserved': number | null;
    /**
     * Maximum amount of resources that can be allocated. If equal to deserved, no over-quota will be allowed. Use \"-1\" for unlimited over quota.
     * @type {number}
     * @memberof ResourceV1Response
     */
    'maxAllowed': number | null;
    /**
     * The priority for over quota resources.
     * @type {number}
     * @memberof ResourceV1Response
     */
    'overQuotaWeight': number | null;
}
/**
 * 
 * @export
 * @interface Resources
 */
export interface Resources {
    /**
     * 
     * @type {ResourcesNodePool}
     * @memberof Resources
     */
    'nodePool'?: ResourcesNodePool | null;
    /**
     * 
     * @type {ResourcesGpu}
     * @memberof Resources
     */
    'gpu': ResourcesGpu;
    /**
     * 
     * @type {ResourcesCpu}
     * @memberof Resources
     */
    'cpu'?: ResourcesCpu | null;
    /**
     * 
     * @type {ResourcesMemory}
     * @memberof Resources
     */
    'memory'?: ResourcesMemory | null;
}
/**
 * 
 * @export
 * @interface ResourcesCpu
 */
export interface ResourcesCpu {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof ResourcesCpu
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof ResourcesCpu
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof ResourcesCpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface ResourcesDataOvertimeV1
 */
export interface ResourcesDataOvertimeV1 {
    /**
     * 
     * @type {number}
     * @memberof ResourcesDataOvertimeV1
     */
    'numberOfPendingWorkloads': number;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof ResourcesDataOvertimeV1
     */
    'gpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof ResourcesDataOvertimeV1
     */
    'cpu': MetricsResourceDataV1;
    /**
     * 
     * @type {MetricsResourceDataV1}
     * @memberof ResourcesDataOvertimeV1
     */
    'memory': MetricsResourceDataV1;
    /**
     * 
     * @type {string}
     * @memberof ResourcesDataOvertimeV1
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface ResourcesDataOvertimeV1AllOf
 */
export interface ResourcesDataOvertimeV1AllOf {
    /**
     * 
     * @type {string}
     * @memberof ResourcesDataOvertimeV1AllOf
     */
    'timestamp': string;
}
/**
 * 
 * @export
 * @interface ResourcesGpu
 */
export interface ResourcesGpu {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof ResourcesGpu
     */
    'deserved': number;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof ResourcesGpu
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof ResourcesGpu
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface ResourcesMemory
 */
export interface ResourcesMemory {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof ResourcesMemory
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof ResourcesMemory
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof ResourcesMemory
     */
    'overQuotaWeight'?: number | null;
    /**
     * 
     * @type {Units}
     * @memberof ResourcesMemory
     */
    'units': Units;
}
/**
 * Memory to the project in the node pool
 * @export
 * @interface ResourcesMemoryAllOf
 */
export interface ResourcesMemoryAllOf {
    /**
     * 
     * @type {Units}
     * @memberof ResourcesMemoryAllOf
     */
    'units': Units;
}
/**
 * the node pool which the resources refer to
 * @export
 * @interface ResourcesNodePool
 */
export interface ResourcesNodePool {
    /**
     * node pool id
     * @type {string}
     * @memberof ResourcesNodePool
     */
    'id': string;
    /**
     * node pool name
     * @type {string}
     * @memberof ResourcesNodePool
     */
    'name'?: string;
}
/**
 * 
 * @export
 * @interface ResourcesSync
 */
export interface ResourcesSync {
    /**
     * 
     * @type {ResourcesNodePool}
     * @memberof ResourcesSync
     */
    'nodePool'?: ResourcesNodePool | null;
    /**
     * 
     * @type {ResourcesGpu}
     * @memberof ResourcesSync
     */
    'gpu': ResourcesGpu;
    /**
     * 
     * @type {ResourcesCpu}
     * @memberof ResourcesSync
     */
    'cpu'?: ResourcesCpu | null;
    /**
     * 
     * @type {ResourcesSyncMemory}
     * @memberof ResourcesSync
     */
    'memory'?: ResourcesSyncMemory | null;
}
/**
 * 
 * @export
 * @interface ResourcesSyncMemory
 */
export interface ResourcesSyncMemory {
    /**
     * The project resource allocation that it is deserved to get in case the cluster has those resources
     * @type {number}
     * @memberof ResourcesSyncMemory
     */
    'deserved'?: number | null;
    /**
     * Maximum amount of resources the project can get
     * @type {number}
     * @memberof ResourcesSyncMemory
     */
    'limit'?: number | null;
    /**
     * The priority the project gets for over quota resources
     * @type {number}
     * @memberof ResourcesSyncMemory
     */
    'overQuotaWeight'?: number | null;
}
/**
 * 
 * @export
 * @interface SchedulingRules
 */
export interface SchedulingRules {
    /**
     * interactive job time limit in seconds
     * @type {number}
     * @memberof SchedulingRules
     */
    'interactiveJobTimeLimitSeconds'?: number | null;
    /**
     * interactive job max idle duration in seconds
     * @type {number}
     * @memberof SchedulingRules
     */
    'interactiveJobMaxIdleDurationSeconds'?: number | null;
    /**
     * interactive job preempt idle duration in seconds
     * @type {number}
     * @memberof SchedulingRules
     */
    'interactiveJobPreemptIdleDurationSeconds'?: number | null;
    /**
     * training job max idle duration in seconds
     * @type {number}
     * @memberof SchedulingRules
     */
    'trainingJobMaxIdleDurationSeconds'?: number | null;
    /**
     * training job time limit in seconds
     * @type {number}
     * @memberof SchedulingRules
     */
    'trainingJobTimeLimitSeconds'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ScopeType = {
    System: 'system',
    Tenant: 'tenant',
    Cluster: 'cluster',
    Department: 'department',
    Project: 'project'
} as const;

export type ScopeType = typeof ScopeType[keyof typeof ScopeType];


/**
 * 
 * @export
 * @interface SubmissionError
 */
export interface SubmissionError {
    /**
     * 
     * @type {number}
     * @memberof SubmissionError
     */
    'code': number;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'message': string;
    /**
     * 
     * @type {string}
     * @memberof SubmissionError
     */
    'details'?: string;
    /**
     * 
     * @type {Array<ComplianceIssuesComplianceIssuesInner>}
     * @memberof SubmissionError
     */
    'complianceIssues'?: Array<ComplianceIssuesComplianceIssuesInner>;
}
/**
 * 
 * @export
 * @interface SyncPatchRequestInner
 */
export interface SyncPatchRequestInner {
    /**
     * 
     * @type {string}
     * @memberof SyncPatchRequestInner
     */
    'id'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SyncPatchRequestInner
     */
    'sync-failed'?: boolean;
}
/**
 * 
 * @export
 * @interface TelemetryResponse
 */
export interface TelemetryResponse {
    /**
     * specifies what data returned
     * @type {string}
     * @memberof TelemetryResponse
     */
    'type': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponse
     */
    'timestamp': string;
    /**
     * 
     * @type {Array<TelemetryResponseValuesInner>}
     * @memberof TelemetryResponse
     */
    'values': Array<TelemetryResponseValuesInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInner
 */
export interface TelemetryResponseValuesInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInner
     */
    'value': string;
    /**
     * columns the data is grouped by
     * @type {Array<TelemetryResponseValuesInnerGroupsInner>}
     * @memberof TelemetryResponseValuesInner
     */
    'groups'?: Array<TelemetryResponseValuesInnerGroupsInner>;
}
/**
 * 
 * @export
 * @interface TelemetryResponseValuesInnerGroupsInner
 */
export interface TelemetryResponseValuesInnerGroupsInner {
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'key': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'value': string;
    /**
     * 
     * @type {string}
     * @memberof TelemetryResponseValuesInnerGroupsInner
     */
    'name'?: string;
}
/**
 * Overtime metrics data. Returned only if both start and end query parameters are set.
 * @export
 * @interface TimeRangeV1
 */
export interface TimeRangeV1 {
    /**
     * 
     * @type {Array<ResourcesDataOvertimeV1>}
     * @memberof TimeRangeV1
     */
    'resources': Array<ResourcesDataOvertimeV1>;
}
/**
 * 
 * @export
 * @interface TotalResources
 */
export interface TotalResources {
    /**
     * Total gpu quota of the org unit
     * @type {number}
     * @memberof TotalResources
     */
    'gpuQuota'?: number | null;
    /**
     * Total cpu quota of the org unit in Millicores
     * @type {number}
     * @memberof TotalResources
     */
    'cpuQuota'?: number | null;
    /**
     * Total memory quota of the org unit in MB
     * @type {number}
     * @memberof TotalResources
     */
    'memoryQuota'?: number | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const Units = {
    Mib: 'Mib',
    Mb: 'MB',
    Gb: 'GB'
} as const;

export type Units = typeof Units[keyof typeof Units];


/**
 * 
 * @export
 * @enum {string}
 */

export const Verbosity = {
    Meta: 'meta',
    Brief: 'brief',
    Verbose: 'verbose'
} as const;

export type Verbosity = typeof Verbosity[keyof typeof Verbosity];



/**
 * DepartmentsApi - axios parameter creator
 * @export
 */
export const DepartmentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * count departments
         * @summary Count departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDepartments: async (filterBy?: Array<string>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/departments/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create Department
         * @summary Create department
         * @param {DepartmentCreationRequest} departmentCreationRequest Department to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment: async (departmentCreationRequest: DepartmentCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentCreationRequest' is not null or undefined
            assertParamExists('createDepartment', 'departmentCreationRequest', departmentCreationRequest)
            const localVarPath = `/api/v1/org-unit/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete department by Id
         * @summary Delete department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartment', 'departmentId', departmentId)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get department by Id
         * @summary Get department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartment', 'departmentId', departmentId)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get department ancestors
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentAncestors: async (departmentId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartmentAncestors', 'departmentId', departmentId)
            const localVarPath = `/internal/org-unit/departments/{departmentId}/ancestors`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves department data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get department metrics data. [Experimental]
         * @param {string} departmentId The id of the department.
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMetrics: async (departmentId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartmentMetrics', 'departmentId', departmentId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getDepartmentMetrics', 'metricType', metricType)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getDepartmentMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getDepartmentMetrics', 'end', end)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}/metrics`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list departments
         * @summary Get departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {DepartmentFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {Verbosity} [verbosity] Departments verbosity. If it is set to \&quot;verbose\&quot;, status will be returned. If it is not defined or set to \&quot;brief\&quot; only unit specific data will be returned.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments: async (filterBy?: Array<string>, sortBy?: DepartmentFilterSortFields, verbosity?: Verbosity, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/departments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (verbosity !== undefined) {
                localVarQueryParameter['verbosity'] = verbosity;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get departments telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'CLUSTER_ID'>} [groupBy] department fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsTelemetry: async (telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolName?: string, departmentId?: string, groupBy?: Array<'CLUSTER_ID'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryType' is not null or undefined
            assertParamExists('getDepartmentsTelemetry', 'telemetryType', telemetryType)
            const localVarPath = `/api/v1/org-unit/departments/telemetry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy.join(COLLECTION_FORMATS.csv);
            }

            if (telemetryType !== undefined) {
                localVarQueryParameter['telemetryType'] = telemetryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get departments for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncDepartments: async (clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getSyncDepartments', 'clusterId', clusterId)
            const localVarPath = `/api/v1/org-unit/departments/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDepartmentResources: async (departmentId: string, resources: Array<Resources>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('patchDepartmentResources', 'departmentId', departmentId)
            // verify required parameter 'resources' is not null or undefined
            assertParamExists('patchDepartmentResources', 'resources', resources)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}/resources`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update department by Id
         * @summary Update department
         * @param {string} departmentId The id of the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest Department to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment: async (departmentId: string, departmentUpdateRequest: DepartmentUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartment', 'departmentId', departmentId)
            // verify required parameter 'departmentUpdateRequest' is not null or undefined
            assertParamExists('updateDepartment', 'departmentUpdateRequest', departmentUpdateRequest)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update department resources by Id
         * @summary Update department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentResources: async (departmentId: string, resources: Array<Resources>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartmentResources', 'departmentId', departmentId)
            // verify required parameter 'resources' is not null or undefined
            assertParamExists('updateDepartmentResources', 'resources', resources)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}/resources`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Department status from cluster
         * @param {string} departmentId The id of the department.
         * @param {DepartmentStatusUpdateRequest} departmentStatusUpdateRequest Department to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentStatus: async (departmentId: string, departmentStatusUpdateRequest: DepartmentStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartmentStatus', 'departmentId', departmentId)
            // verify required parameter 'departmentStatusUpdateRequest' is not null or undefined
            assertParamExists('updateDepartmentStatus', 'departmentStatusUpdateRequest', departmentStatusUpdateRequest)
            const localVarPath = `/api/v1/org-unit/departments/{departmentId}/status`
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsApi - functional programming interface
 * @export
 */
export const DepartmentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsApiAxiosParamCreator(configuration)
    return {
        /**
         * count departments
         * @summary Count departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countDepartments(filterBy?: Array<string>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countDepartments(filterBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create Department
         * @summary Create department
         * @param {DepartmentCreationRequest} departmentCreationRequest Department to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createDepartment(departmentCreationRequest: DepartmentCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartment(departmentCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete department by Id
         * @summary Delete department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteDepartment(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartment(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get department by Id
         * @summary Get department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartment(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartment(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get department ancestors
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentAncestors(departmentId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentAncestors(departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves department data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get department metrics data. [Experimental]
         * @param {string} departmentId The id of the department.
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentMetrics(departmentId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentMetrics(departmentId, metricType, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * list departments
         * @summary Get departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {DepartmentFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {Verbosity} [verbosity] Departments verbosity. If it is set to \&quot;verbose\&quot;, status will be returned. If it is not defined or set to \&quot;brief\&quot; only unit specific data will be returned.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartments(filterBy?: Array<string>, sortBy?: DepartmentFilterSortFields, verbosity?: Verbosity, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDepartments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartments(filterBy, sortBy, verbosity, sortOrder, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get departments telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'CLUSTER_ID'>} [groupBy] department fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDepartmentsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolName?: string, departmentId?: string, groupBy?: Array<'CLUSTER_ID'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentsTelemetry(telemetryType, clusterId, nodepoolName, departmentId, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary get departments for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSyncDepartments(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSyncDepartments200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSyncDepartments(clusterId, updatedAfter, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchDepartmentResources(departmentId: string, resources: Array<Resources>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resources>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchDepartmentResources(departmentId, resources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update department by Id
         * @summary Update department
         * @param {string} departmentId The id of the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest Department to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartment(departmentId: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Department>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartment(departmentId, departmentUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update department resources by Id
         * @summary Update department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartmentResources(departmentId: string, resources: Array<Resources>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resources>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentResources(departmentId, resources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Department status from cluster
         * @param {string} departmentId The id of the department.
         * @param {DepartmentStatusUpdateRequest} departmentStatusUpdateRequest Department to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateDepartmentStatus(departmentId: string, departmentStatusUpdateRequest: DepartmentStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentStatus(departmentId, departmentStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsApi - factory interface
 * @export
 */
export const DepartmentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsApiFp(configuration)
    return {
        /**
         * count departments
         * @summary Count departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countDepartments(filterBy?: Array<string>, options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countDepartments(filterBy, options).then((request) => request(axios, basePath));
        },
        /**
         * Create Department
         * @summary Create department
         * @param {DepartmentCreationRequest} departmentCreationRequest Department to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDepartment(departmentCreationRequest: DepartmentCreationRequest, options?: any): AxiosPromise<Department> {
            return localVarFp.createDepartment(departmentCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete department by Id
         * @summary Delete department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDepartment(departmentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get department by Id
         * @summary Get department
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartment(departmentId: string, options?: any): AxiosPromise<Department> {
            return localVarFp.getDepartment(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get department ancestors
         * @param {string} departmentId The id of the department.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentAncestors(departmentId: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getDepartmentAncestors(departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves department data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get department metrics data. [Experimental]
         * @param {string} departmentId The id of the department.
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentMetrics(departmentId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getDepartmentMetrics(departmentId, metricType, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * list departments
         * @summary Get departments
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {DepartmentFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {Verbosity} [verbosity] Departments verbosity. If it is set to \&quot;verbose\&quot;, status will be returned. If it is not defined or set to \&quot;brief\&quot; only unit specific data will be returned.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartments(filterBy?: Array<string>, sortBy?: DepartmentFilterSortFields, verbosity?: Verbosity, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: any): AxiosPromise<GetDepartments200Response> {
            return localVarFp.getDepartments(filterBy, sortBy, verbosity, sortOrder, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get departments telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'CLUSTER_ID'>} [groupBy] department fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDepartmentsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolName?: string, departmentId?: string, groupBy?: Array<'CLUSTER_ID'>, options?: any): AxiosPromise<TelemetryResponse> {
            return localVarFp.getDepartmentsTelemetry(telemetryType, clusterId, nodepoolName, departmentId, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary get departments for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncDepartments(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetSyncDepartments200Response> {
            return localVarFp.getSyncDepartments(clusterId, updatedAfter, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchDepartmentResources(departmentId: string, resources: Array<Resources>, options?: any): AxiosPromise<Array<Resources>> {
            return localVarFp.patchDepartmentResources(departmentId, resources, options).then((request) => request(axios, basePath));
        },
        /**
         * Update department by Id
         * @summary Update department
         * @param {string} departmentId The id of the department.
         * @param {DepartmentUpdateRequest} departmentUpdateRequest Department to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartment(departmentId: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: any): AxiosPromise<Department> {
            return localVarFp.updateDepartment(departmentId, departmentUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update department resources by Id
         * @summary Update department resources
         * @param {string} departmentId The id of the department.
         * @param {Array<Resources>} resources Department resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentResources(departmentId: string, resources: Array<Resources>, options?: any): AxiosPromise<Array<Resources>> {
            return localVarFp.updateDepartmentResources(departmentId, resources, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Department status from cluster
         * @param {string} departmentId The id of the department.
         * @param {DepartmentStatusUpdateRequest} departmentStatusUpdateRequest Department to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDepartmentStatus(departmentId: string, departmentStatusUpdateRequest: DepartmentStatusUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateDepartmentStatus(departmentId, departmentStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsApi - object-oriented interface
 * @export
 * @class DepartmentsApi
 * @extends {BaseAPI}
 */
export class DepartmentsApi extends BaseAPI {
    /**
     * count departments
     * @summary Count departments
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public countDepartments(filterBy?: Array<string>, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).countDepartments(filterBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create Department
     * @summary Create department
     * @param {DepartmentCreationRequest} departmentCreationRequest Department to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public createDepartment(departmentCreationRequest: DepartmentCreationRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).createDepartment(departmentCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete department by Id
     * @summary Delete department
     * @param {string} departmentId The id of the department.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public deleteDepartment(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).deleteDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get department by Id
     * @summary Get department
     * @param {string} departmentId The id of the department.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartment(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartment(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get department ancestors
     * @param {string} departmentId The id of the department.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentAncestors(departmentId: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentAncestors(departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves department data metrics from the metrics database. Use in reporting and analysis tools.
     * @summary Get department metrics data. [Experimental]
     * @param {string} departmentId The id of the department.
     * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter using the nodepool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentMetrics(departmentId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentMetrics(departmentId, metricType, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * list departments
     * @summary Get departments
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {DepartmentFilterSortFields} [sortBy] Sort results by a parameters.
     * @param {Verbosity} [verbosity] Departments verbosity. If it is set to \&quot;verbose\&quot;, status will be returned. If it is not defined or set to \&quot;brief\&quot; only unit specific data will be returned.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartments(filterBy?: Array<string>, sortBy?: DepartmentFilterSortFields, verbosity?: Verbosity, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartments(filterBy, sortBy, verbosity, sortOrder, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get departments telemetry
     * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodepoolName] Filter using the nodepool.
     * @param {string} [departmentId] Filter using the department id.
     * @param {Array<'CLUSTER_ID'>} [groupBy] department fields to group the data by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getDepartmentsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolName?: string, departmentId?: string, groupBy?: Array<'CLUSTER_ID'>, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getDepartmentsTelemetry(telemetryType, clusterId, nodepoolName, departmentId, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary get departments for sync
     * @param {string} clusterId The id of the cluster
     * @param {string} [updatedAfter] 
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public getSyncDepartments(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).getSyncDepartments(clusterId, updatedAfter, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
     * @summary Patch department resources
     * @param {string} departmentId The id of the department.
     * @param {Array<Resources>} resources Department resources to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public patchDepartmentResources(departmentId: string, resources: Array<Resources>, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).patchDepartmentResources(departmentId, resources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update department by Id
     * @summary Update department
     * @param {string} departmentId The id of the department.
     * @param {DepartmentUpdateRequest} departmentUpdateRequest Department to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartment(departmentId: string, departmentUpdateRequest: DepartmentUpdateRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartment(departmentId, departmentUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update department resources by Id
     * @summary Update department resources
     * @param {string} departmentId The id of the department.
     * @param {Array<Resources>} resources Department resources to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartmentResources(departmentId: string, resources: Array<Resources>, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartmentResources(departmentId, resources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Department status from cluster
     * @param {string} departmentId The id of the department.
     * @param {DepartmentStatusUpdateRequest} departmentStatusUpdateRequest Department to update by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DepartmentsApi
     */
    public updateDepartmentStatus(departmentId: string, departmentStatusUpdateRequest: DepartmentStatusUpdateRequest, options?: AxiosRequestConfig) {
        return DepartmentsApiFp(this.configuration).updateDepartmentStatus(departmentId, departmentStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DepartmentsV1Api - axios parameter creator
 * @export
 */
export const DepartmentsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {DepartmentV1CreationRequest} departmentV1CreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartmentV1: async (clusterId: string, departmentV1CreationRequest: DepartmentV1CreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createDepartmentV1', 'clusterId', clusterId)
            // verify required parameter 'departmentV1CreationRequest' is not null or undefined
            assertParamExists('createDepartmentV1', 'departmentV1CreationRequest', departmentV1CreationRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentV1CreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartmentV1: async (clusterId: string, departmentId: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteDepartmentV1', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('deleteDepartmentV1', 'departmentId', departmentId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get metrics for a specific department in the cluster.  Use a time range to return historical data (optional). If you use a `start` date, an `end` date is required. 
         * @summary Get metrics for a specific department.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentMetricsV1: async (clusterUuid: string, departmentId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getDepartmentMetricsV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartmentMetricsV1', 'departmentId', departmentId)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/departments/{departmentId}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"departmentId"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentV1: async (clusterId: string, departmentId: number, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getDepartmentV1', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('getDepartmentV1', 'departmentId', departmentId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics for all departments.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentsMetricsV1: async (clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getDepartmentsMetricsV1', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/departments/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentsV1: async (clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getDepartmentsV1', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }

            if (memoryUnitMb !== undefined) {
                localVarQueryParameter['memoryUnitMb'] = memoryUnitMb;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentV1AccessControl} departmentV1AccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setDepartmentAdminsV1: async (clusterId: string, departmentId: number, departmentV1AccessControl: DepartmentV1AccessControl, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('setDepartmentAdminsV1', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('setDepartmentAdminsV1', 'departmentId', departmentId)
            // verify required parameter 'departmentV1AccessControl' is not null or undefined
            assertParamExists('setDepartmentAdminsV1', 'departmentV1AccessControl', departmentV1AccessControl)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}/access-control`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(departmentV1AccessControl, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartmentV1: async (clusterId: string, departmentId: number, body: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateDepartmentV1', 'clusterId', clusterId)
            // verify required parameter 'departmentId' is not null or undefined
            assertParamExists('updateDepartmentV1', 'departmentId', departmentId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateDepartmentV1', 'body', body)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/departments/{department-id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"department-id"}}`, encodeURIComponent(String(departmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DepartmentsV1Api - functional programming interface
 * @export
 */
export const DepartmentsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DepartmentsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {DepartmentV1CreationRequest} departmentV1CreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createDepartmentV1(clusterId: string, departmentV1CreationRequest: DepartmentV1CreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentForGetV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createDepartmentV1(clusterId, departmentV1CreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteDepartmentV1(clusterId: string, departmentId: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentForGetV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteDepartmentV1(clusterId, departmentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get metrics for a specific department in the cluster.  Use a time range to return historical data (optional). If you use a `start` date, an `end` date is required. 
         * @summary Get metrics for a specific department.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartmentMetricsV1(clusterUuid: string, departmentId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentMetricsV1Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentMetricsV1(clusterUuid, departmentId, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartmentV1(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentForGetV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentV1(clusterId, departmentId, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metrics for all departments.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartmentsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentsMetricsV1Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getDepartmentsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GetDepartmentsV1200ResponseInner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDepartmentsV1(clusterId, excludePermissions, memoryUnitMb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentV1AccessControl} departmentV1AccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async setDepartmentAdminsV1(clusterId: string, departmentId: number, departmentV1AccessControl: DepartmentV1AccessControl, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentV1AccessControl>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setDepartmentAdminsV1(clusterId, departmentId, departmentV1AccessControl, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateDepartmentV1(clusterId: string, departmentId: number, body: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DepartmentForGetV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateDepartmentV1(clusterId, departmentId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DepartmentsV1Api - factory interface
 * @export
 */
export const DepartmentsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DepartmentsV1ApiFp(configuration)
    return {
        /**
         * Creates a new department in the cluster.
         * @summary Create a new department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {DepartmentV1CreationRequest} departmentV1CreationRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createDepartmentV1(clusterId: string, departmentV1CreationRequest: DepartmentV1CreationRequest, options?: any): AxiosPromise<DepartmentForGetV1> {
            return localVarFp.createDepartmentV1(clusterId, departmentV1CreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a department from a specific cluster.
         * @summary Delete a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteDepartmentV1(clusterId: string, departmentId: number, options?: any): AxiosPromise<DepartmentForGetV1> {
            return localVarFp.deleteDepartmentV1(clusterId, departmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get metrics for a specific department in the cluster.  Use a time range to return historical data (optional). If you use a `start` date, an `end` date is required. 
         * @summary Get metrics for a specific department.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} departmentId The id of the department.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentMetricsV1(clusterUuid: string, departmentId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<DepartmentMetricsV1Response> {
            return localVarFp.getDepartmentMetricsV1(clusterUuid, departmentId, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a specific department. Requires  the`view` permission for the department.
         * @summary Get a specific department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentV1(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: any): AxiosPromise<DepartmentForGetV1> {
            return localVarFp.getDepartmentV1(clusterId, departmentId, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics for all departments.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<DepartmentsMetricsV1Response> {
            return localVarFp.getDepartmentsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * List all the departments managed by the tenant on a specific cluster.
         * @summary List all departments.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getDepartmentsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: any): AxiosPromise<Array<GetDepartmentsV1200ResponseInner>> {
            return localVarFp.getDepartmentsV1(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(axios, basePath));
        },
        /**
         * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
         * @summary Set the department admins.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {DepartmentV1AccessControl} departmentV1AccessControl 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        setDepartmentAdminsV1(clusterId: string, departmentId: number, departmentV1AccessControl: DepartmentV1AccessControl, options?: any): AxiosPromise<DepartmentV1AccessControl> {
            return localVarFp.setDepartmentAdminsV1(clusterId, departmentId, departmentV1AccessControl, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
         * @summary Update a department.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} departmentId The unique id identifying the department.
         * @param {object} body 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateDepartmentV1(clusterId: string, departmentId: number, body: object, options?: any): AxiosPromise<DepartmentForGetV1> {
            return localVarFp.updateDepartmentV1(clusterId, departmentId, body, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DepartmentsV1Api - object-oriented interface
 * @export
 * @class DepartmentsV1Api
 * @extends {BaseAPI}
 */
export class DepartmentsV1Api extends BaseAPI {
    /**
     * Creates a new department in the cluster.
     * @summary Create a new department.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {DepartmentV1CreationRequest} departmentV1CreationRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public createDepartmentV1(clusterId: string, departmentV1CreationRequest: DepartmentV1CreationRequest, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).createDepartmentV1(clusterId, departmentV1CreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a department from a specific cluster.
     * @summary Delete a department.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public deleteDepartmentV1(clusterId: string, departmentId: number, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).deleteDepartmentV1(clusterId, departmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get metrics for a specific department in the cluster.  Use a time range to return historical data (optional). If you use a `start` date, an `end` date is required. 
     * @summary Get metrics for a specific department.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} departmentId The id of the department.
     * @param {string} [start] Start of time range to fetch data from in UTC format.
     * @param {string} [end] End of time range to fetch data from in UTC format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter by unique nodepool name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentMetricsV1(clusterUuid: string, departmentId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentMetricsV1(clusterUuid, departmentId, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a specific department. Requires  the`view` permission for the department.
     * @summary Get a specific department.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentV1(clusterId: string, departmentId: number, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentV1(clusterId, departmentId, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics for all departments.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [start] Start of time range to fetch data from in UTC format.
     * @param {string} [end] End of time range to fetch data from in UTC format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter by unique nodepool name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List all the departments managed by the tenant on a specific cluster.
     * @summary List all departments.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public getDepartmentsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).getDepartmentsV1(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deprecated. Instead, use the accessrules API to add the department-admin permissions to a specific subject.
     * @summary Set the department admins.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {DepartmentV1AccessControl} departmentV1AccessControl 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public setDepartmentAdminsV1(clusterId: string, departmentId: number, departmentV1AccessControl: DepartmentV1AccessControl, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).setDepartmentAdminsV1(clusterId, departmentId, departmentV1AccessControl, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a department\'s details in the cluster. \\n For example, node pools and other details.
     * @summary Update a department.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} departmentId The unique id identifying the department.
     * @param {object} body 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof DepartmentsV1Api
     */
    public updateDepartmentV1(clusterId: string, departmentId: number, body: object, options?: AxiosRequestConfig) {
        return DepartmentsV1ApiFp(this.configuration).updateDepartmentV1(clusterId, departmentId, body, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary health check.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HealthApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary health check.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async health(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.health(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HealthApiFp(configuration)
    return {
        /**
         * 
         * @summary health check.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        health(options?: any): AxiosPromise<void> {
            return localVarFp.health(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     * 
     * @summary health check.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    public health(options?: AxiosRequestConfig) {
        return HealthApiFp(this.configuration).health(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeTypesApi - axios parameter creator
 * @export
 */
export const NodeTypesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Create node types
         * @summary Create node type
         * @param {NodeTypeCreateRequest} nodeTypeCreateRequest NodeType to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodeType: async (nodeTypeCreateRequest: NodeTypeCreateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeTypeCreateRequest' is not null or undefined
            assertParamExists('createNodeType', 'nodeTypeCreateRequest', nodeTypeCreateRequest)
            const localVarPath = `/api/v1/org-unit/node-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTypeCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete node types
         * @summary Delete node type
         * @param {string} nodeTypeId The node-type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodeType: async (nodeTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeTypeId' is not null or undefined
            assertParamExists('deleteNodeType', 'nodeTypeId', nodeTypeId)
            const localVarPath = `/api/v1/org-unit/node-type/{nodeTypeId}`
                .replace(`{${"nodeTypeId"}}`, encodeURIComponent(String(nodeTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get node types
         * @summary Get node types
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTypes: async (clusterId?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/node-type`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update node type
         * @summary Update node type
         * @param {string} nodeTypeId The node-type id
         * @param {NodeTypeUpdateRequest} nodeTypeUpdateRequest NodeType to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodeType: async (nodeTypeId: string, nodeTypeUpdateRequest: NodeTypeUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'nodeTypeId' is not null or undefined
            assertParamExists('updateNodeType', 'nodeTypeId', nodeTypeId)
            // verify required parameter 'nodeTypeUpdateRequest' is not null or undefined
            assertParamExists('updateNodeType', 'nodeTypeUpdateRequest', nodeTypeUpdateRequest)
            const localVarPath = `/api/v1/org-unit/node-type/{nodeTypeId}`
                .replace(`{${"nodeTypeId"}}`, encodeURIComponent(String(nodeTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTypeUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeTypesApi - functional programming interface
 * @export
 */
export const NodeTypesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeTypesApiAxiosParamCreator(configuration)
    return {
        /**
         * Create node types
         * @summary Create node type
         * @param {NodeTypeCreateRequest} nodeTypeCreateRequest NodeType to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNodeType(nodeTypeCreateRequest: NodeTypeCreateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNodeType(nodeTypeCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete node types
         * @summary Delete node type
         * @param {string} nodeTypeId The node-type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteNodeType(nodeTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNodeType(nodeTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get node types
         * @summary Get node types
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNodeTypes(clusterId?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetNodeTypes200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTypes(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update node type
         * @summary Update node type
         * @param {string} nodeTypeId The node-type id
         * @param {NodeTypeUpdateRequest} nodeTypeUpdateRequest NodeType to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateNodeType(nodeTypeId: string, nodeTypeUpdateRequest: NodeTypeUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeType>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodeType(nodeTypeId, nodeTypeUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeTypesApi - factory interface
 * @export
 */
export const NodeTypesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeTypesApiFp(configuration)
    return {
        /**
         * Create node types
         * @summary Create node type
         * @param {NodeTypeCreateRequest} nodeTypeCreateRequest NodeType to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNodeType(nodeTypeCreateRequest: NodeTypeCreateRequest, options?: any): AxiosPromise<NodeType> {
            return localVarFp.createNodeType(nodeTypeCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete node types
         * @summary Delete node type
         * @param {string} nodeTypeId The node-type id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNodeType(nodeTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNodeType(nodeTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * Get node types
         * @summary Get node types
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNodeTypes(clusterId?: string, options?: any): AxiosPromise<GetNodeTypes200Response> {
            return localVarFp.getNodeTypes(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Update node type
         * @summary Update node type
         * @param {string} nodeTypeId The node-type id
         * @param {NodeTypeUpdateRequest} nodeTypeUpdateRequest NodeType to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNodeType(nodeTypeId: string, nodeTypeUpdateRequest: NodeTypeUpdateRequest, options?: any): AxiosPromise<NodeType> {
            return localVarFp.updateNodeType(nodeTypeId, nodeTypeUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeTypesApi - object-oriented interface
 * @export
 * @class NodeTypesApi
 * @extends {BaseAPI}
 */
export class NodeTypesApi extends BaseAPI {
    /**
     * Create node types
     * @summary Create node type
     * @param {NodeTypeCreateRequest} nodeTypeCreateRequest NodeType to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTypesApi
     */
    public createNodeType(nodeTypeCreateRequest: NodeTypeCreateRequest, options?: AxiosRequestConfig) {
        return NodeTypesApiFp(this.configuration).createNodeType(nodeTypeCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete node types
     * @summary Delete node type
     * @param {string} nodeTypeId The node-type id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTypesApi
     */
    public deleteNodeType(nodeTypeId: string, options?: AxiosRequestConfig) {
        return NodeTypesApiFp(this.configuration).deleteNodeType(nodeTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get node types
     * @summary Get node types
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTypesApi
     */
    public getNodeTypes(clusterId?: string, options?: AxiosRequestConfig) {
        return NodeTypesApiFp(this.configuration).getNodeTypes(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update node type
     * @summary Update node type
     * @param {string} nodeTypeId The node-type id
     * @param {NodeTypeUpdateRequest} nodeTypeUpdateRequest NodeType to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NodeTypesApi
     */
    public updateNodeType(nodeTypeId: string, nodeTypeUpdateRequest: NodeTypeUpdateRequest, options?: AxiosRequestConfig) {
        return NodeTypesApiFp(this.configuration).updateNodeType(nodeTypeId, nodeTypeUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NodeTypesV1Api - axios parameter creator
 * @export
 */
export const NodeTypesV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create a new NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNodeTypeV1: async (clusterId: string, nodeTypeV1: NodeTypeV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createNodeTypeV1', 'clusterId', clusterId)
            // verify required parameter 'nodeTypeV1' is not null or undefined
            assertParamExists('createNodeTypeV1', 'nodeTypeV1', nodeTypeV1)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/nodetypes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTypeV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteNodeTypeV1: async (clusterId: string, nodeTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteNodeTypeV1', 'clusterId', clusterId)
            // verify required parameter 'nodeTypeId' is not null or undefined
            assertParamExists('deleteNodeTypeV1', 'nodeTypeId', nodeTypeId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/nodetypes/{nodeTypeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeTypeId"}}`, encodeURIComponent(String(nodeTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getNodeTypeV1: async (clusterId: string, nodeTypeId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getNodeTypeV1', 'clusterId', clusterId)
            // verify required parameter 'nodeTypeId' is not null or undefined
            assertParamExists('getNodeTypeV1', 'nodeTypeId', nodeTypeId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/nodetypes/{nodeTypeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeTypeId"}}`, encodeURIComponent(String(nodeTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all cluster\'s NodeTypes.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getNodeTypesV1: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getNodeTypesV1', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/nodetypes`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateNodeTypeV1: async (clusterId: string, nodeTypeId: string, nodeTypeV1: NodeTypeV1, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateNodeTypeV1', 'clusterId', clusterId)
            // verify required parameter 'nodeTypeId' is not null or undefined
            assertParamExists('updateNodeTypeV1', 'nodeTypeId', nodeTypeId)
            // verify required parameter 'nodeTypeV1' is not null or undefined
            assertParamExists('updateNodeTypeV1', 'nodeTypeV1', nodeTypeV1)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/nodetypes/{nodeTypeId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"nodeTypeId"}}`, encodeURIComponent(String(nodeTypeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodeTypeV1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NodeTypesV1Api - functional programming interface
 * @export
 */
export const NodeTypesV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NodeTypesV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create a new NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createNodeTypeV1(clusterId: string, nodeTypeV1: NodeTypeV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeTypeV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNodeTypeV1(clusterId, nodeTypeV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteNodeTypeV1(clusterId: string, nodeTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteNodeTypeV1(clusterId, nodeTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getNodeTypeV1(clusterId: string, nodeTypeId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeTypeV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTypeV1(clusterId, nodeTypeId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get all cluster\'s NodeTypes.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getNodeTypesV1(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NodeTypeV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNodeTypesV1(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateNodeTypeV1(clusterId: string, nodeTypeId: string, nodeTypeV1: NodeTypeV1, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<NodeTypeV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateNodeTypeV1(clusterId, nodeTypeId, nodeTypeV1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NodeTypesV1Api - factory interface
 * @export
 */
export const NodeTypesV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NodeTypesV1ApiFp(configuration)
    return {
        /**
         * 
         * @summary Create a new NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createNodeTypeV1(clusterId: string, nodeTypeV1: NodeTypeV1, options?: any): AxiosPromise<NodeTypeV1> {
            return localVarFp.createNodeTypeV1(clusterId, nodeTypeV1, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteNodeTypeV1(clusterId: string, nodeTypeId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteNodeTypeV1(clusterId, nodeTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getNodeTypeV1(clusterId: string, nodeTypeId: string, options?: any): AxiosPromise<NodeTypeV1> {
            return localVarFp.getNodeTypeV1(clusterId, nodeTypeId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get all cluster\'s NodeTypes.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getNodeTypesV1(clusterId: string, options?: any): AxiosPromise<Array<NodeTypeV1>> {
            return localVarFp.getNodeTypesV1(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update a NodeType.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} nodeTypeId 
         * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateNodeTypeV1(clusterId: string, nodeTypeId: string, nodeTypeV1: NodeTypeV1, options?: any): AxiosPromise<NodeTypeV1> {
            return localVarFp.updateNodeTypeV1(clusterId, nodeTypeId, nodeTypeV1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NodeTypesV1Api - object-oriented interface
 * @export
 * @class NodeTypesV1Api
 * @extends {BaseAPI}
 */
export class NodeTypesV1Api extends BaseAPI {
    /**
     * 
     * @summary Create a new NodeType.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NodeTypesV1Api
     */
    public createNodeTypeV1(clusterId: string, nodeTypeV1: NodeTypeV1, options?: AxiosRequestConfig) {
        return NodeTypesV1ApiFp(this.configuration).createNodeTypeV1(clusterId, nodeTypeV1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete a NodeType.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeTypeId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NodeTypesV1Api
     */
    public deleteNodeTypeV1(clusterId: string, nodeTypeId: string, options?: AxiosRequestConfig) {
        return NodeTypesV1ApiFp(this.configuration).deleteNodeTypeV1(clusterId, nodeTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get a NodeType.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeTypeId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NodeTypesV1Api
     */
    public getNodeTypeV1(clusterId: string, nodeTypeId: string, options?: AxiosRequestConfig) {
        return NodeTypesV1ApiFp(this.configuration).getNodeTypeV1(clusterId, nodeTypeId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get all cluster\'s NodeTypes.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NodeTypesV1Api
     */
    public getNodeTypesV1(clusterId: string, options?: AxiosRequestConfig) {
        return NodeTypesV1ApiFp(this.configuration).getNodeTypesV1(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update a NodeType.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} nodeTypeId 
     * @param {NodeTypeV1} nodeTypeV1 The NodeType to create.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof NodeTypesV1Api
     */
    public updateNodeTypeV1(clusterId: string, nodeTypeId: string, nodeTypeV1: NodeTypeV1, options?: AxiosRequestConfig) {
        return NodeTypesV1ApiFp(this.configuration).updateNodeTypeV1(clusterId, nodeTypeId, nodeTypeV1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * NotificationsApi - axios parameter creator
 * @export
 */
export const NotificationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cluster deleted
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterDeleted: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('clusterDeleted', 'clusterId', clusterId)
            const localVarPath = `/internal/org-unit/clusters/{clusterId}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Node pool updated
         * @param {boolean} _default is created/deleted nodepool is default nodepool
         * @param {NodePool} nodePool Node pool
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodePoolUpdated: async (_default: boolean, nodePool: NodePool, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter '_default' is not null or undefined
            assertParamExists('nodePoolUpdated', '_default', _default)
            // verify required parameter 'nodePool' is not null or undefined
            assertParamExists('nodePoolUpdated', 'nodePool', nodePool)
            const localVarPath = `/internal/org-unit/node-pools`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (_default !== undefined) {
                localVarQueryParameter['default'] = _default;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(nodePool, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NotificationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Cluster deleted
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async clusterDeleted(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.clusterDeleted(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Node pool updated
         * @param {boolean} _default is created/deleted nodepool is default nodepool
         * @param {NodePool} nodePool Node pool
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async nodePoolUpdated(_default: boolean, nodePool: NodePool, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.nodePoolUpdated(_default, nodePool, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NotificationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Cluster deleted
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clusterDeleted(clusterId: string, options?: any): AxiosPromise<void> {
            return localVarFp.clusterDeleted(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Node pool updated
         * @param {boolean} _default is created/deleted nodepool is default nodepool
         * @param {NodePool} nodePool Node pool
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        nodePoolUpdated(_default: boolean, nodePool: NodePool, options?: any): AxiosPromise<void> {
            return localVarFp.nodePoolUpdated(_default, nodePool, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * 
     * @summary Cluster deleted
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public clusterDeleted(clusterId: string, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).clusterDeleted(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Node pool updated
     * @param {boolean} _default is created/deleted nodepool is default nodepool
     * @param {NodePool} nodePool Node pool
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public nodePoolUpdated(_default: boolean, nodePool: NodePool, options?: AxiosRequestConfig) {
        return NotificationsApiFp(this.configuration).nodePoolUpdated(_default, nodePool, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrgUnitApi - axios parameter creator
 * @export
 */
export const OrgUnitApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get org tree
         * @param {boolean} [showAll] flag to show all org unit without filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgTree: async (showAll?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (showAll !== undefined) {
                localVarQueryParameter['showAll'] = showAll;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get the names of requested org units.
         * @summary Get the names of requested org units.
         * @param {'department' | 'project'} orgUnitType The org unit type.
         * @param {string} orgUnitIds The org unit unique identifiers, seperated by \&#39;,\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUnitsNames: async (orgUnitType: 'department' | 'project', orgUnitIds: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'orgUnitType' is not null or undefined
            assertParamExists('getOrgUnitsNames', 'orgUnitType', orgUnitType)
            // verify required parameter 'orgUnitIds' is not null or undefined
            assertParamExists('getOrgUnitsNames', 'orgUnitIds', orgUnitIds)
            const localVarPath = `/internal/org-unit/names`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (orgUnitType !== undefined) {
                localVarQueryParameter['orgUnitType'] = orgUnitType;
            }

            if (orgUnitIds !== undefined) {
                localVarQueryParameter['orgUnitIds'] = orgUnitIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgUnitApi - functional programming interface
 * @export
 */
export const OrgUnitApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrgUnitApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get org tree
         * @param {boolean} [showAll] flag to show all org unit without filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgTree(showAll?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetOrgTree200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgTree(showAll, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get the names of requested org units.
         * @summary Get the names of requested org units.
         * @param {'department' | 'project'} orgUnitType The org unit type.
         * @param {string} orgUnitIds The org unit unique identifiers, seperated by \&#39;,\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getOrgUnitsNames(orgUnitType: 'department' | 'project', orgUnitIds: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrgUnit>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getOrgUnitsNames(orgUnitType, orgUnitIds, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrgUnitApi - factory interface
 * @export
 */
export const OrgUnitApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrgUnitApiFp(configuration)
    return {
        /**
         * 
         * @summary Get org tree
         * @param {boolean} [showAll] flag to show all org unit without filtering
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgTree(showAll?: boolean, options?: any): AxiosPromise<GetOrgTree200Response> {
            return localVarFp.getOrgTree(showAll, options).then((request) => request(axios, basePath));
        },
        /**
         * Get the names of requested org units.
         * @summary Get the names of requested org units.
         * @param {'department' | 'project'} orgUnitType The org unit type.
         * @param {string} orgUnitIds The org unit unique identifiers, seperated by \&#39;,\&#39;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUnitsNames(orgUnitType: 'department' | 'project', orgUnitIds: string, options?: any): AxiosPromise<Array<OrgUnit>> {
            return localVarFp.getOrgUnitsNames(orgUnitType, orgUnitIds, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrgUnitApi - object-oriented interface
 * @export
 * @class OrgUnitApi
 * @extends {BaseAPI}
 */
export class OrgUnitApi extends BaseAPI {
    /**
     * 
     * @summary Get org tree
     * @param {boolean} [showAll] flag to show all org unit without filtering
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgUnitApi
     */
    public getOrgTree(showAll?: boolean, options?: AxiosRequestConfig) {
        return OrgUnitApiFp(this.configuration).getOrgTree(showAll, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get the names of requested org units.
     * @summary Get the names of requested org units.
     * @param {'department' | 'project'} orgUnitType The org unit type.
     * @param {string} orgUnitIds The org unit unique identifiers, seperated by \&#39;,\&#39;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgUnitApi
     */
    public getOrgUnitsNames(orgUnitType: 'department' | 'project', orgUnitIds: string, options?: AxiosRequestConfig) {
        return OrgUnitApiFp(this.configuration).getOrgUnitsNames(orgUnitType, orgUnitIds, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsApi - axios parameter creator
 * @export
 */
export const ProjectsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * count projects
         * @summary Count projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countProjects: async (filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/projects/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create a project
         * @summary Create project
         * @param {ProjectCreationRequest} projectCreationRequest Project to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: async (projectCreationRequest: ProjectCreationRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectCreationRequest' is not null or undefined
            assertParamExists('createProject', 'projectCreationRequest', projectCreationRequest)
            const localVarPath = `/api/v1/org-unit/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectCreationRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a project
         * @summary Delete project
         * @param {string} projectId The project id
         * @param {DeletionTypes} [deleteType] Define project deletion type.
         * @param {boolean} [force] Sets the project to be forced deleted (could leave orphan objects in the system)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: async (projectId: string, deleteType?: DeletionTypes, force?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProject', 'projectId', projectId)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (deleteType !== undefined) {
                localVarQueryParameter['deleteType'] = deleteType;
            }

            if (force !== undefined) {
                localVarQueryParameter['force'] = force;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get a project by id
         * @summary Get project
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProject', 'projectId', projectId)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project ancestors
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAncestors: async (projectId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAncestors', 'projectId', projectId)
            const localVarPath = `/internal/org-unit/projects/{projectId}/ancestors`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves project data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get project metrics data. [Experimental]
         * @param {string} projectId The project id
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMetrics: async (projectId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectMetrics', 'projectId', projectId)
            // verify required parameter 'metricType' is not null or undefined
            assertParamExists('getProjectMetrics', 'metricType', metricType)
            // verify required parameter 'start' is not null or undefined
            assertParamExists('getProjectMetrics', 'start', start)
            // verify required parameter 'end' is not null or undefined
            assertParamExists('getProjectMetrics', 'end', end)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}/metrics`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (metricType) {
                localVarQueryParameter['metricType'] = metricType.join(COLLECTION_FORMATS.csv);
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List projects
         * @summary Get projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ProjectFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: async (filterBy?: Array<string>, sortBy?: ProjectFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List projects metadata
         * @summary Get projects metadata
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {'name' | 'clusterId' | 'phase' | 'createdAt'} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsMetadata: async (filterBy?: Array<string>, sortBy?: 'name' | 'clusterId' | 'phase' | 'createdAt', sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/projects/metadata`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Get projects telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolId] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'ClusterId' | 'NodepoolId' | 'ParentId'>} [groupBy] project fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsTelemetry: async (telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolId?: string, departmentId?: string, groupBy?: Array<'ClusterId' | 'NodepoolId' | 'ParentId'>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'telemetryType' is not null or undefined
            assertParamExists('getProjectsTelemetry', 'telemetryType', telemetryType)
            const localVarPath = `/api/v1/org-unit/projects/telemetry`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (nodepoolId !== undefined) {
                localVarQueryParameter['nodepoolId'] = nodepoolId;
            }

            if (departmentId !== undefined) {
                localVarQueryParameter['departmentId'] = departmentId;
            }

            if (groupBy) {
                localVarQueryParameter['groupBy'] = groupBy.join(COLLECTION_FORMATS.csv);
            }

            if (telemetryType !== undefined) {
                localVarQueryParameter['telemetryType'] = telemetryType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get projects for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProjects: async (clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getSyncProjects', 'clusterId', clusterId)
            const localVarPath = `/api/v1/org-unit/projects/sync`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clusterId !== undefined) {
                localVarQueryParameter['clusterId'] = clusterId;
            }

            if (updatedAfter !== undefined) {
                localVarQueryParameter['updatedAfter'] = (updatedAfter as any instanceof Date) ?
                    (updatedAfter as any).toISOString() :
                    updatedAfter;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectResources: async (projectId: string, resources: Array<Resources>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProjectResources', 'projectId', projectId)
            // verify required parameter 'resources' is not null or undefined
            assertParamExists('patchProjectResources', 'resources', resources)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}/resources`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Update project
         * @param {string} projectId The project id
         * @param {ProjectUpdateRequest} projectUpdateRequest Project to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject: async (projectId: string, projectUpdateRequest: ProjectUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProject', 'projectId', projectId)
            // verify required parameter 'projectUpdateRequest' is not null or undefined
            assertParamExists('updateProject', 'projectUpdateRequest', projectUpdateRequest)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Update projects resources
         * @summary Update project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectResources: async (projectId: string, resources: Array<Resources>, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('updateProjectResources', 'projectId', projectId)
            // verify required parameter 'resources' is not null or undefined
            assertParamExists('updateProjectResources', 'resources', resources)
            const localVarPath = `/api/v1/org-unit/projects/{projectId}/resources`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resources, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update project status from cluster
         * @param {ProjectStatusUpdateRequest} projectStatusUpdateRequest Project to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectStatus: async (projectStatusUpdateRequest: ProjectStatusUpdateRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectStatusUpdateRequest' is not null or undefined
            assertParamExists('updateProjectStatus', 'projectStatusUpdateRequest', projectStatusUpdateRequest)
            const localVarPath = `/api/v1/org-unit/projects/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectStatusUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsApi - functional programming interface
 * @export
 */
export const ProjectsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsApiAxiosParamCreator(configuration)
    return {
        /**
         * count projects
         * @summary Count projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countProjects(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countProjects(filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create a project
         * @summary Create project
         * @param {ProjectCreationRequest} projectCreationRequest Project to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProject(projectCreationRequest: ProjectCreationRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProject(projectCreationRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a project
         * @summary Delete project
         * @param {string} projectId The project id
         * @param {DeletionTypes} [deleteType] Define project deletion type.
         * @param {boolean} [force] Sets the project to be forced deleted (could leave orphan objects in the system)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProject(projectId: string, deleteType?: DeletionTypes, force?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProject(projectId, deleteType, force, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get a project by id
         * @summary Get project
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProject(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProject(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project ancestors
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAncestors(projectId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Ancestors>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAncestors(projectId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves project data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get project metrics data. [Experimental]
         * @param {string} projectId The project id
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectMetrics(projectId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MetricsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectMetrics(projectId, metricType, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List projects
         * @summary Get projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ProjectFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjects(filterBy?: Array<string>, sortBy?: ProjectFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(filterBy, sortBy, sortOrder, offset, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * List projects metadata
         * @summary Get projects metadata
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {'name' | 'clusterId' | 'phase' | 'createdAt'} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsMetadata(filterBy?: Array<string>, sortBy?: 'name' | 'clusterId' | 'phase' | 'createdAt', sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectsMetadata200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsMetadata(filterBy, sortBy, sortOrder, offset, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Get projects telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolId] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'ClusterId' | 'NodepoolId' | 'ParentId'>} [groupBy] project fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolId?: string, departmentId?: string, groupBy?: Array<'ClusterId' | 'NodepoolId' | 'ParentId'>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TelemetryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsTelemetry(telemetryType, clusterId, nodepoolId, departmentId, groupBy, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get projects for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSyncProjects(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSyncProjects200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSyncProjects(clusterId, updatedAfter, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectResources(projectId: string, resources: Array<Resources>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resources>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectResources(projectId, resources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Update project
         * @param {string} projectId The project id
         * @param {ProjectUpdateRequest} projectUpdateRequest Project to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProject(projectId: string, projectUpdateRequest: ProjectUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Project>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProject(projectId, projectUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Update projects resources
         * @summary Update project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectResources(projectId: string, resources: Array<Resources>, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Resources>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectResources(projectId, resources, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update project status from cluster
         * @param {ProjectStatusUpdateRequest} projectStatusUpdateRequest Project to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProjectStatus(projectStatusUpdateRequest: ProjectStatusUpdateRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectStatus(projectStatusUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsApi - factory interface
 * @export
 */
export const ProjectsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsApiFp(configuration)
    return {
        /**
         * count projects
         * @summary Count projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countProjects(filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countProjects(filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Create a project
         * @summary Create project
         * @param {ProjectCreationRequest} projectCreationRequest Project to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(projectCreationRequest: ProjectCreationRequest, options?: any): AxiosPromise<Project> {
            return localVarFp.createProject(projectCreationRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a project
         * @summary Delete project
         * @param {string} projectId The project id
         * @param {DeletionTypes} [deleteType] Define project deletion type.
         * @param {boolean} [force] Sets the project to be forced deleted (could leave orphan objects in the system)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(projectId: string, deleteType?: DeletionTypes, force?: boolean, options?: any): AxiosPromise<void> {
            return localVarFp.deleteProject(projectId, deleteType, force, options).then((request) => request(axios, basePath));
        },
        /**
         * Get a project by id
         * @summary Get project
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(projectId: string, options?: any): AxiosPromise<Project> {
            return localVarFp.getProject(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project ancestors
         * @param {string} projectId The project id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAncestors(projectId: string, options?: any): AxiosPromise<Ancestors> {
            return localVarFp.getProjectAncestors(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves project data metrics from the metrics database. Use in reporting and analysis tools.
         * @summary Get project metrics data. [Experimental]
         * @param {string} projectId The project id
         * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
         * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
         * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter using the nodepool.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectMetrics(projectId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<MetricsResponse> {
            return localVarFp.getProjectMetrics(projectId, metricType, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * List projects
         * @summary Get projects
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ProjectFilterSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(filterBy?: Array<string>, sortBy?: ProjectFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: any): AxiosPromise<GetProjects200Response> {
            return localVarFp.getProjects(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * List projects metadata
         * @summary Get projects metadata
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {'name' | 'clusterId' | 'phase' | 'createdAt'} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsMetadata(filterBy?: Array<string>, sortBy?: 'name' | 'clusterId' | 'phase' | 'createdAt', sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: any): AxiosPromise<GetProjectsMetadata200Response> {
            return localVarFp.getProjectsMetadata(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(axios, basePath));
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Get projects telemetry
         * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
         * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [nodepoolId] Filter using the nodepool.
         * @param {string} [departmentId] Filter using the department id.
         * @param {Array<'ClusterId' | 'NodepoolId' | 'ParentId'>} [groupBy] project fields to group the data by
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolId?: string, departmentId?: string, groupBy?: Array<'ClusterId' | 'NodepoolId' | 'ParentId'>, options?: any): AxiosPromise<TelemetryResponse> {
            return localVarFp.getProjectsTelemetry(telemetryType, clusterId, nodepoolId, departmentId, groupBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get projects for sync
         * @param {string} clusterId The id of the cluster
         * @param {string} [updatedAfter] 
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSyncProjects(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: any): AxiosPromise<GetSyncProjects200Response> {
            return localVarFp.getSyncProjects(clusterId, updatedAfter, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
         * @summary Patch project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to patch.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectResources(projectId: string, resources: Array<Resources>, options?: any): AxiosPromise<Array<Resources>> {
            return localVarFp.patchProjectResources(projectId, resources, options).then((request) => request(axios, basePath));
        },
        /**
         * Get projects telemetry data by the given query parameters
         * @summary Update project
         * @param {string} projectId The project id
         * @param {ProjectUpdateRequest} projectUpdateRequest Project to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId: string, projectUpdateRequest: ProjectUpdateRequest, options?: any): AxiosPromise<Project> {
            return localVarFp.updateProject(projectId, projectUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Update projects resources
         * @summary Update project resources
         * @param {string} projectId The project id
         * @param {Array<Resources>} resources Project resources to update.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectResources(projectId: string, resources: Array<Resources>, options?: any): AxiosPromise<Array<Resources>> {
            return localVarFp.updateProjectResources(projectId, resources, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update project status from cluster
         * @param {ProjectStatusUpdateRequest} projectStatusUpdateRequest Project to update by name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectStatus(projectStatusUpdateRequest: ProjectStatusUpdateRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProjectStatus(projectStatusUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsApi - object-oriented interface
 * @export
 * @class ProjectsApi
 * @extends {BaseAPI}
 */
export class ProjectsApi extends BaseAPI {
    /**
     * count projects
     * @summary Count projects
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public countProjects(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).countProjects(filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create a project
     * @summary Create project
     * @param {ProjectCreationRequest} projectCreationRequest Project to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public createProject(projectCreationRequest: ProjectCreationRequest, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).createProject(projectCreationRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a project
     * @summary Delete project
     * @param {string} projectId The project id
     * @param {DeletionTypes} [deleteType] Define project deletion type.
     * @param {boolean} [force] Sets the project to be forced deleted (could leave orphan objects in the system)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public deleteProject(projectId: string, deleteType?: DeletionTypes, force?: boolean, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).deleteProject(projectId, deleteType, force, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get a project by id
     * @summary Get project
     * @param {string} projectId The project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProject(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProject(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project ancestors
     * @param {string} projectId The project id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectAncestors(projectId: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectAncestors(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves project data metrics from the metrics database. Use in reporting and analysis tools.
     * @summary Get project metrics data. [Experimental]
     * @param {string} projectId The project id
     * @param {Array<OrgUnitMetricType>} metricType Specify which data to request.
     * @param {string} start Start date of time range to fetch data in ISO 8601 timestamp format.
     * @param {string} end End date of time range to fetch data in ISO 8601 timestamp format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter using the nodepool.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectMetrics(projectId: string, metricType: Array<OrgUnitMetricType>, start: string, end: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectMetrics(projectId, metricType, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List projects
     * @summary Get projects
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {ProjectFilterSortFields} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjects(filterBy?: Array<string>, sortBy?: ProjectFilterSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjects(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * List projects metadata
     * @summary Get projects metadata
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {'name' | 'clusterId' | 'phase' | 'createdAt'} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsMetadata(filterBy?: Array<string>, sortBy?: 'name' | 'clusterId' | 'phase' | 'createdAt', sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsMetadata(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get projects telemetry data by the given query parameters
     * @summary Get projects telemetry
     * @param {OrgUnitTelemetryType} telemetryType specifies what data to request
     * @param {string} [clusterId] Filter using the Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [nodepoolId] Filter using the nodepool.
     * @param {string} [departmentId] Filter using the department id.
     * @param {Array<'ClusterId' | 'NodepoolId' | 'ParentId'>} [groupBy] project fields to group the data by
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getProjectsTelemetry(telemetryType: OrgUnitTelemetryType, clusterId?: string, nodepoolId?: string, departmentId?: string, groupBy?: Array<'ClusterId' | 'NodepoolId' | 'ParentId'>, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getProjectsTelemetry(telemetryType, clusterId, nodepoolId, departmentId, groupBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get projects for sync
     * @param {string} clusterId The id of the cluster
     * @param {string} [updatedAfter] 
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public getSyncProjects(clusterId: string, updatedAfter?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).getSyncProjects(clusterId, updatedAfter, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Partial updates to specific items in the list. Should be used for update one or more attributes of an item without modifying the entire resource.
     * @summary Patch project resources
     * @param {string} projectId The project id
     * @param {Array<Resources>} resources Project resources to patch.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public patchProjectResources(projectId: string, resources: Array<Resources>, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).patchProjectResources(projectId, resources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Get projects telemetry data by the given query parameters
     * @summary Update project
     * @param {string} projectId The project id
     * @param {ProjectUpdateRequest} projectUpdateRequest Project to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProject(projectId: string, projectUpdateRequest: ProjectUpdateRequest, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProject(projectId, projectUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Update projects resources
     * @summary Update project resources
     * @param {string} projectId The project id
     * @param {Array<Resources>} resources Project resources to update.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProjectResources(projectId: string, resources: Array<Resources>, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProjectResources(projectId, resources, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update project status from cluster
     * @param {ProjectStatusUpdateRequest} projectStatusUpdateRequest Project to update by name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectsApi
     */
    public updateProjectStatus(projectStatusUpdateRequest: ProjectStatusUpdateRequest, options?: AxiosRequestConfig) {
        return ProjectsApiFp(this.configuration).updateProjectStatus(projectStatusUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProjectsV1Api - axios parameter creator
 * @export
 */
export const ProjectsV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Creates a new project in a specific cluster.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProjectV1: async (clusterId: string, body: object, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('createProjectV1', 'clusterId', clusterId)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('createProjectV1', 'body', body)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Deletes a project from a specific cluster.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProjectV1: async (clusterId: string, id: number, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('deleteProjectV1', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteProjectV1', 'id', id)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics data for a specific project.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} projectId The project id
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectMetricsV1: async (clusterUuid: string, projectId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getProjectMetricsV1', 'clusterUuid', clusterUuid)
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectMetricsV1', 'projectId', projectId)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/projects/{projectId}/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. \\n Use for project analysis. \\n **Requires `view` permissions to the queried project.**
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectV1: async (clusterId: string, id: string, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getProjectV1', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProjectV1', 'id', id)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get metrics for all projects.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsMetricsV1: async (clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterUuid' is not null or undefined
            assertParamExists('getProjectsMetricsV1', 'clusterUuid', clusterUuid)
            const localVarPath = `/v1/k8s/clusters/{clusterUuid}/projects/metrics`
                .replace(`{${"clusterUuid"}}`, encodeURIComponent(String(clusterUuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (start !== undefined) {
                localVarQueryParameter['start'] = (start as any instanceof Date) ?
                    (start as any).toISOString() :
                    start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = (end as any instanceof Date) ?
                    (end as any).toISOString() :
                    end;
            }

            if (numberOfSamples !== undefined) {
                localVarQueryParameter['numberOfSamples'] = numberOfSamples;
            }

            if (nodepoolName !== undefined) {
                localVarQueryParameter['nodepoolName'] = nodepoolName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get project quotas.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsQuotasV1: async (clusterId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getProjectsQuotasV1', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/quotas`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. \\n Use in reporting and analysis tools.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsV1: async (clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('getProjectsV1', 'clusterId', clusterId)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }

            if (memoryUnitMb !== undefined) {
                localVarQueryParameter['memoryUnitMb'] = memoryUnitMb;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectV1StatusRequest} projectV1StatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectStatusV1: async (clusterId: string, projectV1StatusRequest: ProjectV1StatusRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateProjectStatusV1', 'clusterId', clusterId)
            // verify required parameter 'projectV1StatusRequest' is not null or undefined
            assertParamExists('updateProjectStatusV1', 'projectV1StatusRequest', projectV1StatusRequest)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/status`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectV1StatusRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Updates a project\'s details in a specific cluster. \\n For example, node pool resources, and others.
         * @summary Update a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectV1: async (clusterId: string, id: string, body: object, excludePermissions?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'clusterId' is not null or undefined
            assertParamExists('updateProjectV1', 'clusterId', clusterId)
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProjectV1', 'id', id)
            // verify required parameter 'body' is not null or undefined
            assertParamExists('updateProjectV1', 'body', body)
            const localVarPath = `/v1/k8s/clusters/{clusterId}/projects/{id}`
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (excludePermissions !== undefined) {
                localVarQueryParameter['excludePermissions'] = excludePermissions;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectsV1Api - functional programming interface
 * @export
 */
export const ProjectsV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectsV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * Creates a new project in a specific cluster.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async createProjectV1(clusterId: string, body: object, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProjectV1(clusterId, body, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Deletes a project from a specific cluster.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async deleteProjectV1(clusterId: string, id: number, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectV1(clusterId, id, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metrics data for a specific project.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} projectId The project id
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectMetricsV1(clusterUuid: string, projectId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectMetricsV1Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectMetricsV1(clusterUuid, projectId, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. \\n Use for project analysis. \\n **Requires `view` permissions to the queried project.**
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectV1(clusterId: string, id: string, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectV1(clusterId, id, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get metrics for all projects.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectsMetricsV1Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get project quotas.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectsQuotasV1(clusterId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectQuotasV1Inner>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsQuotasV1(clusterId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. \\n Use in reporting and analysis tools.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getProjectsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectV1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectsV1(clusterId, excludePermissions, memoryUnitMb, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectV1StatusRequest} projectV1StatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateProjectStatusV1(clusterId: string, projectV1StatusRequest: ProjectV1StatusRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectStatusV1(clusterId, projectV1StatusRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Updates a project\'s details in a specific cluster. \\n For example, node pool resources, and others.
         * @summary Update a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async updateProjectV1(clusterId: string, id: string, body: object, excludePermissions?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectV1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProjectV1(clusterId, id, body, excludePermissions, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProjectsV1Api - factory interface
 * @export
 */
export const ProjectsV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectsV1ApiFp(configuration)
    return {
        /**
         * Creates a new project in a specific cluster.
         * @summary Create a new project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        createProjectV1(clusterId: string, body: object, excludePermissions?: boolean, options?: any): AxiosPromise<ProjectV1> {
            return localVarFp.createProjectV1(clusterId, body, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * Deletes a project from a specific cluster.
         * @summary Delete a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {number} id The unique id of the project.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        deleteProjectV1(clusterId: string, id: number, excludePermissions?: boolean, options?: any): AxiosPromise<ProjectV1> {
            return localVarFp.deleteProjectV1(clusterId, id, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics data for a specific project.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} projectId The project id
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectMetricsV1(clusterUuid: string, projectId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<ProjectMetricsV1Response> {
            return localVarFp.getProjectMetricsV1(clusterUuid, projectId, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves the details of a specific project from a specific cluster. \\n Use for project analysis. \\n **Requires `view` permissions to the queried project.**
         * @summary List details of a specific project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectV1(clusterId: string, id: string, excludePermissions?: boolean, options?: any): AxiosPromise<ProjectV1> {
            return localVarFp.getProjectV1(clusterId, id, excludePermissions, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get metrics for all projects.
         * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} [start] Start of time range to fetch data from in UTC format.
         * @param {string} [end] End of time range to fetch data from in UTC format.
         * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
         * @param {string} [nodepoolName] Filter by unique nodepool name.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: any): AxiosPromise<ProjectsMetricsV1Response> {
            return localVarFp.getProjectsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get project quotas.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsQuotasV1(clusterId: string, options?: any): AxiosPromise<Array<ProjectQuotasV1Inner>> {
            return localVarFp.getProjectsQuotasV1(clusterId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieves a list of all projects and details from a specific cluster. \\n Use in reporting and analysis tools.
         * @summary List all projects and their details.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getProjectsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: any): AxiosPromise<Array<ProjectV1>> {
            return localVarFp.getProjectsV1(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Project status
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {ProjectV1StatusRequest} projectV1StatusRequest 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectStatusV1(clusterId: string, projectV1StatusRequest: ProjectV1StatusRequest, options?: any): AxiosPromise<void> {
            return localVarFp.updateProjectStatusV1(clusterId, projectV1StatusRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Updates a project\'s details in a specific cluster. \\n For example, node pool resources, and others.
         * @summary Update a project.
         * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
         * @param {string} id The unique project-id.
         * @param {object} body 
         * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        updateProjectV1(clusterId: string, id: string, body: object, excludePermissions?: boolean, options?: any): AxiosPromise<ProjectV1> {
            return localVarFp.updateProjectV1(clusterId, id, body, excludePermissions, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectsV1Api - object-oriented interface
 * @export
 * @class ProjectsV1Api
 * @extends {BaseAPI}
 */
export class ProjectsV1Api extends BaseAPI {
    /**
     * Creates a new project in a specific cluster.
     * @summary Create a new project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {object} body 
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public createProjectV1(clusterId: string, body: object, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).createProjectV1(clusterId, body, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Deletes a project from a specific cluster.
     * @summary Delete a project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {number} id The unique id of the project.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public deleteProjectV1(clusterId: string, id: number, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).deleteProjectV1(clusterId, id, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics data for a specific project.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} projectId The project id
     * @param {string} [start] Start of time range to fetch data from in UTC format.
     * @param {string} [end] End of time range to fetch data from in UTC format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter by unique nodepool name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public getProjectMetricsV1(clusterUuid: string, projectId: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).getProjectMetricsV1(clusterUuid, projectId, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves the details of a specific project from a specific cluster. \\n Use for project analysis. \\n **Requires `view` permissions to the queried project.**
     * @summary List details of a specific project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} id The unique project-id.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public getProjectV1(clusterId: string, id: string, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).getProjectV1(clusterId, id, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get metrics for all projects.
     * @param {string} clusterUuid The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} [start] Start of time range to fetch data from in UTC format.
     * @param {string} [end] End of time range to fetch data from in UTC format.
     * @param {number} [numberOfSamples] The number of samples to take in the specified time range.
     * @param {string} [nodepoolName] Filter by unique nodepool name.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public getProjectsMetricsV1(clusterUuid: string, start?: string, end?: string, numberOfSamples?: number, nodepoolName?: string, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).getProjectsMetricsV1(clusterUuid, start, end, numberOfSamples, nodepoolName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get project quotas.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public getProjectsQuotasV1(clusterId: string, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).getProjectsQuotasV1(clusterId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieves a list of all projects and details from a specific cluster. \\n Use in reporting and analysis tools.
     * @summary List all projects and their details.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {boolean} [memoryUnitMb] Memory returned in MB. When set to &#x60;false&#x60; (default) memory will be returned in MiB.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public getProjectsV1(clusterId: string, excludePermissions?: boolean, memoryUnitMb?: boolean, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).getProjectsV1(clusterId, excludePermissions, memoryUnitMb, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Project status
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {ProjectV1StatusRequest} projectV1StatusRequest 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public updateProjectStatusV1(clusterId: string, projectV1StatusRequest: ProjectV1StatusRequest, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).updateProjectStatusV1(clusterId, projectV1StatusRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Updates a project\'s details in a specific cluster. \\n For example, node pool resources, and others.
     * @summary Update a project.
     * @param {string} clusterId The Universally Unique Identifier (UUID) of the cluster.
     * @param {string} id The unique project-id.
     * @param {object} body 
     * @param {boolean} [excludePermissions] Backward compatibility of the &#x60;departmentAdmins&#x60; field. If &#x60;true&#x60;, the &#x60;departmentAdmins&#x60; field in the returned departments is not set. If &#x60;false&#x60;, the &#x60;departmentAdmins&#x60; is set in the returned departments.
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof ProjectsV1Api
     */
    public updateProjectV1(clusterId: string, id: string, body: object, excludePermissions?: boolean, options?: AxiosRequestConfig) {
        return ProjectsV1ApiFp(this.configuration).updateProjectV1(clusterId, id, body, excludePermissions, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportsApi - axios parameter creator
 * @export
 */
export const ReportsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Reports availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areReportsAvailable: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/reports/availability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReports: async (filterBy?: Array<string>, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/reports/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a new report request.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (reportRequestFields: ReportRequestFields, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportRequestFields' is not null or undefined
            assertParamExists('createReport', 'reportRequestFields', reportRequestFields)
            const localVarPath = `/api/v1/org-unit/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reportRequestFields, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('deleteReportById', 'reportId', reportId)
            const localVarPath = `/api/v1/org-unit/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Download report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('downloadReportById', 'reportId', reportId)
            const localVarPath = `/api/v1/org-unit/reports/{reportId}/file`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById: async (reportId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'reportId' is not null or undefined
            assertParamExists('getReportById', 'reportId', reportId)
            const localVarPath = `/api/v1/org-unit/reports/{reportId}`
                .replace(`{${"reportId"}}`, encodeURIComponent(String(reportId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports: async (filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/org-unit/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (filterBy) {
                localVarQueryParameter['filterBy'] = filterBy.join(COLLECTION_FORMATS.csv);
            }

            if (sortBy !== undefined) {
                localVarQueryParameter['sortBy'] = sortBy;
            }

            if (sortOrder !== undefined) {
                localVarQueryParameter['sortOrder'] = sortOrder;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportsApi - functional programming interface
 * @export
 */
export const ReportsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Reports availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async areReportsAvailable(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AreReportsAvailable200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.areReportsAvailable(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countReports(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countReports(filterBy, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Create a new report request.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(reportRequestFields: ReportRequestFields, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(reportRequestFields, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Download report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async downloadReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.downloadReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getReportById(reportId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getReportById(reportId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ListReports200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listReports(filterBy, sortBy, sortOrder, offset, limit, search, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportsApi - factory interface
 * @export
 */
export const ReportsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportsApiFp(configuration)
    return {
        /**
         * 
         * @summary Reports availability
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        areReportsAvailable(options?: any): AxiosPromise<AreReportsAvailable200Response> {
            return localVarFp.areReportsAvailable(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Count reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReports(filterBy?: Array<string>, search?: string, options?: any): AxiosPromise<CountResponse> {
            return localVarFp.countReports(filterBy, search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Create a new report request.
         * @param {ReportRequestFields} reportRequestFields Report to create.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(reportRequestFields: ReportRequestFields, options?: any): AxiosPromise<Report> {
            return localVarFp.createReport(reportRequestFields, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteReportById(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteReportById(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Download report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadReportById(reportId: string, options?: any): AxiosPromise<void> {
            return localVarFp.downloadReportById(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get report
         * @param {string} reportId The report id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReportById(reportId: string, options?: any): AxiosPromise<Report> {
            return localVarFp.getReportById(reportId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List reports
         * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
         * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
         * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
         * @param {number} [offset] The offset of the first item returned in the collection.
         * @param {number} [limit] The maximum number of entries to return.
         * @param {string} [search] Filter results by a free text search.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: any): AxiosPromise<ListReports200Response> {
            return localVarFp.listReports(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportsApi - object-oriented interface
 * @export
 * @class ReportsApi
 * @extends {BaseAPI}
 */
export class ReportsApi extends BaseAPI {
    /**
     * 
     * @summary Reports availability
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public areReportsAvailable(options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).areReportsAvailable(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Count reports
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public countReports(filterBy?: Array<string>, search?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).countReports(filterBy, search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Create a new report request.
     * @param {ReportRequestFields} reportRequestFields Report to create.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public createReport(reportRequestFields: ReportRequestFields, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).createReport(reportRequestFields, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete report
     * @param {string} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public deleteReportById(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).deleteReportById(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Download report
     * @param {string} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public downloadReportById(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).downloadReportById(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get report
     * @param {string} reportId The report id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public getReportById(reportId: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).getReportById(reportId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List reports
     * @param {Array<string>} [filterBy] Filter results by a parameter. Use the format field-name operator value. Operators are &#x3D;&#x3D; Equals, !&#x3D; Not equals, &lt;&#x3D; Less than or equal, &gt;&#x3D; Greater than or equal, &#x3D;@ contains, !@ Does not contains, &#x3D;^ Starts with and &#x3D;$ Ends with. Dates are in ISO 8601 timestamp format and available for operators &#x3D;&#x3D;, !&#x3D;, &lt;&#x3D; and &gt;&#x3D;.
     * @param {ReportFilterAndSortFields} [sortBy] Sort results by a parameters.
     * @param {'asc' | 'desc'} [sortOrder] Sort results in descending or ascending order.
     * @param {number} [offset] The offset of the first item returned in the collection.
     * @param {number} [limit] The maximum number of entries to return.
     * @param {string} [search] Filter results by a free text search.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportsApi
     */
    public listReports(filterBy?: Array<string>, sortBy?: ReportFilterAndSortFields, sortOrder?: 'asc' | 'desc', offset?: number, limit?: number, search?: string, options?: AxiosRequestConfig) {
        return ReportsApiFp(this.configuration).listReports(filterBy, sortBy, sortOrder, offset, limit, search, options).then((request) => request(this.axios, this.basePath));
    }
}


