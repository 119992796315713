import { ReportStatus } from "@/swagger-models/org-unit-service-client";
import type { IStatusColOptions } from "@/models/table.model";

export const ReportStatusMap: Record<ReportStatus, IStatusColOptions> = {
  [ReportStatus.Pending]: {
    status: "Pending",
    displayAnimation: false,
    color: "warning",
    showIconToolTip: true,
  },
  [ReportStatus.Ready]: {
    status: "Ready",
    color: "success",
    displayAnimation: false,
    showIconToolTip: true,
  },
  [ReportStatus.Failed]: {
    status: "Failed",
    color: "negative",
    displayAnimation: false,
    showIconToolTip: true,
  },
  [ReportStatus.Processing]: {
    status: "Processing...",
    displayAnimation: true,
    showIconToolTip: true,
  },
};
